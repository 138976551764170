import Pesquisa from "lirida-back-service/Servicos/Pesquisa/Pesquisa";
import Pessoa from "lirida-back-service/Servicos/Pessoa/Pessoa";
import PessoaService from "lirida-back-service/Servicos/Pessoa/PessoaService";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { Component } from "react";
import { Link } from "react-router-dom";
import Header from "../../components/Header";
import Constante from "../../Util/Constante";
import Util from "../../Util/Util";

export default class PessoaContador extends Component {
  util = new Util();
  constante = new Constante();
  pessoaService = new PessoaService(this.constante.token, this.constante.url_base);

  state = {
    pessoaUsuario: new Pessoa(),
    listaContador: [],
    buscarCarregando: false,
    filtro: {
      dataCadastro_inicial: '',
      dataCadastro_final: '',
      nome: '',
      email: '',
      cnpj: '',
    }
  }

  componentDidMount() {
    this.iniciar();
  }

  async iniciar() {
    this.util.traduzirCalendar();
    let pessoaUsuario = await this.util.buscarLocalStorage("PESSOA_USUARIO");
    let listaContador = await this.listar();
    this.setState({ pessoaUsuario: pessoaUsuario, listaContador: listaContador });
  }

  async listar() {
    this.setState({ buscarCarregando: true })
    const listaPesquisa = [];
    let pesquisa = new Pesquisa();

    pesquisa.campo = 'pessoa.seq_usuario';
    pesquisa.conteudo = this.constante.seqUsuario;
    pesquisa.operacao = '';
    pesquisa.tipo = "TEXTO";
    listaPesquisa.push(pesquisa);

    pesquisa = new Pesquisa();
    pesquisa.campo = 'pessoa.seq_pessoa_perfil';
    pesquisa.conteudo = this.constante.seqPessoaPerfilContador;
    pesquisa.operacao = 'AND';
    pesquisa.tipo = "TEXTO";
    listaPesquisa.push(pesquisa);


    if (this.state?.filtro?.dataCadastro_inicial) {
      pesquisa = new Pesquisa();
      pesquisa.campo = 'pessoa.data_cadastro';
      pesquisa.conteudo = this.util.formatarDataBanco(this.state.filtro.dataCadastro_inicial);
      pesquisa.operacao = 'AND';
      pesquisa.tipo = "DATA_INCIAL";
      listaPesquisa.push(pesquisa);
    }

    if (this.state?.filtro?.dataCadastro_final) {
      pesquisa = new Pesquisa();
      pesquisa.campo = 'pessoa.data_cadastro';
      pesquisa.conteudo = this.util.formatarDataBanco(this.state.filtro.dataCadastro_final);
      pesquisa.operacao = 'AND';
      pesquisa.tipo = "DATA_FINAL";
      listaPesquisa.push(pesquisa);
    }

    if (this.state?.filtro?.nome) {
      pesquisa = new Pesquisa();
      pesquisa.campo = 'pessoa.nome';
      pesquisa.conteudo = this.state.filtro.nome;
      pesquisa.operacao = 'AND';
      pesquisa.tipo = "TEXTO";
      listaPesquisa.push(pesquisa);
    }

    if (this.state?.filtro?.email) {
      pesquisa = new Pesquisa();
      pesquisa.campo = 'pessoa.email_plataforma_lirida';
      pesquisa.conteudo = this.state.filtro.email;
      pesquisa.operacao = 'AND';
      pesquisa.tipo = "TEXTO";
      listaPesquisa.push(pesquisa);
    }

    if (this.state?.filtro?.cnpj) {
      pesquisa = new Pesquisa();
      pesquisa.campo = 'pessoa.documento';
      pesquisa.conteudo = this.state.filtro.cnpj;
      pesquisa.operacao = 'AND';
      pesquisa.tipo = "TEXTO";
      listaPesquisa.push(pesquisa);
    }

    console.log('LISTA', listaPesquisa);
    let retorno = await this.pessoaService.listarComFiltro(listaPesquisa);
    let listaContador = retorno.objeto;

    console.log('LISTA CONTADOR', listaContador);
    this.setState({
      listaContador: listaContador,
      buscarCarregando: false,
    })
    return listaContador;
  }

  render() {
    return (
      <div>
        < Header />

        <main style={{ width: '95%', margin: '20px auto' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', border: '1px solid lightgray', padding: 10, borderRadius: 3 }}>
            <div style={{ margin: '20px 0' }}>
              <h1 style={{ fontSize: 22, }}>Contadores</h1>
              <h2 style={{ fontSize: 20, color: 'gray' }}>Contadores na plataforma</h2>
            </div>
          </div>

          <div style={{ border: '1px solid lightgray', padding: 10, borderRadius: 3, gap: 20, marginTop: 20 }}>
            <h2 style={{ fontSize: 20, color: 'gray', marginBottom: 20, display: 'flex', gap: 18 }}>Filtro de busca
              <i className="pi pi-search"
                style={{ 'fontSize': '1em' }}></i></h2>
            <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', gap: 20 }}>

              <div>
                <label>Data cadastro inicial</label><br />
                <Calendar
                  showButtonBar
                  onChange={pTexto => this.setState({
                    filtro: {
                      ...this.state.filtro, dataCadastro_inicial: pTexto.target.value
                    }
                  })}
                  value={this.state.filtro.dataCadastro_inicial || ''}
                  style={{ width: '100%', }} dateFormat="dd/mm/yy" placeholder="dd/mm/yyyy" />
              </div>

              <div>
                <label>Data cadastro final</label><br />
                <Calendar
                  showButtonBar
                  onChange={pTexto => this.setState({
                    filtro: {
                      ...this.state.filtro, dataCadastro_final: pTexto.target.value
                    }
                  })}
                  value={this.state.filtro.dataCadastro_final || ''}
                  style={{ width: '100%', }} dateFormat="dd/mm/yy" placeholder="dd/mm/yyyy" />
              </div>

              <div>
                <label>Nome</label><br />
                <InputText
                  onChange={pTexto => this.setState({
                    filtro: {
                      ...this.state.filtro, nome: pTexto.target.value
                    }
                  })}
                  value={this.state.filtro.nome || ''}
                  style={{ width: '100%' }}
                />
              </div>


              <div>
                <label>CNPJ</label><br />
                <InputText
                  onChange={pTexto => this.setState({
                    filtro: {
                      ...this.state.filtro, cnpj: this.util.formatarCNPJ(pTexto.target.value)
                    }
                  })}
                  value={this.state.filtro.cnpj || ''}
                  style={{ width: '100%' }}
                />
              </div>


              <div>
                <label>E-mail</label><br />
                <InputText
                  onChange={pTexto => this.setState({
                    filtro: {
                      ...this.state.filtro, email: pTexto.target.value
                    }
                  })}
                  value={this.state.filtro.email || ''}
                  style={{ width: '100%' }}
                />
              </div>
            </div>

            <div style={{ display: 'flex', justifyContent: 'right', marginTop: 20 }}>
              <Button label="Buscar" loading={this.state.buscarCarregando} onClick={() => this.listar()} />
            </div>
          </div>

          <DataTable style={{ marginTop: 20 }}
            selectionMode="single"
            showGridlines
            removableSort
            paginator
            responsiveLayout="scroll"
            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords}"
            rows={10}
            rowsPerPageOptions={[10, 20, 50]}
            value={this.state.listaContador}
          // onSelectionChange={e => this.selecionarPessoa(e.value)}
          >

            <Column header='Nome'
              field='nome'
              sortable
              body={(pItem) => <Link
                style={{ color: this.constante.azul }}
                target="_blank" to={"/detalhe_contador?id=" + pItem._seqPessoa}>{pItem.nome}</Link>} />
            <Column
              field="documento"
              header="documento"
              sortable />

            <Column
              field="emailPlataformaLirida"
              header="E-mail"
              sortable />

            <Column
              field="_perfilNome"
              header="Perfil"
              sortable />

            <Column
              field="dataCadastro"
              header="Data cadastro"
              sortable
              body={pItem => <p>{this.util.formatarDataComTimestamp(pItem.dataCadastro)}</p>}
            />
          </DataTable>
        </main>
      </div>
    )
  }
}