import { Button } from "primereact/button";
import { Component } from "react";
import Header from "../components/Header";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Calendar } from "primereact/calendar";
import Util from '../Util/Util';
import Constante from "../Util/Constante";
import Pessoa from 'lirida-back-service/Servicos/Pessoa/Pessoa';
import PessoaService from 'lirida-back-service/Servicos/Pessoa/PessoaService';
import FinanceiroDetalhePg from "./FinanceiroDetalhePg";
import { Dropdown } from "primereact/dropdown";
import FinanceiroService from 'lirida-back-service/Servicos/Financeiro/FinanceiroService';
import Financeiro from 'lirida-back-service/Servicos/Financeiro/Financeiro';
import { InputText } from "primereact/inputtext";

export default class FinanceiroPg extends Component {

    constante = new Constante();
    util = new Util();
    pessoaService = new PessoaService(this.constante.token, this.constante.url_base);
    financeiroService = new FinanceiroService(this.constante.token, this.constante.url_base);

    state = {
        pessoaUsuario: new Pessoa(),
        financeiro: new Financeiro(),
        buscarCarregando: false,
        tela: 1,
        listaFinanceiro: [],
        listaBanco: [],
        listaFormaPagamento: [],

        //FILTRO
        filtro: {
            dataCadastro_inicial: '',
            dataCadastro_final: '',
            dataProgramada_inicial: '',
            dataProgramada_final: '',
            nomeCliente: '',
            cpfCliente: '',
            nomeFantasia: '',
            cnpjCliente: '',
            listaStatus: [{ nome: 'Pago', valor: 1 }, { nome: 'Não pago', valor: 0 }],
            status: null,
            formaPagamento: null,
        }
    }

    componentDidMount() {
        this.iniciar();
    }

    async iniciar() {

        this.pessoaUsuario = await this.util.buscarLocalStorage('PESSOA_USUARIO');
        console.log("PESSOA USUARIO");
        console.log(this.pessoaUsuario);
        if (!this.pessoaUsuario?._seqPessoa) {
            window.history.back()
        }
        this.retornoListaFormaPagamento = await this.util.listarLista(this.constante.seqUsuario, this.constante.idListaFormaPagamento);

        this.listaFormaPagamento = this.retornoListaFormaPagamento.objeto
        this.setState({ pessoaUsuario: this.pessoaUsuario, listaFormaPagamento: this.listaFormaPagamento });
        this.listar(this.pessoaUsuario);
    }



    selecionar(pDocumento) {
        console.log(pDocumento)
        window.open(this.constante.url_base_crosspag + pDocumento._seqMovimentoFinanceiro, '_blank')
    }

    async listar(pPessoUsuario) {
        if (pPessoUsuario) {
            this.pessoaUsuario = pPessoUsuario;
        } else {
            this.pessoaUsuario = this.state.pessoaUsuario;
        }


        this.setState({ buscarCarregando: true });
        this.listaPesquisa = [
            this.util.criarObjetoPesquisa(
                'movimento_financeiro.seq_usuario',
                this.constante.seqUsuario,
                '',
                'TEXTO'
            ),
            this.util.criarObjetoPesquisa(
                'movimento_financeiro.seq_pessoa_recebedor',
                this.pessoaUsuario._seqPessoa,
                'AND',
                'TEXTO'
            ),
        ]

        if (this.state.filtro.dataCadastro_inicial) {
            this.listaPesquisa.push(
                this.util.criarObjetoPesquisa(
                    'movimento_financeiro.data_cadastro',
                    this.state.filtro.dataCadastro_inicial,
                    'AND',
                    'DATA_INICIAL'
                )
            )
        }

        if (this.state.filtro.dataCadastro_final) {
            this.listaPesquisa.push(
                this.util.criarObjetoPesquisa(
                    'movimento_financeiro.data_cadastro',
                    this.state.filtro.dataCadastro_final,
                    'AND',
                    'DATA_FINAL'
                )
            )
        }


        if (this.state.filtro.dataProgramada_inicial) {
            this.listaPesquisa.push(
                this.util.criarObjetoPesquisa(
                    'movimento_financeiro.data_programado',
                    this.state.filtro.dataProgramada_inicial,
                    'AND',
                    'DATA_INICIAL'
                )
            )
        }


        if (this.state.filtro.dataProgramada_final) {
            this.listaPesquisa.push(
                this.util.criarObjetoPesquisa(
                    'movimento_financeiro.data_programado',
                    this.state.filtro.dataProgramada_final,
                    'AND',
                    'DATA_FINAL'
                )
            )
        }

        if (this.state.filtro.status?.valor) {
            this.listaPesquisa.push(
                this.util.criarObjetoPesquisa(
                    'movimento_financeiro.qtde_baixa',
                    this.state.filtro.status?.valor,
                    'AND',
                    'NUMERO'
                )
            )
        }


        if (this.state.filtro.formaPagamento?._seq) {
            this.listaPesquisa.push(
                this.util.criarObjetoPesquisa(
                    "movimento_financeiro.seq_forma_pagamento",
                    this.state.filtro.formaPagamento?._seq,
                    "AND",
                    "TEXTO"
                )
            )
        }


        if (this.state.filtro?.nomeCliente) {
            this.listaPesquisa.push(
                this.util.criarObjetoPesquisa(
                    "pessoa.nome",
                    this.state.filtro?.nomeCliente,
                    "AND",
                    "TEXTO"
                )
            )
        }


        if (this.state.filtro.nomeFantasia) {
            this.listaPesquisa.push(
                this.util.criarObjetoPesquisa(
                    "pessoa.apelido",
                    this.state.filtro.nomeFantasia,
                    "AND",
                    "TEXTO"
                )
            )
        }

        console.log(this.listaPesquisa)
        this.retorno = await this.financeiroService.listarComFiltro(this.listaPesquisa)
        this.listaFinanceiro = this.retorno.objeto;
        console.log("LISTA FINANCEIRA")
        console.log(this.listaFinanceiro);
        this.setState({
            listaFinanceiro: this.listaFinanceiro,
            buscarCarregando: false,
            filtro: {
                ...this.state.filtro,
                status: null,
                formaPagamento: null,
            }
        })
    }

    render() {
        return this.state.pessoaUsuario._seqPessoa && <div>
            <Header />

            {this.state.tela === 1 ? <main style={{ width: '95%', margin: '20px auto' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', border: '1px solid lightgray', padding: 10, borderRadius: 3 }}>

                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 20, margin: '20px 0' }}>
                        <div>
                            <h1 style={{ fontSize: 22, }}>Financeiro</h1>
                            <h2 style={{ fontSize: 20, color: 'gray' }}>Seus financeiro</h2>
                        </div>
                    </div>

                </div>

                <div style={{ border: '1px solid lightgray', padding: 10, borderRadius: 3, gap: 20, marginTop: 20 }}>
                    <h2
                        style={{ fontSize: 20, color: 'gray', marginBottom: 20, display: 'flex', gap: 18 }}>
                        Filtro de busca
                        <i className="pi pi-search" style={{ 'fontSize': '1em' }}></i>
                    </h2>
                    <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr', gap: 20 }}>
                        <div>
                            <label>Data cadastro inicial</label><br />
                            <Calendar
                                onChange={pTexto => this.setState({
                                    filtro: {
                                        ...this.state.filtro, dataCadastro_inicial: this.util.formatarDataBanco(pTexto.target.value)
                                    }
                                })}
                                showButtonBar
                                value={this.state.filtro.dataCadastro_inicial || ''}
                                style={{ width: '100%', }} dateFormat="dd/mm/yy" placeholder="dd/mm/yyyy" />
                        </div>

                        <div>
                            <label>Data cadastro final</label><br />
                            <Calendar
                                onChange={pTexto => this.setState({
                                    filtro: {
                                        ...this.state.filtro, dataCadastro_final: this.util.formatarDataBanco(pTexto.target.value)
                                    }
                                })}
                                showButtonBar
                                value={this.state.filtro.dataCadastro_final || ''}
                                style={{ width: '100%', }} dateFormat="dd/mm/yy" placeholder="dd/mm/yyyy" />
                        </div>

                        <div>
                            <label>Data programada inicial</label><br />
                            <Calendar
                                onChange={pTexto => this.setState({
                                    filtro: {
                                        ...this.state.filtro, dataProgramada_inicial: this.util.formatarDataBanco(pTexto.target.value)
                                    }
                                })}
                                showButtonBar
                                value={this.state.filtro.dataProgramada_inicial || ''}
                                style={{ width: '100%', }} dateFormat="dd/mm/yy" placeholder="dd/mm/yyyy" />
                        </div>

                        <div>
                            <label>Data programada final</label><br />
                            <Calendar
                                showButtonBar
                                onChange={pTexto => this.setState({
                                    filtro: {
                                        ...this.state.filtro, dataProgramada_final: this.util.formatarDataBanco(pTexto.target.value)
                                    }
                                })}
                                value={this.state.filtro.dataProgramada_final}
                                style={{ width: '100%', }} dateFormat="dd/mm/yy" placeholder="dd/mm/yyyy" />
                        </div>

                        <div>
                            <label>Status</label><br />
                            <Dropdown
                                onChange={e => this.setState({ filtro: { ...this.state.filtro, status: e.value } })}
                                value={this.state.filtro.status}
                                options={this.state.filtro.listaStatus}
                                optionLabel={'nome'}
                                style={{ width: '100%', }}
                                placeholder='Selecione' />
                        </div>

                        <div>
                            <label>Forma de pagamento</label><br />
                            <Dropdown
                                onChange={e => this.setState({ filtro: { ...this.state.filtro, formaPagamento: e.value } })}
                                value={this.state.filtro.formaPagamento}
                                options={this.state.listaFormaPagamento}
                                optionLabel={'_nome'}
                                style={{ width: '100%', }}
                                placeholder='Selecione' />
                        </div>

                        <div>
                            <label>Nome</label><br />
                            <InputText
                                onChange={pTexto => this.setState({ filtro: { ...this.state.filtro, nomeCliente: pTexto.target.value } })}
                                value={this.state.filtro.nomeCliente || ''}
                                style={{ width: '100%' }}
                            />
                        </div>

                        <div>
                            <label>Nome fantasia</label><br />
                            <InputText
                                onChange={pTexto => this.setState({ filtro: { ...this.state.filtro, nomeFantasia: pTexto.target.value } })}
                                value={this.state.filtro.nomeFantasia || ''}
                                style={{ width: '100%' }}
                            />
                        </div>
                    </div>

                    <div style={{ display: 'flex', justifyContent: 'right', marginTop: 20 }}>
                        <Button type='button' loading={this.state.buscarCarregando} label="Buscar" className="p-button-raised" onClick={() => this.listar()} />
                    </div>
                </div>

                <DataTable style={{ marginTop: 20 }}
                    selectionMode="single"
                    showGridlines
                    removableSort
                    paginator
                    responsiveLayout="scroll"
                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords}"
                    rows={10}
                    rowsPerPageOptions={[10, 20, 50]}
                    value={this.state.listaFinanceiro}
                    onSelectionChange={e => this.selecionar(e.value)}>

                    <Column
                        field="_pesNome"
                        header="Nome cliente"
                        sortable />
                    <Column
                        field="_pesApelido"
                        header="Razão social"
                        sortable />
                    <Column
                        field="valorProgramado"
                        header="Valor programado"
                        sortable body={item => <p>R${this.util.formatarValor(item.valorProgramado)}</p>} />
                    <Column
                        field="_cfOperacao"
                        header="Operação"
                        sortable />
                    <Column
                        field="_fpNome"
                        header="Forma de pagamento" sortable />
                    <Column
                        field="qtdeBaixa"
                        header="Status"
                        sortable body={item => <p>{
                            item.qtdeBaixa === 0 ? 'Não pago' : 'Pago'
                        }</p>} />
                    <Column
                        field="dataProgramado"
                        header="Data programada"
                        sortable body={item => <p>{this.util.formatarData(item.dataProgramado)}</p>
                        } />
                    <Column
                        field="_dataBaixa)"
                        header="Data baixa"
                        sortable body={item => <p>{this.util.formatarData(item._dataBaixa)}</p>
                        } />
                    <Column
                        field="_dataCredito"
                        header="Data crédito"
                        sortable body={item => <p>{this.util.formatarData(item._dataCredito)}</p>
                        } />
                    <Column
                        field="dataCadastro"
                        header="Data cadastro"
                        sortable body={item => <>{this.util.formatarData(item.dataCadastro)}</>
                        } />

                </DataTable>

            </main>
                : <FinanceiroDetalhePg
                    listarFinanceiro={() => this.listar()}
                    setState={(pState) => this.setState(pState)}
                />}
        </div>
    }
}