import CepService from "lirida-back-service/Servicos/Cep/CepService";
import Pessoa from "lirida-back-service/Servicos/Pessoa/Pessoa";
import PessoaService from "lirida-back-service/Servicos/Pessoa/PessoaService";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import { Component, createRef } from "react";
import Util from "../Util/Util";
import Constante from "../Util/Constante";
import { Dropdown } from "primereact/dropdown";

export default class DadosPessoais extends Component {
    toast = createRef();
    util = new Util();
    constante = new Constante();
    pessoaService = new PessoaService(this.constante.token, this.constante.url_base);
    cepService = new CepService(this.constante.token, this.constante.url_base);

    state = {
        urlBase: '',
        pessoaUsuario: new Pessoa(),

        carregando: false,
        inputDadosPessoaisError: {
            nome: false,
            email: false,
            telefone: false,
            documento: false,
            tipoDocumento: false,
            crc: false
        },
    }

    componentDidMount() {
        this.iniciar();
    }

    async iniciar() {
        const pessoaUsuario = await this.util.buscarLocalStorage("PESSOA_USUARIO");

        this.setState({ pessoaUsuario: pessoaUsuario });
    }

    validarInputsDadosPessoais() {
        const inputDadosPessoaisError = this.state.inputDadosPessoaisError;
        const listaChaveinputDadosPessoaisError = Object.keys(inputDadosPessoaisError);

        for (let i = 0; i < listaChaveinputDadosPessoaisError.length; i++) {
            inputDadosPessoaisError[listaChaveinputDadosPessoaisError[i]] = false;
        }

        if (!this.state.pessoaUsuario.nome) {
            inputDadosPessoaisError.nome = true;
        }

        if (!this.state.pessoaUsuario.emailPlataformaLirida) {
            inputDadosPessoaisError.email = true;
        }

        if (!this.state.pessoaUsuario.telefonePlataformaLirida) {
            inputDadosPessoaisError.telefone = true;
        }
        if (!this.state.pessoaUsuario.tipoDocumento) {
            inputDadosPessoaisError.documento = true;
        }
        if (!this.state.pessoaUsuario.documento) {
            inputDadosPessoaisError.documento = true;
        }

        if (this.state.pessoaUsuario.tipoDocumento === "CNPJ" && this.state.pessoaUsuario.documento.length !== 18) {
            inputDadosPessoaisError.documento = true;
        }

        if (!this.state.pessoaUsuario.tags1) {
            inputDadosPessoaisError.crc = true;
        }
        let inputOK = true;

        for (let i = 0; i < listaChaveinputDadosPessoaisError.length; i++) {
            if (inputDadosPessoaisError[listaChaveinputDadosPessoaisError[i]]) {
                inputOK = false;
            }
        }

        this.setState({ inputDadosPessoaisError: inputDadosPessoaisError });

        return inputOK;
    }

    async salvar(pPessoa) {
        this.setState({ carregando: true });

        let pessoaUsuario;

        if (pPessoa) {
            pessoaUsuario = pPessoa;
        } else {
            pessoaUsuario = this.state.pessoaUsuario;
        }

        const retornoPessoa = await this.pessoaService.salvar(pessoaUsuario);
        pessoaUsuario = retornoPessoa.objeto;

        this.util.salvarLocalStorage("PESSOA_USUARIO", pessoaUsuario);

        this.toast.current.show([
            { severity: 'success', summary: 'Informações armazenadas com sucesso!' },
        ]);

        this.setState({
            pessoaUsuario: pessoaUsuario,
            carregando: false,
        });

        if (this.props?.avancarFormulario) {
            this.props.avancarFormulario();
        }
    }

    render() {
        return <div>
            <Toast ref={this.toast} />
            <form
                onSubmit={e => {
                    e.preventDefault();
                    const retorno = this.validarInputsDadosPessoais();
                    if (retorno) this.salvar();
                }}
                style={{ width: '100%', display: 'flex', flexDirection: 'column', gap: 20 }}
            >
                <div>
                    <label>Nome da empresa contábil</label><br />
                    <InputText
                        value={this.state.pessoaUsuario.nome || ''}
                        onChange={e => this.setState({
                            pessoaUsuario: {
                                ...this.state.pessoaUsuario,
                                nome: e.target.value
                            }
                        })}
                        style={{ width: '100%', borderColor: this.state.inputDadosPessoaisError.nome && 'var(--red-600)' }} />
                    {this.state.inputDadosPessoaisError.nome && <small style={{ color: 'var(--red-600)' }}>Nome inválido</small>}
                </div>
                <div>
                    <label>Email</label><br />
                    <InputText
                        disabled
                        value={this.state.pessoaUsuario.emailPlataformaLirida || ''}
                        onChange={e => this.setState({
                            pessoaUsuario: {
                                ...this.state.pessoaUsuario,
                                emailPlataformaLirida: e.target.value
                            }
                        })}
                        style={{ width: '100%', borderColor: this.state.inputDadosPessoaisError.email && 'var(--red-600)' }} />
                    {this.state.inputDadosPessoaisError.email && <small style={{ color: 'var(--red-600)' }}>Email inválido</small>}
                </div>


                <div>
                    <label>Telefone</label><br />
                    <InputText
                        value={this.state.pessoaUsuario.telefonePlataformaLirida || ''}
                        onChange={e => this.setState({
                            pessoaUsuario: {
                                ...this.state.pessoaUsuario,
                                telefonePlataformaLirida: this.util.formatarTelefone(e.target.value)
                            }
                        })}
                        style={{ width: '100%', borderColor: this.state.inputDadosPessoaisError.telefone && 'var(--red-600)' }} />
                    {this.state.inputDadosPessoaisError.telefone && <small style={{ color: 'var(--red-600)' }}>Email inválido</small>}
                </div>

                <div>
                    <label>Tipo de pessoa</label><br />
                    <Dropdown
                        options={[{ label: "Pessoa jurídica", value: "CNPJ" }]}
                        placeholder='Selecione'
                        disabled
                        value={this.state.pessoaUsuario.tipoDocumento || ''}
                        onChange={e => this.setState({
                            pessoaUsuario: {
                                ...this.state.pessoaUsuario,
                                tipoDocumento: e.value
                            }
                        })}
                        style={{ width: '100%', borderColor: this.state.inputDadosPessoaisError.tipoDocumento && 'var(--red-600)' }} />
                    {this.state.inputDadosPessoaisError.tipoDocumento && <small style={{ color: 'var(--red-600)' }}>Tipo de pessoainválido</small>}
                </div>
                {this.state.pessoaUsuario.tipoDocumento === "CPF" &&
                    <div>
                        <label>CPF</label><br />
                        <InputText
                            type="tel"
                            value={this.state.pessoaUsuario.documento || ''}
                            onChange={e => {
                                if (e.target.value.length > 14) return;

                                this.setState({
                                    pessoaUsuario: {
                                        ...this.state.pessoaUsuario,
                                        documento: this.util.formatarCPF(e.target.value)
                                    }
                                })
                            }}
                            style={{ width: '100%', borderColor: this.state.inputDadosPessoaisError.documento && 'var(--red-600)' }} />
                        {this.state.inputDadosPessoaisError.documento && <small style={{ color: 'var(--red-600)' }}>Documento inválido</small>}
                    </div>}

                {this.state.pessoaUsuario.tipoDocumento === "CNPJ" &&
                    <div>
                        <label>CNPJ</label><br />
                        <InputText
                            type="tel"
                            value={this.state.pessoaUsuario.documento || ''}
                            onChange={e => this.setState({
                                pessoaUsuario: {
                                    ...this.state.pessoaUsuario,
                                    documento: this.util.formatarCNPJ(e.target.value)
                                }
                            })}
                            style={{ width: '100%', borderColor: this.state.inputDadosPessoaisError.documento && 'var(--red-600)' }} />
                        {this.state.inputDadosPessoaisError.documento && <small style={{ color: 'var(--red-600)' }}>Documento inválido</small>}
                    </div>}

                {(this.state.pessoaUsuario.seqPessoaPerfil === this.constante.seqPessoaPerfilContador) &&
                    <div>
                        <label>CRC</label><br />
                        <InputText
                            value={this.state.pessoaUsuario.tags1 || ''}
                            onChange={e => this.setState({
                                pessoaUsuario: {
                                    ...this.state.pessoaUsuario,
                                    tags1: e.target.value
                                }
                            })}
                            style={{ width: '100%', borderColor: this.state.inputDadosPessoaisError.crc && 'var(--red-600)' }} />
                        {this.state.inputDadosPessoaisError.crc && <small style={{ color: 'var(--red-600)' }}>CRC inválido</small>}
                    </div>}
                <div style={{ display: 'flex', justifyContent: 'right' }}>
                    <Button loading={this.state.carregando} type="submit" label={this.props?.avancarFormulario ? "Próximo" : "Salvar"} />
                </div>
            </form>
        </div>
    }
}