import { Component, createRef } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Navigate } from "react-router-dom";
import { Messages } from "primereact/messages";
import PessoaService from 'lirida-back-service/Servicos/Pessoa/PessoaService';
import Pessoa from 'lirida-back-service/Servicos/Pessoa/Pessoa';
import Constante from "../Util/Constante";
import Util from "../Util/Util";
import LogoEscura from '../assets/images/logoEscura.png';
import LogoBranca from '../assets/images/logoBranca.png';
import Pesquisa from "lirida-back-service/Servicos/Pesquisa/Pesquisa";


export default class RecuperarSenhaPg extends Component {
    msgs1 = createRef();
    constante = new Constante();
    util = new Util();
    pessoaService = new PessoaService(this.constante.token, this.constante.url_base);


    TelaEnum = {
        principal: 'principal',
        login: "login",
        sobre: 'sobre',
        funcionalidades: 'funcionalidades',
        depoimentos: 'depoimentos',
        perguntasFrequentes: 'perguntasFrequentes',
        politicaPrivacidade: "politicaPrivacidade",
        termoDeUso: "termoDeUso",
        recuperarSenha: 'recuperarSenha'
    }

    state = {
        email: '',
        inputError: {
            email: false,
        },
        liberarAcesso: {
            login: false,
        },
        tela: this.TelaEnum.recuperarSenha,
        carregando: false,
    }

    async recuperarSenha() {
        this.setState({ carregando: true });
        let pessoa = new Pessoa();
        let listaPesquisa = [];
        let inputError = this.state.inputError;
        this?.msgs1?.current?.clear();
        Object.keys(inputError).forEach(pKey => {
            inputError[pKey] = false;
        });

        if (!this.state.email) {
            inputError.email = true;
            this.setState({ inputError: inputError, carregando: false, });
            return
        }

        let pesquisa = new Pesquisa();
        pesquisa.campo = 'pessoa.seq_usuario';
        pesquisa.conteudo = this.constante.seqUsuario;
        pesquisa.operacao = '';
        pesquisa.tipo = 'TEXTO';
        listaPesquisa.push(pesquisa);

        pesquisa = new Pesquisa();
        pesquisa.campo = 'pessoa.email_plataforma_lirida';
        pesquisa.conteudo = this.state.email;
        pesquisa.operacao = 'AND';
        pesquisa.tipo = 'TEXTO';
        listaPesquisa.push(pesquisa);

        pesquisa = new Pesquisa();
        pesquisa.campo = 'pessoa.seq_pessoa_perfil';
        pesquisa.conteudo = this.constante.seqPessoaPerfilContador;
        pesquisa.operacao = 'AND';
        pesquisa.tipo = 'TEXTO';
        listaPesquisa.push(pesquisa);

        let retorno = await this.pessoaService.listarComFiltro(listaPesquisa);
        pessoa = retorno.objeto[0];

        if (pessoa?.emailPlataformaLirida === this.state.email) {
            this.util.enviarEmailSenha(this.constante.seqEmailRecuperarSenha, pessoa.emailPlataformaLirida, pessoa.nome, pessoa.senha)
            this.msgs1.current.show([{ severity: 'success', summary: 'Seu acesso foi enviado para o seu e-mail.', }]);
            window.scrollTo({ top: 0, behavior: 'smooth' })
            this.setState({ carregando: false })
            setTimeout(() => { window.location.href = '/login' }, 3200);
        } else {
            this.setState({
                inputError: {
                    ...inputError, email: true
                },
                carregando: false,
            });
            this.msgs1.current.show([{ severity: 'error', summary: 'E-mail de acesso inválido!', sticky: true }]);
        }

    }

    navegar(pTela) {
        const liberarAcesso = this.state.liberarAcesso;
        liberarAcesso[pTela] = true;
        this.setState({ liberarAcesso: liberarAcesso });
    }

    render() {
        return <div>
            {/* NAVEGAÇÃO */}
            {this.state.liberarAcesso.login && <Navigate to="/login" />}
            {this.state.tela === this.TelaEnum.principal && <Navigate to="/" />}
            {this.state.tela === this.TelaEnum.sobre && <Navigate to="/#sobre" />}
            {this.state.tela === this.TelaEnum.funcionalidades && <Navigate to="/#funcionalidades" />}
            {this.state.tela === this.TelaEnum.depoimentos && <Navigate to="/#depoimentos" />}
            {this.state.tela === this.TelaEnum.perguntasFrequentes && <Navigate to="/#perguntas-frequentes" />}
            {this.state.tela === this.TelaEnum.termoDeUso && <Navigate to="/termoDeUso" />}
            {this.state.tela === this.TelaEnum.politicaPrivacidade && <Navigate to="/politicaDePrivacidade" />}
            {this.state.tela === this.TelaEnum.login && <Navigate to="/login" />}
            {/* TELA */}
            {/* <header style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', backgroundColor: 'var(--primary-color)', padding: 10 }}>
                <div style={{ width: 110, height: 60 }}>
                    <img
                        style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                        src={LogoBranca}
                        alt="Logo ContadorFlex na cor branca." />
                </div>
            </header> */}
            <header className="h-header" style={{ position: 'static' }} >
                <div className="h-header-container">

                    <img src={LogoBranca} alt="logo" style={{ cursor: 'pointer' }} onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })} />

                    <nav>
                        {/* <a href="#sobre" onClick={() => this.setState({ tela: this.TelaEnum.sobre })}>Sobre</a>
                        <a href="#funcionalidades" onClick={() => this.setState({ tela: this.TelaEnum.funcionalidades })}>Funcionalidades</a>
                        <a href="#depoimentos" onClick={() => this.setState({ tela: this.TelaEnum.depoimentos })}>Depoimentos</a>
                        <a href="#perguntas-frequentes" onClick={() => this.setState({ tela: this.TelaEnum.perguntasFrequentes })}>Perguntas frequentes</a>

                        <a onClick={() => this.setState({ tela: this.TelaEnum.login })}>Login</a>
                        <button onClick={() => this.setState({ sidebarVisivel: true })}>
                            <i className="pi pi-bars"></i>
                        </button> */}
                    </nav>
                </div>
            </header>

            <main style={{
                width: '100%',
                height: '100%',
                margin: 'auto',
                display: 'flex',
                justifyContent: 'center',
            }}>

                <section style={{
                    maxWidth: '1280px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    padding: '10px',
                }}>
                    <div style={{ width: '40%', height: '60%' }}>
                        <img
                            style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                            src={LogoEscura}
                            alt="Logo ContadorFlex na cor escura." />

                    </div>

                    <form onSubmit={e => { e.preventDefault(); this.recuperarSenha(e.target) }} style={{ width: '50%', display: 'flex', flexDirection: 'column', gap: 20 }}>
                        <Messages ref={this.msgs1} />

                        <h1>Recuperar senha</h1>

                        <div>
                            <label>E-mail</label><br />
                            <InputText
                                onChange={pEvento => this.setState({ email: pEvento.target.value })}
                                type='email'
                                style={{ width: '100%', borderColor: this.state.inputError.email && 'var(--red-600)' }} />
                            {this.state.inputError.email && <small style={{ color: 'var(--red-600)' }}>E-mail inválido</small>}
                        </div>

                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Button label="Voltar para login" type="button" className="p-button-link" style={{ padding: 0 }}
                                onClick={() => this.navegar('login')} />
                            <Button label="Enviar" type="submit" loading={this.state.carregando} onClick={() => this.recuperarSenha()} />
                        </div>
                    </form>
                </section>
            </main>

            <footer className="h-footer-container">
                <section >
                    <div>
                        <a
                            href="#sobre"
                            onClick={() => this.setState({ tela: this.TelaEnum.sobre })}>
                            Quem somos
                        </a>

                        <a
                            href="#funcionalidades"
                            onClick={() => this.setState({ tela: this.TelaEnum.funcionalidades })}>
                            Funcionalidade
                        </a>

                        <a
                            href="#depoimentos"
                            onClick={() => this.setState({ tela: this.TelaEnum.depoimentos })}>
                            Depoimentos
                        </a>

                        <a
                            href="#perguntas-frequentes"
                            onClick={() => this.setState({ tela: this.TelaEnum.perguntasFrequentes })}>
                            Perguntas frequentes
                        </a>

                        <a
                            onClick={() => {
                                window.scrollTo({ top: 0, behavior: 'smooth' });
                                this.setState({ tela: this.TelaEnum.login });
                            }}>
                            login
                        </a>
                    </div>

                    <div>
                        <a
                            onClick={() => {
                                window.scrollTo({ top: 0, behavior: 'smooth' });
                                this.setState({ tela: this.TelaEnum.politicaPrivacidade });
                            }}>
                            Política de privacidade
                        </a>

                        <a
                            href=''
                            onClick={() => {
                                this.setState({ tela: this.TelaEnum.termoDeUso });
                            }}>
                            Termo de uso
                        </a>
                    </div>

                    <div>
                        <a
                            href="https://www.facebook.com/profile.php?id=100085863546876" target='_blank'>
                            Facebook
                        </a>

                        <a
                            href="https://www.instagram.com/contadorflex/" target='_blank'>
                            Instagram
                        </a>
                    </div>
                </section>
                <img src={LogoBranca} alt="logo rodapé" />
            </footer>
        </div>
    }
}