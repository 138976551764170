import { Component, createRef } from "react";

import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { TabView, TabPanel } from 'primereact/tabview';
import { Dialog } from 'primereact/dialog';
import { Calendar } from "primereact/calendar";
import { InputNumber } from "primereact/inputnumber";
import { Toast } from "primereact/toast";
import { InputTextarea } from 'primereact/inputtextarea';

import Header from "../components/Header";
import Constante from "../Util/Constante";
import Util from "../Util/Util";

import Documento from "lirida-back-service/Servicos/Documento/Documento";
import DocumentoRelacionamento from "lirida-back-service/Servicos/DocumentoRelacionamento/DocumentoRelacionamento";
import DocumentoService from 'lirida-back-service/Servicos/Documento/DocumentoService'
import Pessoa from "lirida-back-service/Servicos/Pessoa/Pessoa";
import PessoaAtendimento from "lirida-back-service/Servicos/PessoaAtendimento/PessoaAtendimento";
import PessoaService from "lirida-back-service/Servicos/Pessoa/PessoaService";
import Endereco from "lirida-back-service/Servicos/Endereco/Endereco";
import CepService from "lirida-back-service/Servicos/Cep/CepService";
import FinanceiroService from "lirida-back-service/Servicos/Financeiro/FinanceiroService";
export default class PessoaDetalhePg extends Component {
    toast = createRef();
    constante = new Constante();
    util = new Util();
    pessoaService = new PessoaService(this.constante.token, this.constante.url_base);
    documentoService = new DocumentoService(this.constante.token, this.constante.url_base);
    cepService = new CepService(this.constante.token, this.constante.url_base);
    financeiroService = new FinanceiroService(this.constante.token, this.constante.url_base);

    state = {
        pessoaUsuario: new Pessoa(),
        pessoaEmpresa: new Pessoa(),
        documento: new Documento(),
        documentoRelacionamento: new DocumentoRelacionamento(),
        endereco: new Endereco(),
        pessoAtendimentoSolicitacao: new PessoaAtendimento(),

        listaSolicitacao: [],
        listaDocumento: [],
        listaCobranca: [],
        listaNotaFiscal: [],

        salvarCarregando: false,
        deletarCarregando: false,
        vModalDeletar: false,
        vModalContrato: false,
        vModalFinalizarContrato: false,
        salvarCheck: false,

        validarInputsPessoaEmpresa: {
            nome: false,
            apelido: false,
            inscricaoEstadual: false,
            inscricaoMunicipal: false,
            cnpj: false,
            responsavelNome: false,
            cpfResponsavel: false,
            email: false,
            telefone: false,
            cep: false,
            logradouro: false,
            bairro: false,
            numero: false,
            cidade: false,
            uf: false,
            pais: false,
            codigoMunicipio: false,
            codigoUf: false,
            codigoPais: false,
        },
        validarInputsContrato: {
            dataProximaCobranca: false,
            dataInicio: false,
            valor: false,
        },

        listaRegimeTributario: [
            { label: '1 - Simples Nacional', valor: '1' },
            { label: '2 - Simples Nacional – excesso de sublimite de receita bruta', valor: '2' },
            { label: '3 - Regime Normal', valor: '3' }
        ],
        listaIssRetido: [
            { label: 'Sim', valor: 'true' },
            { label: 'Não', valor: 'false' }
        ],
    }
    componentDidMount() {
        this.iniciar();
    }

    async iniciar() {
        const pessoaUsuario = await this.util.buscarLocalStorage("PESSOA_USUARIO");
        console.log('PESSOA USUARIO');
        console.log(pessoaUsuario);
        if (!pessoaUsuario?._seqPessoa) {
            window.history.back()
        }
        let seqPessoa = await this.util.buscarParametros();
        let retornoPessoa = await this.pessoaService.buscarPorSeqPessoa(seqPessoa?.id, this.constante.seqUsuario)
        let pessoaEmpresa = await retornoPessoa?.objeto;
        let endereco = new Endereco()

        let listaDocumento = await this.listarDocumento(pessoaEmpresa);
        let listaEndereco = await this.pessoaService.listarEndereco(pessoaEmpresa);
        if (listaEndereco?.objeto[0]) {
            endereco = listaEndereco.objeto[0]
        }
        console.log("ENDERECO", endereco)
        console.log('PESSOA EMPRESA NA TELA', pessoaEmpresa);

        let listaCobranca = await this.listarCobranca(pessoaEmpresa);
        console.log("LISTA DE COBRANCA", listaCobranca);
        let listaNotaFiscal = await this.listarNotaFiscal(pessoaEmpresa);

        let listaSolicitacao = await this.listarSolicitacao(pessoaEmpresa);
        this.setState({
            pessoaUsuario: pessoaUsuario,
            pessoaEmpresa: pessoaEmpresa,
            listaDocumento: listaDocumento,
            endereco: endereco,
            listaCobranca: listaCobranca,
            listaNotaFiscal: listaNotaFiscal,
            listaSolicitacao: listaSolicitacao,
        })

    }

    async salvar() {
        this.setState({ salvarCarregando: true });
        let inputsOk = await this.validarInputsPessoaEmpresa();
        if (!inputsOk) {
            this.setState({
                salvarCarregando: false,
            })
            return
        }
        let pessoaEmpresa = this.state.pessoaEmpresa;

        let endereco = this.state.endereco;
        endereco.seqUsuario = this.constante.seqUsuario;
        endereco.seqPessoa = pessoaEmpresa._seqPessoa;
        endereco.seqTipoEndereco = this.constante.seqTipoEnderecoCobranca;
        let retornoEndereco = await this.pessoaService.salvarEndereco(endereco);
        endereco = retornoEndereco?.objeto;
        console.log("Endereco salvo", endereco)

        pessoaEmpresa.seqEnderecoPrincipal = endereco?._seqEndereco;

        let retornoPessoa = await this.pessoaService.salvar(pessoaEmpresa);
        pessoaEmpresa = retornoPessoa?.objeto
        console.log("Pessoa salva", pessoaEmpresa)


        let retornoFiscal = await this.pessoaService.salvarFiscal(pessoaEmpresa);
        pessoaEmpresa = retornoFiscal.objeto
        console.log("INTEGRAÇÃO FOCUS NFE SUCESSO", pessoaEmpresa)
        this.toast.current.show([
            { severity: 'success', summary: 'Informações salva com sucesso!' },
        ]);

        this.listarCobranca();
        this.setState({
            salvarCarregando: false,
            salvarCheck: true,
            pessoaEmpresa: pessoaEmpresa,
            endereco: endereco,
        })
        this.setState({ salvarCheck: false, });
    }

    async salvarDocumento(pDocumento) {
        this.setState({ salvarCarregando: true, salvarCheck: false });
        let pessoaEmpresa = this.state.pessoaEmpresa;
        let pessoaUsuario = this.state.pessoaUsuario;
        let documento;
        if (pDocumento) {
            documento = pDocumento;
        } else {
            documento = this.state.documento;
        }

        let inputsOk = await this.validarInputsContrato();
        if (!inputsOk) {
            this.setState({ salvarCarregando: false })
            return
        }
        let documentoNovo = false
        if (!documento._seqDocumento) {
            documentoNovo = true;
        }

        documento.seqUsuario = this.constante.seqUsuario;
        documento.seqPessoa = pessoaEmpresa._seqPessoa;
        documento.seqTipoDocumento = this.constante.seqTipoDocumentoContrato;
        documento.seqContaBancaria = this.constante.seqContaBancariaPrincipal;
        documento.seqCondicaoPagamento = this.constante.seqCondicaoPagamentoAvista;
        documento.seqFormaPagamento = this.constante.seqFormaPagamentoPix;
        documento.seqTipoDocumentoProcesso = this.constante.seqPrimeiroProcessoDocumento;
        documento.seqPessoaRecebedor = pessoaUsuario._seqPessoa;
        documento.seqContaFinanceira = this.constante.seqContaFinanceiraAnalitica;
        documento.seqPeriodo = this.constante.seqPeriodo;
        documento.valorLiquido = documento.valorTotal;

        let retornoDocumento = await this.documentoService.salvar(documento);
        documento = retornoDocumento.objeto;
        console.log("DOCUMENTO SALVO", documento);

        pessoaEmpresa.tags5 = 'S';
        let retornoPessoaEmpresa = await this.pessoaService.salvar(pessoaEmpresa);
        pessoaEmpresa = retornoPessoaEmpresa.objeto;

        if (documentoNovo === true) {
            let documentoRelacionamento = new DocumentoRelacionamento();
            documentoRelacionamento.seqUsuario = this.constante.seqUsuario;
            documentoRelacionamento.seqDocumento = documento._seqDocumento;
            documentoRelacionamento.seqTipoRelacionamento = this.constante.seqRelacionamentoContadorResponsavel;
            documentoRelacionamento.seqPessoaRelacionada = pessoaUsuario._seqPessoa;
            let retornoDocumentoRelacionamento = await this.documentoService.salvarRelacionamento(documentoRelacionamento)
            documentoRelacionamento = retornoDocumentoRelacionamento.objeto;
            console.log('Relacionamento salvo', documentoRelacionamento)
            await this.documentoService.gerarMovimentoFinanceiro(documento);
        }

        this.toast.current.show([
            { severity: 'success', summary: 'Contrato salvo com sucesso!' },
        ]);

        this.setState({ salvarCarregando: false, salvarCheck: true });
        setTimeout(() =>
            this.setState({ salvarCheck: false }), 1000
        )

        this.listarDocumento()
        this.listarCobranca();
        this.setState({ vModalContrato: false })
    }

    async listarDocumento(pPessoa) {
        let pessoaEmpresa;
        if (pPessoa) {
            pessoaEmpresa = pPessoa
        } else {
            pessoaEmpresa = this.state.pessoaEmpresa;
        }

        let listaPesquisa = [
            this.util.criarObjetoPesquisa(
                "documento.seq_usuario",
                this.constante.seqUsuario,
                '',
                'TEXTO'
            ),
            this.util.criarObjetoPesquisa(
                "documento.seq_pessoa",
                pessoaEmpresa?._seqPessoa,
                'AND',
                'TEXTO'
            )
        ]

        let retorno = await this.documentoService.listarComFiltro(listaPesquisa);
        let listaDocumento = retorno.objeto;

        this.setState({
            listaDocumento: listaDocumento,
        })
        return listaDocumento
    }


    async listarCobranca(pPessoa) {
        let pessoaEmpresa;
        if (pPessoa) {
            pessoaEmpresa = pPessoa;
        } else {
            pessoaEmpresa = this.state.pessoaEmpresa;
        }

        let retornoListaFinanceiro = await this.financeiroService.listar(this.constante.seqUsuario, pessoaEmpresa?._seqPessoa)

        let listaCobranca = retornoListaFinanceiro?.objeto;

        this.setState({ listaCobranca: listaCobranca })
        return listaCobranca
    }


    async listarNotaFiscal(pPessoa) {
        let pessoaEmpresa;
        if (pPessoa) {
            pessoaEmpresa = pPessoa;
        } else {
            pessoaEmpresa = this.state.pessoaEmpresa;
        }

        let listaPesquisa = [
            this.util.criarObjetoPesquisa(
                "documento.seq_usuario",
                this.constante.seqUsuario,
                "",
                "TEXTO",
            ),
            this.util.criarObjetoPesquisa(
                "documento.seq_pessoa_recebedor",
                pessoaEmpresa._seqPessoa,
                "AND",
                "TEXTO"
            )
        ]

        let retorno = await this.documentoService.listarComFiltro(listaPesquisa);
        let listaNotaFiscal = retorno.objeto;

        this.setState({
            listaNotaFiscal: listaNotaFiscal
        })
        return listaNotaFiscal
    }

    async listarSolicitacao(pPessoaEmpresa) {
        let pessoaEmpresa = this.state.pessoaEmpresa;
        if (pPessoaEmpresa) {
            pessoaEmpresa = pPessoaEmpresa
        }

        let retorno = await this.pessoaService.listarAtendimento(pessoaEmpresa);
        let listaSolicitacao = retorno.objeto;
        this.setState({
            listaSolicitacao: listaSolicitacao
        });
        return listaSolicitacao
    }

    // async finalizarDocumento() {
    //     let documento = this.state.documento;
    //     let data = new Date();
    //     data = data.toISOString();
    //     data = data.split('T')
    //     data = data[0].split('-').reverse().join('-') + 'T00:00:00'
    //     documento.dataFinal = data;

    //     this.salvarDocumento(documento)

    //     this.setState({ vModalFinalizarContrato: false })
    // }


    validarInputsPessoaEmpresa() {
        const inputs = this.state.validarInputsPessoaEmpresa;
        const listaKey = Object.keys(inputs);

        for (let i = 0; i < listaKey.length; i++) {
            inputs[listaKey[i]] = false;
        }

        if (!this.state.pessoaEmpresa.nome) {
            inputs.nome = true;
        }

        if (!this.state.pessoaEmpresa.apelido) {
            inputs.apelido = true;
        }
        if (!this.state.pessoaEmpresa.documento) {
            inputs.cnpj = true
        }

        if (this.state.pessoaEmpresa.documento && this.state.pessoaEmpresa.documento.length < 18) {
            inputs.cnpj = true
        }

        if (!this.state.pessoaEmpresa.responsavelNome) {
            inputs.responsavelNome = true;
        }
        if (!this.state.pessoaEmpresa.responsavelCpf) {
            inputs.cpfResponsavel = true;
        }

        if (this.state.pessoaEmpresa.responsavelCpf && this.state.pessoaEmpresa.length < 14) {
            inputs.cpfResponsavel = true;
        }

        if (!this.state.pessoaEmpresa.emailPlataformaLirida) {
            inputs.email = true;
        }

        if (!this.state.pessoaEmpresa.telefonePlataformaLirida) {
            inputs.telefone = true;
        }


        if (!this.state.endereco.cep) {
            inputs.cep = true
        }

        if (!this.state.endereco.logradouro) {
            inputs.logradouro = true;
        }

        if (!this.state.endereco.bairro) {
            inputs.bairro = true;
        }

        if (!this.state.endereco.numero) {
            inputs.numero = true;
        }

        if (!this.state.endereco.cidade) {
            inputs.cidade = true;
        }

        if (!this.state.endereco.uf) {
            inputs.uf = true;
        }

        if (!this.state.endereco.pais) {
            inputs.pais = true;
        }

        if (!this.state.endereco.codigoMunicipio) {
            inputs.codigoMunicipio = true;
        }

        if (!this.state.endereco.codigoUf) {
            inputs.codigoUf = true;
        }

        if (!this.state.endereco.codigoPais) {
            inputs.codigoPais = true
        }
        let inputsOk = true;


        for (let i = 0; i < listaKey.length; i++) {
            if (inputs[listaKey[i]]) {
                inputsOk = false
            }
        }

        this.setState({
            validarInputsPessoaEmpresa: inputs
        })
        return inputsOk
    }


    validarInputsContrato() {
        const inputsContrato = this.state.validarInputsContrato;
        let listaKey = Object.keys(inputsContrato);

        for (let i = 0; i < listaKey.length; i++) {
            inputsContrato[listaKey[i]] = false;
        }

        if (!this.state.documento.dataProximaRecorrencia) {
            inputsContrato.dataProximaCobranca = true;
        }
        if (!this.state.documento.dataInicio) {
            inputsContrato.dataInicio = true;
        }

        if (!this.state.documento.valorTotal) {
            inputsContrato.valor = true;
        }

        let inputsOK = true;

        for (let i = 0; i < listaKey.length; i++) {
            if (inputsContrato[listaKey[i]]) {
                inputsOK = false
            }
        }
        this.setState({ validarInputsContrato: inputsContrato })
        return inputsOK
    }


    novoContrato() {
        const inputsContrato = this.state.validarInputsContrato;
        let listaKey = Object.keys(inputsContrato);

        for (let i = 0; i < listaKey.length; i++) {
            inputsContrato[listaKey[i]] = false;
        }
        this.setState({ validarInputsContrato: inputsContrato, vModalContrato: true })
    }

    async buscarCep() {
        let endereco = this.state.endereco;
        let retorno = await this.cepService.buscarCep(endereco?.cep);
        console.log(retorno)
        endereco.logradouro = retorno?.logradouro;
        endereco.bairro = retorno?.bairro;
        endereco.cidade = retorno?.localidade;
        endereco.complemento = retorno?.complemento;
        endereco.estado = retorno?.estado;
        endereco.codigoMunicipio = retorno?.ibge;
        endereco.uf = retorno?.uf;
        endereco.numero = '';
        console.log(endereco);
        this.setState({
            endereco: endereco
        })
    }

    async selecionarArquivo(e) {
        const retorno = await this.util.pegarBase64(e);
        let base64 = retorno;
        let pessoaEmpresa = this.state.pessoaEmpresa;
        pessoaEmpresa.arquivoCertificado = base64;
        let retornoPessoa = await this.pessoaService.salvar(pessoaEmpresa);
        pessoaEmpresa = retornoPessoa?.objeto;
        this.setState({ pessoaEmpresa: pessoaEmpresa })
    }
    render() {
        return <div>
            {/* NAVEGAÇÃO */}
            <Toast ref={this.toast} />

            <Dialog header="Alerta" visible={this.state.vModalDeletar} style={{ width: 400 }} onHide={() => this.setState({ vModalDeletar: false, })}>
                <div style={{ display: "flex", flexDirection: 'column', gap: 20 }}>
                    <p>Deseja realmente deletar?</p>
                    <div style={{ display: 'flex', gap: 10, justifyContent: "right" }}>
                        <Button label="Sim" onClick={() => {
                            this.deletarDocumento();
                            this.setState({ vModalDeletar: false, });
                        }} />
                        <Button label="Não" onClick={() => this.setState({ vModalDeletar: false, })} />
                    </div>
                </div>
            </Dialog>

            <Dialog header="Atenção" visible={this.state.vModalFinalizarContrato} style={{ width: 400 }} onHide={() => this.setState({ vModalFinalizarContrato: false, })}>
                <div style={{ display: "flex", flexDirection: 'column', gap: 20 }}>
                    <p>Deseja realmente finalizar o contrato</p>
                    <div style={{ display: 'flex', gap: 10, justifyContent: "right" }}>
                        <Button label="Sim" onClick={() => {
                            this.finalizarDocumento();
                        }} />
                        <Button label="Não" onClick={() => this.setState({ vModalFinalizarContrato: false, })} />
                    </div>
                </div>
            </Dialog>

            <Dialog
                header={this.state.documento?._seqDocumento ? 'Informações do contrato' : 'Novo contrato'}
                visible={this.state.vModalContrato}
                style={{ width: '80rem' }}
                onHide={() => this.setState({ vModalContrato: false, documento: new Documento() })}
            >
                <div style={{ border: '1px solid lightgray', borderRadius: 3, marginTop: 20, padding: 10 }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <h2 style={{ fontSize: 22.5 }}>Informações do contrato</h2>
                    </div>

                    <div style={{ display: 'flex', alignItems: 'center', gap: 20 }}>
                        <div>
                            <label>Data final</label><br />
                            <Calendar
                                showButtonBar
                                onChange={pTexto => this.setState({
                                    documento: {
                                        ...this.state.documento, dataFinal: this.util.formatarDataBanco(pTexto.target.value)
                                    }
                                })}
                                value={this.util.formatarDataCalendar(this.state.documento.dataFinal)}
                                style={{ width: '100%', }} dateFormat="dd/mm/yy" placeholder="dd/mm/yyyy" />
                        </div>

                        <div>
                            <label>Data cadastro</label><br />
                            <Calendar
                                showButtonBar
                                value={this.util.formatarDataCalendar(this.state.documento.dataCadastro)}
                                disabled
                                style={{ width: '100%' }}
                                dateFormat="dd/mm/yy"
                                placeholder="dd/mm/yyyy" />
                        </div>
                    </div>

                    <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr", gap: 20, marginTop: 20 }}>
                        <div>
                            <label>Data próxima cobrança</label><br />
                            <Calendar
                                onChange={pTexto => this.setState({
                                    documento: {
                                        ...this.state.documento, dataProximaRecorrencia: this.util.formatarDataBanco(pTexto.target.value)
                                    }
                                })}
                                showButtonBar
                                value={this.util.formatarDataCalendar(this.state.documento.dataProximaRecorrencia)}
                                dateFormat="dd/mm/yy"
                                placeholder="dd/mm/yyyy"
                                inputStyle={{
                                    borderColor: this.state.validarInputsContrato.dataProximaCobranca && 'var(--red-600)'
                                }}
                                style={{
                                    width: '100%',
                                }}
                            />
                            {this.state.validarInputsContrato.dataProximaCobranca && <small style={{ color: 'var(--red-600)' }}>Data inválida</small>}
                        </div>

                        <div>
                            <label>Data inicio</label><br />
                            <Calendar
                                showButtonBar
                                onChange={pTexto => this.setState({
                                    documento: {
                                        ...this.state.documento, dataInicio: this.util.formatarDataBanco(pTexto.target.value)
                                    }
                                })}
                                value={this.util.formatarDataCalendar(this.state.documento.dataInicio)}
                                style={{ width: '100%', }}
                                dateFormat="dd/mm/yy"
                                placeholder="dd/mm/yyyy"
                                inputStyle={{
                                    borderColor: this.state.validarInputsContrato.dataInicio && 'var(--red-600)'
                                }}
                            />
                            {this.state.validarInputsContrato.dataInicio && <small style={{ color: 'var(--red-600)' }}>Data inválida</small>}
                        </div>



                        <div>
                            <label>Valor</label><br />
                            <InputNumber
                                onValueChange={pTexto => this.setState({
                                    documento: {
                                        ...this.state.documento, valorTotal: pTexto.target.value
                                    }
                                })}
                                mode="currency"
                                currency="BRL"
                                locale="pt-BR"
                                value={this.state.documento.valorTotal}
                                style={{ width: '100%' }}
                                inputStyle={{
                                    borderColor: this.state.validarInputsContrato.valor && 'var(--red-600)'
                                }}
                            />
                            {this.state.validarInputsContrato.valor && <small style={{ color: 'var(--red-600)' }}>Valor</small>}
                        </div>
                    </div>
                    <div style={{ marginTop: 15 }}>
                        <label>Observação</label><br />
                        <InputTextarea
                            onChange={pTexto => this.setState({
                                documento: {
                                    ...this.state.documento, observacao: pTexto.target.value
                                }
                            })}
                            value={this.state.documento.observacao}
                            style={{ width: '100%' }} />
                    </div>
                </div>

                <div style={{ display: 'flex', justifyContent: 'flex-end', margin: '10px 0' }}>
                    {/* <div style={{ display: 'flex', alignItems: 'center', width: '15rem', justifyContent: this.state.documento._seqDocumento ? 'space-between' : 'flex-end' }}> */}

                    {this.state.salvarCheck ?
                        <Button label="Salvo" icon={<i className="pi pi-check"></i>} className="p-button-success" />
                        :
                        <Button loading={this.state.salvarCarregando} label="Salvar" className="p-button-raised" onClick={() => this.salvarDocumento()} />
                    }

                    {/* {this.state.documento?._seqDocumento && <Button loading={this.state.salvarCarregando}
                            label="Finalizar"
                            className="p-button-raised"
                            disabled={this.state.documento.dataFinal ? true : false}
                            onClick={() => this.setState({ vModalFinalizarContrato: true })} />} */}



                    {/* </div> */}
                </div>
            </Dialog>

            <Dialog header="Solicitação detalhe" visible={this.state.vModalSolicitacao} style={{ minWidth: 700, maxWidth: '60vw' }} onHide={() => this.setState({ vModalSolicitacao: false, pessoAtendimentoSolicitacao: new PessoaAtendimento() })}>
                <section style={{ display: 'grid', gridTemplateColumns: '1fr' }}>
                    <label>Data cadastro</label>
                    <InputText
                        style={{ color: '#000' }}
                        value={this.util.formatarData(this.state?.pessoAtendimentoSolicitacao?.dataCadastro)}
                        disabled />

                    <label style={{ marginTop: 10 }}>Assunto</label>
                    <InputText
                        style={{ color: '#000' }}
                        value={this.state.pessoAtendimentoSolicitacao?.descricao?.split('/')[0]}
                        disabled />

                    <label style={{ marginTop: 10 }}>Descrição</label>
                    <InputTextarea
                        style={{ color: '#000' }}
                        value={this.state.pessoAtendimentoSolicitacao?.descricao?.split('/')[1]}
                        disabled
                    />
                </section>
            </Dialog>
            {/* TELA */}
            <Header />

            <main style={{ width: '95%', margin: '20px auto' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', border: '1px solid lightgray', padding: 10, borderRadius: 3 }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 20, margin: '20px 0' }}>
                        <Button label={<i className="pi pi-arrow-left"></i>} className="p-button-raised"
                            onClick={() => window.history.back()} />
                        <div>
                            <h1 style={{ fontSize: 22 }}>Detalhe do cliente</h1>
                            <h2 style={{ fontSize: 20, color: 'gray' }}>Informações do cliente</h2>
                        </div>
                    </div>

                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 10, }}>
                        {this.state.salvarCheck ?
                            <Button label="Salvo" icon={<i className="pi pi-check"></i>} className="p-button-success" />
                            :
                            <Button loading={this.state.salvarCarregando} label="Salvar" className="p-button-raised" onClick={() => this.salvar()} />
                        }
                        {/* <Button loading={this.state.deletarCarregando}
                            label="Deletar"
                            className="p-button-danger" onClick={() => this.setState({ vModalDeletar: true, })} /> */}
                    </div>
                </div>

                <div style={{ border: '1px solid lightgray', borderRadius: 3, marginTop: 20, padding: 10 }}>
                    <h2 style={{ fontSize: 22.5 }}>Informações da empresa</h2>
                    <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr", gap: 20, marginTop: 20 }}>

                        <div>
                            <label>Razão social</label><br />
                            <InputText
                                onChange={(pTexto) => this.setState({
                                    pessoaEmpresa: {
                                        ...this.state.pessoaEmpresa, nome: pTexto.target.value
                                    }
                                })}
                                value={this.state.pessoaEmpresa.nome || ''}
                                style={{ width: '100%', borderColor: this.state.validarInputsPessoaEmpresa.nome && 'var(--red-600)' }} />
                            {this.state.validarInputsPessoaEmpresa.nome && <small style={{ color: 'var(--red-600)' }}>Razão social inválido</small>}
                        </div>

                        <div>
                            <label>Nome fantasia</label><br />
                            <InputText
                                onChange={(pTexto) => this.setState({
                                    pessoaEmpresa: {
                                        ...this.state.pessoaEmpresa, apelido: pTexto.target.value
                                    }
                                })}
                                value={this.state.pessoaEmpresa.apelido || ''}
                                style={{ width: '100%', borderColor: this.state.validarInputsPessoaEmpresa.apelido && 'var(--red-600)' }} />
                            {this.state.validarInputsPessoaEmpresa.apelido && <small style={{ color: 'var(--red-600)' }}>Nome fantasia inválido</small>}
                        </div>

                        <div>
                            <label>CNPJ</label><br />
                            <InputText
                                onChange={(pTexto) => this.setState({
                                    pessoaEmpresa: {
                                        ...this.state.pessoaEmpresa, documento: this.util.formatarCNPJ(pTexto.target.value)
                                    }
                                })}
                                value={this.state.pessoaEmpresa.documento || ''}
                                style={{ width: '100%', borderColor: this.state.validarInputsPessoaEmpresa.cnpj && 'var(--red-600)' }} />
                            {this.state.validarInputsPessoaEmpresa.cnpj && <small style={{ color: 'var(--red-600)' }}>CNPJ inválido</small>}
                        </div>

                        <div>
                            <label>Inscrição estadual</label><br />
                            <InputText
                                onChange={(pTexto) => this.setState({
                                    pessoaEmpresa: {
                                        ...this.state.pessoaEmpresa, inscricaoEstadual: pTexto.target.value
                                    }
                                })}
                                value={this.state.pessoaEmpresa.inscricaoEstadual || ''}
                                style={{ width: '100%', borderColor: this.state.validarInputsPessoaEmpresa.inscricaoEstadual && 'var(--red-600)' }} />
                            {this.state.validarInputsPessoaEmpresa.inscricaoEstadual && <small style={{ color: 'var(--red-600)' }}>Inscrição estadual inválido</small>}
                        </div>

                        <div>
                            <label>Inscrição municipal</label><br />
                            <InputText
                                onChange={(pTexto) => this.setState({
                                    pessoaEmpresa: {
                                        ...this.state.pessoaEmpresa, inscricaoMunicipal: pTexto.target.value
                                    }
                                })}
                                value={this.state.pessoaEmpresa.inscricaoMunicipal || ''}
                                style={{ width: '100%', borderColor: this.state.validarInputsPessoaEmpresa.inscricaoMunicipal && 'var(--red-600)' }} />
                            {this.state.validarInputsPessoaEmpresa.inscricaoMunicipal && <small style={{ color: 'var(--red-600)' }}>Inscrição municipal inválido</small>}
                        </div>

                        <div>
                            <label>Nome responsável</label><br />
                            <InputText
                                onChange={(pTexto) => this.setState({
                                    pessoaEmpresa: {
                                        ...this.state.pessoaEmpresa, responsavelNome: pTexto.target.value
                                    }
                                })}
                                value={this.state.pessoaEmpresa.responsavelNome || ''}
                                style={{ width: '100%', borderColor: this.state.validarInputsPessoaEmpresa.responsavelNome && 'var(--red-600)' }} />
                            {this.state.validarInputsPessoaEmpresa.responsavelNome && <small style={{ color: 'var(--red-600)' }}>Nome inválido</small>}
                        </div>


                        <div>
                            <label>CPF responsável</label><br />
                            <InputText
                                onChange={(pTexto) => this.setState({
                                    pessoaEmpresa: {
                                        ...this.state.pessoaEmpresa, responsavelCpf: this.util.formatarCPF(pTexto.target.value)
                                    }
                                })}
                                value={this.state.pessoaEmpresa.responsavelCpf || ''}
                                style={{ width: '100%', borderColor: this.state.validarInputsPessoaEmpresa.cpfResponsavel && 'var(--red-600)' }} />
                            {this.state.validarInputsPessoaEmpresa.cpfResponsavel && <small style={{ color: 'var(--red-600)' }}>CPF inválido</small>}
                        </div>

                        <div>
                            <label>E-mail</label><br />
                            <InputText
                                onChange={(pTexto) => this.setState({
                                    pessoaEmpresa: {
                                        ...this.state.pessoaEmpresa, emailPlataformaLirida: pTexto.target.value
                                    }
                                })}
                                value={this.state.pessoaEmpresa.emailPlataformaLirida || ''}
                                style={{ width: '100%', borderColor: this.state.validarInputsPessoaEmpresa.email && 'var(--red-600)' }} />
                            {this.state.validarInputsPessoaEmpresa.email && <small style={{ color: 'var(--red-600)' }}>E-mail inválido</small>}
                        </div>

                        <div>
                            <label>Telefone</label><br />
                            <InputText
                                onChange={(pTexto) => this.setState({
                                    pessoaEmpresa: {
                                        ...this.state.pessoaEmpresa, telefonePlataformaLirida: this.util.formatarTelefone(pTexto.target.value)
                                    }
                                })}
                                value={this.state.pessoaEmpresa.telefonePlataformaLirida || ''}
                                style={{ width: '100%', borderColor: this.state.validarInputsPessoaEmpresa.telefone && 'var(--red-600)' }} />
                            {this.state.validarInputsPessoaEmpresa.telefone && <small style={{ color: 'var(--red-600)' }}>Telefone inválido</small>}
                        </div>

                    </div>
                </div>

                <div style={{ border: '1px solid lightgray', borderRadius: 3, marginTop: 20, padding: 10 }}>
                    <h2 style={{ fontSize: 22.5 }}>Endereço</h2>
                    <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr 1fr", gap: 20, marginTop: 20 }}>

                        <div>
                            <label>CEP</label><br />
                            <div className="p-inputgroup">
                                <InputText
                                    onChange={pTexto => this.setState({
                                        endereco: {
                                            ...this.state.endereco, cep: this.util.formatarCEP(pTexto.target.value)
                                        }
                                    })}
                                    value={this.state.endereco.cep || ''}
                                    style={{
                                        width: '50%', height: 40, borderColor: this.state.validarInputsPessoaEmpresa.cep && 'var(--red-600)'
                                    }}
                                />
                                <Button
                                    icon="pi pi-search"
                                    type="button"
                                    onClick={() => this.buscarCep()}
                                />
                            </div>
                            {this.state.validarInputsPessoaEmpresa.cep && <small style={{ color: 'var(--red-600)' }}>CEP inválido</small>}
                        </div>

                        <div>
                            <label>Logradouro</label><br />
                            <InputText
                                onChange={pTexto => this.setState({
                                    endereco: {
                                        ...this.state.endereco, logradouro: pTexto.target.value
                                    }
                                })}
                                value={this.state.endereco.logradouro || ''}
                                style={{
                                    width: '100%', height: 40, borderColor: this.state.validarInputsPessoaEmpresa.logradouro && 'var(--red-600)'
                                }}
                            />
                            {this.state.validarInputsPessoaEmpresa.logradouro && <small style={{ color: 'var(--red-600)' }}>Logradouro inválido</small>}
                        </div>


                        <div>
                            <label>Bairro</label><br />
                            <InputText
                                onChange={pTexto => this.setState({
                                    endereco: {
                                        ...this.state.endereco, bairro: pTexto.target.value
                                    }
                                })}
                                value={this.state.endereco.bairro || ''}
                                style={{
                                    width: '100%', height: 40, borderColor: this.state.validarInputsPessoaEmpresa.bairro && 'var(--red-600)'
                                }}
                            />
                            {this.state.validarInputsPessoaEmpresa.bairro && <small style={{ color: 'var(--red-600)' }}>Bairro inválido</small>}
                        </div>

                        <div>
                            <label>Número</label><br />
                            <InputText
                                onChange={pTexto => this.setState({
                                    endereco: {
                                        ...this.state.endereco, numero: pTexto.target.value
                                    }
                                })}
                                value={this.state.endereco.numero || ''}
                                style={{
                                    width: '100%', height: 40, borderColor: this.state.validarInputsPessoaEmpresa.numero && 'var(--red-600)'
                                }}
                            />
                            {this.state.validarInputsPessoaEmpresa.numero && <small style={{ color: 'var(--red-600)' }}>Número inválido</small>}
                        </div>


                        <div>
                            <label>Complemento</label><br />
                            <InputText
                                onChange={pTexto => this.setState({
                                    endereco: {
                                        ...this.state.endereco, complemento: pTexto.target.value
                                    }
                                })}
                                value={this.state.endereco.complemento || ''}
                                style={{ width: '100%', height: 40 }}
                            />

                        </div>

                        <div>
                            <label>Cidade</label><br />
                            <InputText
                                onChange={pTexto => this.setState({
                                    endereco: {
                                        ...this.state.endereco, cidade: pTexto.target.value
                                    }
                                })}
                                value={this.state.endereco.cidade || ''}
                                style={{
                                    width: '100%', height: 40, borderColor: this.state.validarInputsPessoaEmpresa.cidade && 'var(--red-600)'
                                }}
                            />
                            {this.state.validarInputsPessoaEmpresa.cidade && <small style={{ color: 'var(--red-600)' }}>Cidade inválido</small>}
                        </div>
                        <div>
                            <label>UF</label><br />
                            <InputText
                                onChange={pTexto => this.setState({
                                    endereco: {
                                        ...this.state.endereco, uf: pTexto.target.value
                                    }
                                })}
                                value={this.state.endereco.uf || ''}
                                style={{
                                    width: '100%', height: 40, borderColor: this.state.validarInputsPessoaEmpresa.uf && 'var(--red-600)'
                                }}
                            />
                            {this.state.validarInputsPessoaEmpresa.uf && <small style={{ color: 'var(--red-600)' }}>UF inválido</small>}
                        </div>

                        <div>
                            <label>País</label><br />
                            <InputText
                                onChange={pTexto => this.setState({
                                    endereco: {
                                        ...this.state.endereco, pais: pTexto.target.value
                                    }
                                })}
                                value={this.state.endereco.pais || ''}
                                style={{
                                    width: '100%', height: 40, borderColor: this.state.validarInputsPessoaEmpresa.pais && 'var(--red-600)'
                                }}
                            />
                            {this.state.validarInputsPessoaEmpresa.pais && <small style={{ color: 'var(--red-600)' }}>País inválido</small>}
                        </div>

                        <div>
                            <label>Código municipio</label><br />
                            <InputText
                                onChange={pTexto => this.setState({
                                    endereco: {
                                        ...this.state.endereco, codigoMunicipio: pTexto.target.value
                                    }
                                })}
                                value={this.state.endereco.codigoMunicipio || ''}
                                style={{
                                    width: '100%', height: 40, borderColor: this.state.validarInputsPessoaEmpresa.codigoMunicipio && 'var(--red-600)'
                                }}
                            />
                            {this.state.validarInputsPessoaEmpresa.codigoMunicipio && <small style={{ color: 'var(--red-600)' }}>Código municipio inválido</small>}
                        </div>

                        <div>
                            <label>Código uf</label><br />
                            <InputText
                                onChange={pTexto => this.setState({
                                    endereco: {
                                        ...this.state.endereco, codigoUf: pTexto.target.value
                                    }
                                })}
                                value={this.state.endereco.codigoUf || ''}
                                style={{
                                    width: '100%', height: 40, borderColor: this.state.validarInputsPessoaEmpresa.codigoUf && 'var(--red-600)'
                                }}
                            />
                            {this.state.validarInputsPessoaEmpresa.codigoUf && <small style={{ color: 'var(--red-600)' }}>Código UF inválido</small>}
                        </div>

                        <div>
                            <label>Código país</label><br />
                            <InputText
                                onChange={pTexto => this.setState({
                                    endereco: {
                                        ...this.state.endereco, codigoPais: pTexto.target.value
                                    }
                                })}
                                value={this.state.endereco.codigoPais || ''}
                                style={{
                                    width: '100%', height: 40, borderColor: this.state.validarInputsPessoaEmpresa.codigoPais && 'var(--red-600)'
                                }}
                            />
                            {this.state.validarInputsPessoaEmpresa.codigoPais && <small style={{ color: 'var(--red-600)' }}>Código país inválido</small>}
                        </div>
                    </div>
                </div>


                <div style={{ border: '1px solid lightgray', padding: 10, borderRadius: 3, marginTop: 20 }}>

                    <TabView style={{ marginTop: 10 }} >

                        <TabPanel
                            header="Contratos"
                        >
                            <div style={{
                                padding: 10,
                            }}>
                                <Button label="Novo" onClick={() => this.novoContrato()} />
                            </div>
                            <DataTable
                                selectionMode="single"
                                responsiveLayout="scroll"
                                currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords}"
                                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                showGridlines
                                removableSort
                                paginator
                                rows={10}
                                rowsPerPageOptions={[10, 20, 50]}
                                value={this.state.listaDocumento}
                                onSelectionChange={evento => this.setState({ documento: evento.value, vModalContrato: true })}
                            >
                                <Column
                                    field="valorLiquido"
                                    header="Valor"
                                    sortable
                                    body={item => <p>R${this.util.formatarValor(item.valorLiquido)}</p>} />

                                <Column
                                    field="dataProximaRecorrencia"
                                    header="Data recorrência"
                                    sortable
                                    body={item => <p>{this.util.formatarData(item.dataCadastro)}</p>
                                    } />

                                <Column
                                    field="dataFinal"
                                    header="Data final"
                                    sortable
                                    body={item => <p>{item.dataFinal ? this.util.formatarData(item.dataFinal) : 'Não finalizado'}</p>
                                    } />

                                <Column
                                    field="dataCadastro"
                                    header="Data cadastro"
                                    sortable
                                    body={item => <p>{this.util.formatarData(item.dataCadastro)}</p>
                                    } />
                            </DataTable>
                        </TabPanel>

                        {/* <TabPanel
                            header="Informações fiscais"
                        >
                            <div style={{ border: '1px solid lightgray', borderRadius: 3, marginTop: 20, padding: 10 }}>
                                <h2 style={{ fontSize: 22.5 }}>Informações fiscais</h2>
                                <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: 20, marginTop: 20 }}>

                                    <div>
                                        <label>Regime tributário</label><br />
                                        <Dropdown
                                            onChange={(e) => this.setState({
                                                pessoaEmpresa: {
                                                    ...this.state.pessoaEmpresa, regimeTributario: e.value
                                                }
                                            })}
                                            placeholder="Selecione"
                                            optionValue="valor"
                                            value={this.state.pessoaEmpresa.regimeTributario || ''}
                                            options={this.state.listaRegimeTributario}
                                            style={{
                                                width: '100%', height: 40
                                            }}
                                        />
                                    </div>

                                    <div>
                                        <label>ISS retido</label><br />
                                        <Dropdown
                                            onChange={(e) => this.setState({
                                                pessoaEmpresa: {
                                                    ...this.state.pessoaEmpresa, issRetido: e.value
                                                }
                                            })}
                                            placeholder="Selecione"
                                            optionValue="valor"
                                            value={this.state.pessoaEmpresa.issRetido || ''}
                                            options={this.state.listaIssRetido}
                                            style={{
                                                width: '100%', height: 40
                                            }}
                                        />
                                    </div>

                                    <div>
                                        <label>Serie NFE Produção</label><br />
                                        <InputText
                                            onChange={pTexto => this.setState({
                                                pessoaEmpresa: {
                                                    ...this.state.pessoaEmpresa, serieProducao: pTexto.target.value
                                                }
                                            })}
                                            value={this.state.pessoaEmpresa.serieProducao || ''}
                                            style={{
                                                width: '100%', height: 40
                                            }}
                                        />
                                    </div>

                                    <div>
                                        <label>Serie NFE Homologação</label><br />
                                        <InputText
                                            onChange={pTexto => this.setState({
                                                pessoaEmpresa: {
                                                    ...this.state.pessoaEmpresa, serieHomologacao: pTexto.target.value
                                                }
                                            })}
                                            value={this.state.pessoaEmpresa.serieHomologacao || ''}
                                            style={{
                                                width: '100%', height: 40
                                            }}
                                        />
                                    </div>

                                    <div>
                                        <label>Alíquota</label><br />
                                        <InputNumber
                                            onValueChange={(pTexto) => this.setState({
                                                pessoaEmpresa: {
                                                    ...this.state.pessoaEmpresa, aliquota: pTexto.target.value
                                                }
                                            })}
                                            mode="decimal"
                                            minFractionDigits={2} maxFractionDigits={2}
                                            value={this.state.pessoaEmpresa.aliquota || ''}
                                            style={{
                                                width: '100%', height: 40
                                            }}
                                        />
                                    </div>

                                    <div>
                                        <label>Item lista serviço</label><br />
                                        <InputText
                                            onChange={pTexto => this.setState({
                                                pessoaEmpresa: {
                                                    ...this.state.pessoaEmpresa, itemListaServico: pTexto.target.value
                                                }
                                            })}
                                            value={this.state.pessoaEmpresa.itemListaServico || ''}
                                            style={{
                                                width: '100%', height: 40
                                            }}
                                        />
                                    </div>

                                    <div>
                                        <label>Código tributário</label><br />
                                        <InputText
                                            onChange={pTexto => this.setState({
                                                pessoaEmpresa: {
                                                    ...this.state.pessoaEmpresa, codigoTributarioMunicipio: pTexto.target.value
                                                }
                                            })}
                                            value={this.state.pessoaEmpresa.codigoTributarioMunicipio || ''}
                                            style={{
                                                width: '100%', height: 40
                                            }}
                                        />
                                    </div>

                                </div>
                            </div>
                        </TabPanel> */}

                        {/* <TabPanel
                            header="Portal prefeitura"
                        >
                            <div style={{ border: '1px solid lightgray', borderRadius: 3, marginTop: 20, padding: 10 }}>
                                <h2 style={{ fontSize: 22.5 }}>Portal prefeitura</h2>
                                <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr 1fr", gap: 20, marginTop: 20 }}>

                                    <div>
                                        <label>Login emissor fiscal</label><br />
                                        <InputText
                                            onChange={pTexto => this.setState({
                                                pessoaEmpresa: {
                                                    ...this.state.pessoaEmpresa, loginEmissorFiscal: pTexto.target.value
                                                }
                                            })}
                                            value={this.state.pessoaEmpresa.loginEmissorFiscal || ''}
                                            style={{
                                                width: '100%', height: 40
                                            }}
                                        />
                                    </div>


                                    <div>
                                        <label>Senha responsável</label><br />
                                        <InputText
                                            onChange={pTexto => this.setState({
                                                pessoaEmpresa: {
                                                    ...this.state.pessoaEmpresa, senhaEmissorFiscal: pTexto.target.value
                                                }
                                            })}
                                            value={this.state.pessoaEmpresa.senhaEmissorFiscal || ''}
                                            style={{
                                                width: '100%', height: 40
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </TabPanel> */}

                        {/* <TabPanel
                            header="Certificado digital"
                        >
                            <div style={{
                                border: '1px solid lightgray',
                                borderRadius: 3,
                                margin: '20px 0px',
                                padding: 10
                            }}>
                                <div style={{ display: 'flex', alignItems: 'center', gap: 20 }}>
                                    <div>
                                        <label>Certificado</label><br />
                                        <InputText type="file" onChange={e => this.selecionarArquivo(e.target.files[0])} />
                                    </div>

                                    <div>
                                        <label>Senha certificado</label><br />
                                        <InputText
                                            onChange={pTexto => this.setState({
                                                pessoaEmpresa: {
                                                    ...this.state.pessoaEmpresa, senhaCertificado: pTexto.target.value
                                                }
                                            })}
                                            value={this.state.pessoaEmpresa.senhaCertificado || ''}
                                            style={{
                                                width: '100%', height: 40
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <DataTable
                                selectionMode="single"
                                responsiveLayout="scroll"
                                currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords}"
                                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                showGridlines
                                removableSort
                                paginator
                                rows={10}
                                rowsPerPageOptions={[10, 20, 50]}
                                value={''}
                                onSelectionChange={e => this.selecionarDocumentoAtendimento(e.value)}
                            >
                                <Column
                                    field="descricao"
                                    header="Descrição" />
                                <Column
                                    field="dataCadastro"
                                    header="Data cadastro"
                                    sortable
                                    body={item => <p>{this.util.formatarData(item.dataCadastro)}</p>
                                    } />
                            </DataTable>
                        </TabPanel> */}

                        {/* <TabPanel
                            header="Nota fiscal"
                        >
                            <DataTable
                                selectionMode="single"
                                responsiveLayout="scroll"
                                currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords}"
                                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                showGridlines
                                removableSort
                                paginator
                                rows={10}
                                rowsPerPageOptions={[10, 20, 50]}
                                value={this.state.listaNotaFiscal}
                                onSelectionChange={e => this.selecionarNotaFiscal(e.value)}
                            >
                                <Column
                                    field="_pessoaNome"
                                    header="Tomador" />
                                <Column
                                    field="valorTotal"
                                    header="Valor"
                                    body={(item) => `R$ ${this.util.formatarValor(item.valorTotal)}`} />
                                <Column
                                    field="dataProgramada"
                                    header="Data programada"
                                    sortable
                                    body={item => <p>{this.util.formatarData(item.dataCadastro)}</p>
                                    } />
                                <Column
                                    field="dataCadastro"
                                    header="Data cadastro"
                                    sortable
                                    body={item => <p>{this.util.formatarData(item.dataCadastro)}</p>
                                    } />
                            </DataTable>
                        </TabPanel> */}

                        <TabPanel
                            header="Cobrança"
                        >
                            <DataTable
                                selectionMode="single"
                                responsiveLayout="scroll"
                                currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords}"
                                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                showGridlines
                                removableSort
                                paginator
                                rows={10}
                                rowsPerPageOptions={[10, 20, 50]}
                                value={this.state.listaCobranca}
                                onSelectionChange={e => (e)}
                            >
                                <Column
                                    field="valorProgramado"
                                    header="Valor"
                                    sortable
                                    body={financeiro => "R$" + this.util.formatarValor(financeiro.valorProgramado)} />


                                <Column
                                    field="qtdeBaixa"
                                    header="Status"
                                    sortable
                                    body={financeiro => {
                                        if (financeiro.qtdeBaixa === 0) {
                                            return 'Não pago'
                                        }
                                        else {
                                            return 'Pago'
                                        }
                                    }} />

                                <Column
                                    field="dataProgramado"
                                    header="Data programada"
                                    sortable
                                    body={financeiro => this.util.formatarData(financeiro.dataProgramado)} />

                                <Column
                                    field="dataCadastro"
                                    header="Data cadastro"
                                    sortable
                                    body={financeiro => this.util.formatarData(financeiro.dataCadastro)} />
                            </DataTable>
                        </TabPanel>

                        <TabPanel
                            header="Solicitações"
                        >

                            <DataTable
                                selectionMode="single"
                                responsiveLayout="scroll"
                                currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords}"
                                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                showGridlines
                                removableSort
                                paginator
                                rows={10}
                                rowsPerPageOptions={[10, 20, 50]}
                                value={this.state.listaSolicitacao}
                                onSelectionChange={pAtendimento => this.setState({
                                    vModalSolicitacao: true,
                                    pessoAtendimentoSolicitacao: pAtendimento.value
                                })}
                            >
                                <Column
                                    field="descricao"
                                    header="Assunto"
                                    sortable
                                    body={atendimento => <p>{atendimento?.descricao?.split('/')[0]}</p>} />
                                <Column
                                    field="descricao"
                                    header="Descrição"
                                    sortable
                                    body={atendimento => <p
                                        style={{
                                            maxWidth: '15ch',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            whiteSpace: 'nowrap'
                                        }}
                                    >{atendimento?.descricao?.split('/')[1]}</p>} />

                                <Column
                                    field="dataCadastro"
                                    header="Data cadastro"
                                    sortable
                                    body={atendimento => this.util.formatarData(atendimento.dataCadastro)} />
                            </DataTable>

                        </TabPanel>

                    </TabView>
                </div>
            </main>
        </div>
    }
}