import Pesquisa from 'lirida-back-service/Servicos/Pesquisa/Pesquisa';
import Util_api from 'lirida-back-service/Servicos/Util/Util';
import Constante from './Constante';
import { locale, addLocale } from 'primereact/api';


export default class Util {
    constante = new Constante();
    util_api = new Util_api(this.constante.token, this.constante.url_base);



    ////// *** BUSCAS *** /////


    async buscarUrlBaseArquivo() {
        let retorno = await this.util_api.buscarUrlBaseArquivo();
        retorno = await retorno.json();
        return retorno;
    }

    buscarParametros() {
        this.listaParametrosSplitados = window.location.search.replace('?', '').split('&');
        this.parametros = {};

        for (let i = 0; i < this.listaParametrosSplitados.length; i++) {
            this.listaChaveValor = this.listaParametrosSplitados[i].split('=');
            this.parametros[this.listaChaveValor[0]] = this.listaChaveValor[1];
        }

        return this.parametros;
    }

    async buscarCep(pCep) {
        let retorno = await fetch('https://viacep.com.br/ws/' + pCep + '/json')
        retorno = await retorno.json();

        return retorno;
    }
    ///////////*****************/////////////

    async validarDocumento(pDocumento) {
        this.retorno = await this.util_api.validarDocumento(pDocumento);
        this.retorno = await this.retorno.json();
        return this.retorno;
    }


    ////// *** STORAGE *** /////

    salvarLocalStorage(pCampo, pPessoa) {
        window.localStorage.setItem(pCampo, JSON.stringify(pPessoa));
    }

    async buscarLocalStorage(pCampo, pPessoa) {
        this.retorno = await window.localStorage.getItem(pCampo, pPessoa);
        this.retorno = await JSON.parse(this.retorno);
        return this.retorno
    }

    removerLocalStorage(pCampo) {
        window.localStorage.removeItem(pCampo)
    }

    ///////////*****************/////////////




    ////// *** LISTA / LISTAR *** /////

    async listarBanco(pConteudo) {
        this.listaBanco = await this.util_api.listarBanco(pConteudo);
        this.listaBanco = await this.listaBanco.json();
        return this.listaBanco;
    }

    async listarLista(pSeqUsuario, pID) {
        this.retorno = await this.util_api.listarLista(pSeqUsuario, pID);
        this.retorno = this.retorno.json();
        return this.retorno;
    }

    ///////////*****************/////////////


    ////// *** OBJETOS *** /////
    criarObjetoPesquisa(pCampo, pConteudo, pOperacao, pTipo) {
        this.pesquisa = new Pesquisa()

        this.pesquisa.campo = pCampo;
        this.pesquisa.conteudo = pConteudo;
        this.pesquisa.operacao = pOperacao;
        this.pesquisa.tipo = pTipo;

        return this.pesquisa
    }
    ///////////*****************/////////////

    ////// *** ENVIAR EMAILS *** ///// 

    async enviarEmailSenha(pSeqEmail, pEmail, pNome, pSenha) {
        console.log(pSeqEmail, pEmail, pNome, pSenha);
        await this.util_api.enviarEmail({
            seqEmail: pSeqEmail,
            email: pEmail,
            parametro: {
                nome: pNome,
                email: pEmail,
                senha: pSenha
            }
        })
        return this.retorno
    }

    async enviarEmailSenhaMei(pSeqEmail, pEmail, pDocumento, pNome, pSenha) {
        console.log(pSeqEmail, pEmail, pDocumento, pSenha);
        await this.util_api.enviarEmail({
            seqEmail: pSeqEmail,
            email: pEmail,
            parametro: {
                documento: pDocumento,
                nome: pNome,
                email: pEmail,
                senha: pSenha
            }
        })
        return this.retorno
    }
    ///////////*****************/////////////



    ////// *** FORMATACAO *** /////

    traduzirCalendar() {
        addLocale('br', {
            firstDayOfWeek: 1,
            dayNames: ['domingo', 'segunda', 'terça', 'quarta', 'quinta', 'sexta', 'sábado'],
            dayNamesShort: ['dom', 'seg', 'ter', 'qua', 'qui', 'sex', 'sáb'],
            dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
            monthNames: ['janeiro', 'fevereiro', 'março', 'abril', 'maio', 'junho', 'julho', 'agosto', 'setembro', 'outubro', 'novembro', 'dezembro'],
            monthNamesShort: ['jan', 'fev', 'mar', 'abr', 'mai', 'jun', 'jul', 'ago', 'set', 'out', 'nov', 'dez'],
            today: 'Hoje',
            clear: 'Limpar'
        });
        locale('br');
    }

    formatarDataBanco(pData) {
        let data = pData.toLocaleDateString();
        data = `${data.split('/').join('-')}T00:00:00`;
        console.log("DATA PARA O BANCO")
        console.log(data)
        return data
    }

    formatarDataCalendar(pData) {
        let data = String(pData)
        data = `${data.split('T')[0].split('-').reverse().join('-')}T03:00:00.000Z`;
        return new Date(data)
    }

    formatarDataInput(pData) {
        if (String(pData).includes('/')) {
            this.data = String(pData).split('/').reverse().join('-');
        } else if (String(pData).includes('-')) {
            this.data = String(pData).split('-').reverse().join('/');
        } else {
            this.data = pData;
        }

        return this.data;
    }

    formatarData(pData) {
        if (!pData) {
            return null
        }
        this.data = pData;
        this.data = String(this.data).split('T')[0];
        this.data = this.data.replace('-', '/').replace('-', '/')
        return this.data;
    }

    formatarDataComTimestamp(pData) {
        if (!pData) return;

        let data = pData;
        data = data.split("T")[0].split("-").join("/");
        return data;
    }
    formatarCEP(pTexto) {
        this.texto = pTexto;
        return this.texto.replace(/\D/g, '')
            .replace(/(\d{5})(\d)/, '$1-$2')
            .replace(/(-\d{3})\d+?$/, '$1')
    }

    formatarCPF(pTexto) {
        let t = pTexto;
        if (t.length <= 15) {
            return t.replace(/\D/g, '')
                .replace(/(\d{3})(\d)/, '$1.$2')
                .replace(/(\d{3})(\d)/, '$1.$2')
                .replace(/(\d{3})(\d{1,2})/, '$1-$2')
                .replace(/(-\d{2})\d+?$/, '$1')
        }
    }

    formatarCNPJ(pTexto) {
        let t = pTexto;

        return t.replace(/\D/g, '')
            .replace(/(\d{2})(\d)/, '$1.$2')
            .replace(/(\d{3})(\d)/, '$1.$2')
            .replace(/(\d{3})(\d{1,2})/, '$1/$2')
            .replace(/(\d{4})(\d{1,2})/, '$1-$2')
            .replace(/(-\d{2})\d+?$/, '$1')

    }

    formatarCPFCNPJ(pTexto) {
        let t = pTexto;

        if (t.length > 15) {
            return t.replace(/\D/g, '')
                .replace(/(\d{2})(\d)/, '$1.$2')
                .replace(/(\d{3})(\d)/, '$1.$2')
                .replace(/(\d{3})(\d{1,2})/, '$1/$2')
                .replace(/(\d{4})(\d{1,2})/, '$1-$2')
                .replace(/(-\d{2})\d+?$/, '$1')
        }

        if (t.length <= 15) {
            return t.replace(/\D/g, '')
                .replace(/(\d{3})(\d)/, '$1.$2')
                .replace(/(\d{3})(\d)/, '$1.$2')
                .replace(/(\d{3})(\d{1,2})/, '$1-$2')
        }
    }


    formatarTelefone(value) {
        let r = value.replace(/\D/g, '');
        r = r.replace(/^0/, '');

        if (r.length > 11) {
            r = r.replace(/^(\d\d)(\d{5})(\d{4}).*/, '($1) $2-$3');
        } else if (r.length > 7) {
            r = r.replace(/^(\d\d)(\d{5})(\d{0,4}).*/, '($1) $2-$3');
        } else if (r.length > 2) {
            r = r.replace(/^(\d\d)(\d{0,5})/, '($1) $2');
        } else if (value.trim() !== '') {
            r = r.replace(/^(\d*)/, '($1');
        }

        return r;
    }



    formatarValor(pValor) {
        if (!pValor) {
            return `0,00`
        }

        this.valor = Number(pValor).toFixed(2);
        this.valor = String(this.valor).replace('.', '');
        this.valor = this.valor.replace(/\D/g, ''); //permite digitar apenas números
        this.valor = this.valor.replace(/[0-9]{12}/, 'Valor inválido!'); //limita pra máximo 999.999.999,99
        this.valor = this.valor.replace(/(\d{1})(\d{8})$/, '$1.$2'); //coloca ponto antes dos últimos 8 digitos
        this.valor = this.valor.replace(/(\d{1})(\d{5})$/, '$1.$2'); //coloca ponto antes dos últimos 5 digitos
        this.valor = this.valor.replace(/(\d{1})(\d{1,2})$/, '$1,$2'); //coloca virgula antes dos últimos 2 digitos

        return ` ${this.valor}`;
    }

    ///////////*****************/////////////


    async pegarBase64(pArquivo) {
        console.log("BASE ANTES ");
        console.log(pArquivo);
        return new Promise((resolve, reject) => {
            const reader = new FileReader();

            reader.readAsDataURL(pArquivo);
            reader.onload = () => {
                let base64 = reader.result;
                base64 = base64.split(",")[1];
                resolve(base64);
            };
            reader.onerror = error => reject(error);
        })
    }

    formatarNumero(input) {
        var regex = /^\d+(\.\d+)?$/;
        if (input.match(regex)) {
            return parseFloat(input);
        }
        return '';
    }

    validarEmail(pEmail) {
        let emailRegex = /\S+@\S+\.\S+/;

        return emailRegex.test(pEmail);
    }

}