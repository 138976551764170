import { Component } from "react";
import { Sidebar } from 'primereact/sidebar';

import fotoPessoa from '../../assets/images/foto-pessoa.png';
import banner from '../../assets/images/banner.png';
import foto1 from '../../assets/images/foto1.png';
import foto2 from '../../assets/images/foto2.png';
import logoBranca from '../../assets/images/logoBranca.png'

import { Accordion, AccordionTab } from 'primereact/accordion';
import { Navigate } from "react-router";

export default class Hotsite extends Component {

    TelaEnum = {
        Principal: "Principal",
        PoliticaPrivacidade: "PoliticaPrivacidade",
        TermoUso: "TermoUso",
        Login: "Login"
    }

    state = {
        sidebarVisivel: false,
        tela: this.TelaEnum.Principal,
    }


    render() {
        return <div>
            {this.state.tela === this.TelaEnum.Login && <Navigate to="/login" />}
            {this.state.tela === this.TelaEnum.TermoUso && <Navigate to="/termoDeUso" />}
            {this.state.tela === this.TelaEnum.PoliticaPrivacidade && <Navigate to="/politicaDePrivacidade" />}
            <Sidebar visible={this.state.sidebarVisivel} onHide={() => this.setState({ sidebarVisivel: false })}>
                <div style={{ display: 'flex', flexDirection: 'column', gap: 30, }}>
                    <a
                        onClick={() => this.setState({ sidebarVisivel: false, tela: this.TelaEnum.Principal })}
                        style={{ color: 'black', textDecoration: 'none' }}
                        href="#sobre">Sobre</a>
                    <a
                        onClick={() => this.setState({ sidebarVisivel: false, tela: this.TelaEnum.Principal })}
                        style={{ color: 'black', textDecoration: 'none' }}
                        href="#funcionalidades">Funcionalidades</a>
                    <a
                        onClick={() => this.setState({ sidebarVisivel: false, tela: this.TelaEnum.Principal })}
                        style={{ color: 'black', textDecoration: 'none' }}
                        href="#depoimentos">Depoimentos</a>
                    <a
                        onClick={() => this.setState({ sidebarVisivel: false, tela: this.TelaEnum.Principal })}
                        style={{ color: 'black', textDecoration: 'none' }}
                        href="#perguntas-frequentes">Perguntas frequentes</a>
                    <a

                        onClick={() => this.setState({ sidebarVisivel: false, tela: this.TelaEnum.Login })}
                        style={{ color: 'black', textDecoration: 'none' }}
                    >Login</a>
                </div>
            </Sidebar>

            <header className="h-header">
                <div className="h-header-container">

                    <img src={logoBranca} alt="logo" style={{ cursor: 'pointer' }} onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })} />

                    <nav>
                        <a href="#sobre" onClick={() => this.setState({ tela: this.TelaEnum.Principal })}>Sobre</a>
                        <a href="#funcionalidades" onClick={() => this.setState({ tela: this.TelaEnum.Principal })}>Funcionalidades</a>
                        <a href="#depoimentos" onClick={() => this.setState({ tela: this.TelaEnum.Principal })}>Depoimentos</a>
                        <a href="#perguntas-frequentes" onClick={() => this.setState({ tela: this.TelaEnum.Principal })}>Perguntas frequentes</a>

                        <a onClick={() => {
                            window.scrollTo({ top: 0, behavior: 'smooth' })
                            this.setState({ tela: this.TelaEnum.Login })
                        }}>Login</a>
                        <button onClick={() => this.setState({ sidebarVisivel: true })}>
                            <i className="pi pi-bars"></i>
                        </button>
                    </nav>
                </div>
            </header>

            <main className="h-main-container">
                <img className="h-slide-img" src={banner} alt="imagem principal" />

                <section className="h-sobre-main-container" id="sobre">

                    <div className="h-sobre-container">
                        <div>
                            <i className="pi pi-building" style={{ fontSize: 30 }}></i>
                            <h2>Quem somos</h2>
                            <p>
                                O Contador Flex é a solução que veio para dar mais oportunidades ao profissional de contabilidade: O Contador. Através desta plataforma, você poderá ter total controle da sua carteira de clientes ou empresas que você atende.
                            </p>
                        </div>
                        <div>
                            <i className="pi pi-cog" style={{ fontSize: 30 }}></i>
                            <h2>Como funciona</h2>
                            <p>
                                Com recursos de administração de Mei’s, Cobrança de clientes automática, Gestão de contratos, organização de clientes, cadastro de novos clientes, pagamentos e muito mais. A Plataforma mais completa de gestão contábil do mercado.
                            </p>
                        </div>
                        <div>
                            <i className="pi pi-users" style={{ fontSize: 30 }}></i>
                            <h2>Para quem</h2>
                            <p>
                                Feito pra você, contador que quer ser mais produtivo, parar de ter dor de cabeça ao cobrar seus clientes, ter mais organização ao administrar seu clientes e fazer a gestão e atualização de contratos. Traga seu clientes e administre pelo Contador Flex.
                            </p>
                        </div>
                    </div>

                </section>

                <section className="h-func-main-container" id="funcionalidades">
                    <div className="h-func-container">
                        <div>
                            <p>
                                Mostre autoridade e tenha mais oportunidades para trabalhos contábeis.
                            </p>
                            <img src={foto1} alt="imagem flutuante" />
                        </div>

                        <div>
                            <p>
                                Tenha mais organização dos seus contatos e clientes e seja mais produtivo!
                            </p>
                        </div>

                        <div>
                            <img src={foto2} alt="imagem flutuante" />
                            <p>
                                Conheça a facilidade da cobrança automática com apenas alguns cliques
                            </p>
                        </div>
                    </div>
                </section>

                <section className="h-depo-main-container" id="depoimentos">
                    <div className="h-depo-container">
                        <div>
                            <img src={fotoPessoa} alt="foto pessoa" />
                            <div>
                                <h2>
                                    Fernando (Contador):
                                </h2>
                                <p>
                                    O contador flex facilitou muito minha vida. Agora sou mais produtivo e tenho mais tempo para fazer outras coisas.
                                </p>
                            </div>
                        </div>

                        <div>
                            <img src={fotoPessoa} alt="foto pessoa" />
                            <div>
                                <h2>
                                    Viviane (Cliente):
                                </h2>
                                <p>Contador flex tirou da minhas rotina aquelas tarefas chatas que preciso fazer constantemente, agora não preciso mais esquentar a cabeça com isso.
                                </p>
                            </div>
                        </div>

                        <div>
                            <img src={fotoPessoa} alt="foto pessoa" />
                            <div>
                                <h2>
                                    Joel (Empreendedor):
                                </h2>
                                <p>
                                    Depois que comecei a usar o contador flex não consigo mais parar de usar. Nunca foi tão fácil administrar meus contratos.
                                </p>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="h-faq-container" id="perguntas-frequentes">
                    {/* <div className="h-faq-container"> */}
                    <h2 className="h-titulo-underline" style={{ textAlign: 'center' }}>Perguntas frequentes</h2>

                    <Accordion multiple >
                        <AccordionTab header="Preciso pagar alguma mensalidade para usar a plataforma?">
                            <p>Não, para utilizar nossa plataforma e ter acesso aos recursos é totalmente livre de mensalidade.
                            </p>
                        </AccordionTab>
                        <AccordionTab header="Depois que criei a conta, posso editar minhas informações?">
                            <p>Sim, suas informações ficam disponíveis para edição quando você quiser.</p>
                        </AccordionTab>


                        <AccordionTab header="Como faço para me cadastrar na plataforma?">
                            <p>Entre em contato conosco no email atendimento@contadorflex.com.br</p>
                        </AccordionTab>


                        <AccordionTab header="Eu posso cancelar ou excluir minha conta?">
                            <p>Sim, você tem total autonomia e controle sobre sua conta A qualquer momento você exclui sua sem custo nenhum.</p>
                        </AccordionTab>

                        <AccordionTab header="Preciso passar por alguma análise?">
                            <p>Sim, buscamos levar o melhor atendimento para os clientes, assim para manter a segurança e qualidade dos serviços é preciso que o profissional passe por uma análise.</p>
                        </AccordionTab>
                    </Accordion>
                    {/* </div> */}
                </section>
            </main>

            <footer className="h-footer-container">
                <section >
                    <div>
                        <a
                            href="#sobre"
                            onClick={() => this.setState({ tela: this.TelaEnum.Principal })}>
                            Quem somos
                        </a>

                        <a
                            href="#funcionalidades"
                            onClick={() => this.setState({ tela: this.TelaEnum.Principal })}>
                            Funcionalidade
                        </a>

                        <a
                            href="#depoimentos"
                            onClick={() => this.setState({ tela: this.TelaEnum.Principal })}>
                            Depoimentos
                        </a>

                        <a
                            href="#perguntas-frequentes"
                            onClick={() => this.setState({ tela: this.TelaEnum.Principal })}>
                            Perguntas frequentes
                        </a>

                        <a

                            onClick={() => {
                                window.scrollTo({ top: 0, behavior: 'smooth' })
                                this.setState({ tela: this.TelaEnum.Login })
                            }}>
                            Login
                        </a>
                    </div>

                    <div>
                        <a
                            onClick={() => {
                                window.scrollTo({ top: 0, behavior: 'smooth' });
                                this.setState({ tela: this.TelaEnum.PoliticaPrivacidade });
                            }}>
                            Política de privacidade
                        </a>
                        <a
                            onClick={() => {
                                { window.scrollTo({ top: 0, behavior: 'smooth' }) };
                                this.setState({ tela: this.TelaEnum.TermoUso });
                            }}>
                            Termo de uso
                        </a>

                    </div>

                    <div>
                        <a
                            href="https://www.facebook.com/profile.php?id=100085863546876" target='_blank'>
                            Facebook
                        </a>

                        <a
                            href="https://www.instagram.com/contadorflex/" target='_blank'>
                            Instagram
                        </a>
                    </div>

                </section>
                <img src={logoBranca} alt="logo rodapé" />
            </footer>
        </div>
    }
}