import React, { Component } from "react";
import logoBranca from '../../assets/images/logoBranca.png';
import { Navigate } from "react-router";
import { Sidebar } from 'primereact/sidebar';

export default class politicaPrivacidade extends Component {
    TelaEnum = {
        principal: 'principal',
        login: "login",
        sobre: 'sobre',
        funcionalidades: 'funcionalidades',
        depoimentos: 'depoimentos',
        perguntasFrequentes: 'perguntasFrequentes',
        politicaPrivacidade: "politicaPrivacidade",
        termoDeUso: "termoDeUso",
    }

    state = {
        sidebarVisivel: false,
        tela: this.TelaEnum.politicaPrivacidade,
    }

    render() {
        return <>
            {this.state.tela === this.TelaEnum.principal && <Navigate to="/" />}
            {this.state.tela === this.TelaEnum.login && <Navigate to="/login" />}
            {this.state.tela === this.TelaEnum.sobre && <Navigate to="/#sobre" />}
            {this.state.tela === this.TelaEnum.funcionalidades && <Navigate to="/#funcionalidades" />}
            {this.state.tela === this.TelaEnum.depoimentos && <Navigate to="/#depoimentos" />}
            {this.state.tela === this.TelaEnum.perguntasFrequentes && <Navigate to="/#perguntas-frequentes" />}
            {this.state.tela === this.TelaEnum.termoDeUso && <Navigate to="/termoDeUso" />}


            <Sidebar visible={this.state.sidebarVisivel} onHide={() => this.setState({ sidebarVisivel: false })}>
                <div style={{ display: 'flex', flexDirection: 'column', gap: 30, }}>
                    <a
                        onClick={() => this.setState({ sidebarVisivel: false, tela: this.TelaEnum.sobre })}
                        style={{ color: 'black', textDecoration: 'none' }}
                        href="#sobre">Sobre</a>
                    <a
                        onClick={() => this.setState({ sidebarVisivel: false, tela: this.TelaEnum.funcionalidades })}
                        style={{ color: 'black', textDecoration: 'none' }}
                        href="#funcionalidades">Funcionalidades</a>
                    <a
                        onClick={() => this.setState({ sidebarVisivel: false, tela: this.TelaEnum.depoimentos })}
                        style={{ color: 'black', textDecoration: 'none' }}
                        href="#depoimentos">Depoimentos</a>
                    <a
                        onClick={() => this.setState({ sidebarVisivel: false, tela: this.TelaEnum.perguntasFrequentes })}
                        style={{ color: 'black', textDecoration: 'none' }}
                        href="#perguntas-frequentes">Perguntas frequentes</a>
                    <a
                        onClick={() => this.setState({ sidebarVisivel: false, tela: this.TelaEnum.login })}
                        style={{ color: 'black', textDecoration: 'none' }}
                    >Login</a>
                </div>
            </Sidebar>

            <header className="h-header">
                <div className="h-header-container">

                    <img src={logoBranca} alt="logo" style={{ cursor: 'pointer' }}
                        onClick={() => this.setState({ tela: this.TelaEnum.principal })} />

                    <nav>
                        <a href="#sobre" onClick={() => this.setState({ tela: this.TelaEnum.sobre })}>sobre</a>
                        <a href="#funcionalidades" onClick={() => this.setState({ tela: this.TelaEnum.funcionalidades })}>funcionalidades</a>
                        <a href="#depoimentos" onClick={() => this.setState({ tela: this.TelaEnum.depoimentos })}>Depoimentos</a>
                        <a href="#perguntas-frequentes" onClick={() => this.setState({ tela: this.TelaEnum.perguntasFrequentes })}>Perguntas frequentes</a>

                        <a href='' onClick={() => this.setState({ tela: this.TelaEnum.login })}>login</a>
                        <button onClick={() => this.setState({ sidebarVisivel: true })}>
                            <i className="pi pi-bars"></i>
                        </button>
                    </nav>
                </div>
            </header>
            <main className="h-main-container" style={{ padding: '90px 30px 30px 30px' }}>
                <section className="h-politica-privacidade-container">
                    <h1 className="h-titulo-underline">Política de privacidade</h1>
                    <p className="h-texto-normal">
                        Todas as suas informações pessoais recolhidas, serão usadas para o ajudar a tornar a sua visita no nosso site o mais produtiva e agradável possível. A garantia da confidencialidade dos dados pessoais dos utilizadores do nosso site é importante para a ContadorFlex. Todas as informações pessoais relativas a membros, assinantes, clientes ou visitantes que usem a ContadorFlex serão tratadas em concordância com a Lei da Proteção de Dados Pessoais em vigor. A informação pessoal recolhida pode incluir o seu nome, e-mail, número de telefone e/ou telemóvel e/ou outros. O uso da ContadorFlex pressupõe a aceitação deste acordo de privacidade. A equipa da ContadorFlex reserva-se ao direito de alterar este acordo sem aviso prévio. Deste modo, recomendamos que consulte a nossa política de privacidade com regularidade de forma a estar sempre atualizado.
                    </p>

                    <h2 className="h-titulo-paragrafo">Ligações a sites de terceiros</h2>
                    <p className="h-texto-normal">
                        A ContadorFlex possui ligações para outros sites, os quais, a nosso ver, podem conter informações / ferramentas úteis para os nossos visitantes. A nossa política de privacidade não é aplicada a sites de terceiros, pelo que, caso visite outro site a partir do nosso deverá ler a politica de privacidade do mesmo. Não nos responsabilizamos pela política de privacidade ou conteúdo presente nesses mesmos sites.
                    </p>
                </section>
            </main>

            <footer className="h-footer-container">
                <section >
                    <div>
                        <a
                            href="#sobre"
                            onClick={() => this.setState({ tela: this.TelaEnum.sobre })}>
                            Quem somos
                        </a>

                        <a
                            href="#funcionalidades"
                            onClick={() => this.setState({ tela: this.TelaEnum.funcionalidades })}>
                            Funcionalidade
                        </a>

                        <a
                            href="#depoimentos"
                            onClick={() => this.setState({ tela: this.TelaEnum.depoimentos })}>
                            Depoimentos
                        </a>

                        <a
                            href="#perguntas-frequentes"
                            onClick={() => this.setState({ tela: this.TelaEnum.perguntasFrequentes })}>
                            Perguntas frequentes
                        </a>

                        <a
                            onClick={() => this.setState({ tela: this.TelaEnum.login })}>
                            login
                        </a>
                    </div>

                    <div>
                        <a
                            onClick={() => {
                                window.scrollTo({ top: 0, behavior: 'smooth' });
                            }}>
                            Política de privacidade
                        </a>

                        <a
                            href=''
                            onClick={() => {
                                this.setState({ tela: this.TelaEnum.termoDeUso });
                            }}>
                            Termo de uso
                        </a>
                    </div>

                    <div>
                        <a
                            href="https://www.facebook.com/profile.php?id=100085863546876" target='_blank'>
                            Facebook
                        </a>

                        <a
                            href="https://www.instagram.com/contadorflex/" target='_blank'>
                            Instagram
                        </a>
                    </div>
                </section>
                <img src={logoBranca} alt="logo rodapé" />
            </footer>
        </>
    }
}