import { Button } from "primereact/button";
import { Component, createRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Calendar } from "primereact/calendar";
import { InputText } from "primereact/inputtext";
import { Dialog } from "primereact/dialog";
import { Steps } from 'primereact/steps';

import Header from "../components/Header";

import Util from '../Util/Util';
import Constante from "../Util/Constante";
import Pessoa from 'lirida-back-service/Servicos/Pessoa/Pessoa';
import PessoaRelacionamento from "lirida-back-service/Servicos/PessoaRelacionamento/PessoaRelacionamento";
import PessoaService from 'lirida-back-service/Servicos/Pessoa/PessoaService';
import { Messages } from "primereact/messages";
import Pesquisa from "lirida-back-service/Servicos/Pesquisa/Pesquisa";



export default class PessoaPg extends Component {
    msgs1 = createRef();
    msgs2 = createRef();
    constante = new Constante();
    util = new Util();
    pessoaService = new PessoaService(this.constante.token, this.constante.url_base);

    state = {
        pessoaUsuario: new Pessoa(),
        pessoaEmpresa: new Pessoa(),

        listaPessoa: [],
        buscarCarregando: false,

        vModalNovo: false,
        indexStep: 0,

        salvarCarregando: false,
        salvarCheck: false,

        //FILTRO
        filtro: {
            dataCadastro_inicial: '',
            dataCadastro_final: '',
            nome: '',
            nomeFantasia: '',
            nomeResponsavel: '',
            documento: '',
        },

        validarInputs0: {
            nome: false,
            apelido: false,
            // inscricaoEstadual: false,
            // inscricaoMunicipal: false,
            cnpj: false,
        },

        validarInputs1: {
            responsavelNome: false,
            cpfResponsavel: false,
            email: false,
            telefone: false,
        },

        liberarAcesso: {
            pessoaDetalhe: false,
        }
    }

    componentDidMount() {
        this.iniciar();
    }

    async iniciar() {
        this.util.traduzirCalendar();
        let pessoaUsuario = await this.util.buscarLocalStorage('PESSOA_USUARIO');
        let listaPessoa = await this.listarPessoa(pessoaUsuario);
        if (!pessoaUsuario?._seqPessoa) {
            window.history.back();
        }
        this.setState({ pessoaUsuario: pessoaUsuario, listaPessoa: listaPessoa });
    }


    async listarPessoa(pPessoUsuario) {
        let pessoaUsuario = this.state.pessoaUsuario;
        let listaPesquisa = [];

        if (pPessoUsuario) {
            pessoaUsuario = pPessoUsuario
        }

        let pesquisa = new Pesquisa();
        pesquisa.campo = "pessoa.seq_usuario";
        pesquisa.conteudo = this.constante.seqUsuario;
        pesquisa.operacao = "";
        pesquisa.tipo = "TEXTO";
        listaPesquisa.push(pesquisa);

        pesquisa = new Pesquisa();
        pesquisa.campo = "pessoa_relacionamento.seq_pessoa_relacionada";
        pesquisa.conteudo = pessoaUsuario?._seqPessoa;
        pesquisa.operacao = "AND";
        pesquisa.tipo = "TEXTO";
        listaPesquisa.push(pesquisa);

        pesquisa = new Pesquisa();
        pesquisa.campo = " AND pessoa.tags5 in ('N', 'S')";
        pesquisa.conteudo = 'S';
        pesquisa.operacao = 'AND';
        pesquisa.tipo = 'MANUAL';
        listaPesquisa.push(pesquisa);

        if (this.state.filtro.dataCadastro_inicial) {
            pesquisa = new Pesquisa();
            pesquisa.campo = "pessoa_relacionamento.data_cadastro";
            pesquisa.conteudo = this.util.formatarDataBanco(this.state.filtro.dataCadastro_inicial);
            pesquisa.operacao = "AND";
            pesquisa.tipo = "DATA_INICIAL";
            listaPesquisa.push(pesquisa);
        }


        if (this.state.filtro.dataCadastro_final) {
            pesquisa = new Pesquisa();
            pesquisa.campo = "pessoa_relacionamento.data_cadastro";
            pesquisa.conteudo = this.util.formatarDataBanco(this.state.filtro.dataCadastro_final);
            pesquisa.operacao = "AND";
            pesquisa.tipo = "DATA_FINAL";
            listaPesquisa.push(pesquisa);
        }

        if (this.state.filtro.nome) {
            pesquisa = new Pesquisa();
            pesquisa.campo = "pessoa.nome";
            pesquisa.conteudo = this.state.filtro.nome;
            pesquisa.operacao = "AND";
            pesquisa.tipo = "TEXTO";
            listaPesquisa.push(pesquisa);
        }


        if (this.state.filtro.nomeFantasia) {
            pesquisa = new Pesquisa();
            pesquisa.campo = "pessoa.apelido";
            pesquisa.conteudo = this.state.filtro.nomeFantasia;
            pesquisa.operacao = "AND";
            pesquisa.tipo = "TEXTO";
            listaPesquisa.push(pesquisa);
        }


        if (this.state.filtro.nomeResponsavel) {
            pesquisa = new Pesquisa();
            pesquisa.campo = "pessoa.responsavel_nome";
            pesquisa.conteudo = this.state.filtro.nomeResponsavel;
            pesquisa.operacao = "AND";
            pesquisa.tipo = "TEXTO";
            listaPesquisa.push(pesquisa);
        }


        if (this.state.filtro.documento) {
            pesquisa = new Pesquisa();
            pesquisa.campo = "pessoa.documento";
            pesquisa.conteudo = this.state.filtro.documento;
            pesquisa.operacao = "AND";
            pesquisa.tipo = "TEXTO";
            listaPesquisa.push(pesquisa);
        }

        let retorno = await this.pessoaService.listarComFiltro(listaPesquisa)
        let listaPessoa = retorno?.objeto;
        console.log(listaPessoa);
        this.setState({
            listaPessoa: listaPessoa
        })

        return listaPessoa
    }


    async salvarPessoa() {
        const inputsOk = await this.validarInputs1();
        if (!inputsOk) {
            return
        }

        let pessoaEmpresa = this.state.pessoaEmpresa;
        const pessoaUsuario = this.state.pessoaUsuario;

        pessoaEmpresa.seqUsuario = this.constante.seqUsuario;
        pessoaEmpresa.seqPessoaPerfil = this.constante.seqPessoaPerfilClienteMei;
        pessoaEmpresa.tipoDocumento = 'CNPJ';
        pessoaEmpresa.situacao = 'ATIVO';
        let retornoEmpresa = await this.pessoaService.salvar(pessoaEmpresa);
        pessoaEmpresa = retornoEmpresa.objeto;
        console.log("PESSOA SALVA", pessoaEmpresa)

        let pessoaRelacionamento = new PessoaRelacionamento();
        pessoaRelacionamento.seqUsuario = this.constante.seqUsuario;
        pessoaRelacionamento.seqPessoaRelacionada = pessoaUsuario._seqPessoa;
        pessoaRelacionamento.seqPessoa = pessoaEmpresa._seqPessoa;
        pessoaRelacionamento.seqTipoRelacionamento = this.constante.seqRelacionamentoContadorResponsavel;
        pessoaRelacionamento.situacao = 'ATIVO';
        let retornoRelacionamento = await this.pessoaService.salvarRelacionamento(pessoaRelacionamento);
        let relacionamento = retornoRelacionamento.objeto;
        console.log("Relacionamento salvo", relacionamento)
        this.setState({ salvarCheck: false, vModalNovo: false, indexStep: 0, pessoaEmpresa: pessoaEmpresa });
        this.msgs1.current.show([{ severity: 'success', summary: 'Empresa salva com sucesso.', life: 5000 }]);

        window.location.href = `/cliente_detalhe?id=${pessoaEmpresa._seqPessoa}`;
    }

    async avancarSteps0() {
        const inputsOk = await this.validarInputs0();
        if (!inputsOk) {
            return
        }
        this.setState({ indexStep: 1 })
    }

    validarInputs0() {
        const inputs0 = this.state.validarInputs0;
        const listaKeys = Object.keys(inputs0);

        for (let i = 0; i < listaKeys.length; i++) {
            inputs0[listaKeys[i]] = false;
        }

        if (!this.state.pessoaEmpresa.nome) {
            inputs0.nome = true;
        }

        if (!this.state.pessoaEmpresa.apelido) {
            inputs0.apelido = true;
        }

        if (!this.state.pessoaEmpresa.documento) {
            inputs0.cnpj = true
        }

        if (this.state.pessoaEmpresa.documento && this.state.pessoaEmpresa.documento.length < 18) {
            inputs0.cnpj = true
        }


        let inputsOk = true;

        for (let i = 0; i < listaKeys.length; i++) {
            if (inputs0[listaKeys[i]] === true) {
                inputsOk = false
            }
        }
        this.setState({ validarInputs0: inputs0 })
        return inputsOk
    }


    validarInputs1() {
        const inputs1 = this.state.validarInputs1;
        const listaKeys = Object.keys(inputs1);

        for (let i = 0; i < listaKeys.length; i++) {
            inputs1[listaKeys[i]] = false;
        }

        if (!this.state.pessoaEmpresa.responsavelNome) {
            inputs1.responsavelNome = true;
        }
        if (!this.state.pessoaEmpresa.responsavelCpf) {
            inputs1.cpfResponsavel = true;
        }

        if (this.state.pessoaEmpresa.responsavelCpf && this.state.pessoaEmpresa.length < 14) {
            inputs1.cpfResponsavel = true;
        }

        if (!this.state.pessoaEmpresa.emailPlataformaLirida) {
            inputs1.email = true;
        }

        if (!this.state.pessoaEmpresa.telefonePlataformaLirida) {
            inputs1.telefone = true;
        }

        let inputsOk = true;

        for (let i = 0; i < listaKeys.length; i++) {
            if (inputs1[listaKeys[i]] === true) {
                inputsOk = false
            }
        }
        this.setState({ validarInputs1: inputs1 })
        return inputsOk
    }

    novaPessoa() {
        const inputs0 = this.state.validarInputs0;
        const inputs1 = this.state.validarInputs1;
        const listaKeys = Object.keys(inputs0);
        const listaKeys1 = Object.keys(inputs1);

        for (let i = 0; i < listaKeys.length; i++) {
            inputs0[listaKeys[i]] = false;
        }
        for (let i = 0; i < listaKeys1.length; i++) {
            inputs1[listaKeys[i]] = false;
        }

        this.setState({
            pessoaEmpresa: new Pessoa(),
            vModalNovo: true,
            indexStep: 0,
            validarInputs0: inputs0,
            validarInputs1: inputs1,
        })
    }

    selecionarPessoa(pPessoa) {
        this.setState({ pessoaEmpresa: pPessoa })
        window.location.href = `/cliente_detalhe?id=${pPessoa._seqPessoa}`
    }

    render() {
        return this.state.pessoaUsuario._seqPessoa && <div>
            <Dialog
                header="Nova pessoa"
                visible={this.state.vModalNovo}
                style={{ width: 700 }}
                onHide={() => this.setState({ vModalNovo: false, documento: new Pessoa() })}
            >
                <Steps
                    model={[{ label: "Dados da empresa" }, { label: "Dados responsável" }]}
                    activeIndex={this.state.indexStep} />
                <form
                    onSubmit={e => { e.preventDefault(); this.salvarPessoa() }}
                    style={{ padding: 20, display: "flex", flexDirection: "column", gap: 10 }}>
                    {this.state.indexStep === 0 &&
                        <div style={{ display: 'flex', flexDirection: 'column', gap: 10, padding: '20px 0' }}>
                            <div>
                                <label>Razão social</label><br />
                                <InputText
                                    onChange={(pTexto) => this.setState({
                                        pessoaEmpresa: {
                                            ...this.state.pessoaEmpresa, nome: pTexto.target.value
                                        }
                                    })}
                                    value={this.state.pessoaEmpresa.nome}
                                    style={{ width: '100%', borderColor: this.state.validarInputs0.nome && 'var(--red-600)' }} />
                                {this.state.validarInputs0.nome && <small style={{ color: 'var(--red-600)' }}>Razão social inválido</small>}
                            </div>

                            <div>
                                <label>Nome fantasia</label><br />
                                <InputText
                                    onChange={(pTexto) => this.setState({
                                        pessoaEmpresa: {
                                            ...this.state.pessoaEmpresa, apelido: pTexto.target.value
                                        }
                                    })}
                                    value={this.state.pessoaEmpresa.apelido}
                                    style={{ width: '100%', borderColor: this.state.validarInputs0.apelido && 'var(--red-600)' }} />
                                {this.state.validarInputs0.apelido && <small style={{ color: 'var(--red-600)' }}>Nome inválido</small>}
                            </div>

                            <div>
                                <label>Inscrição estadual</label><br />
                                <InputText
                                    onChange={(pTexto) => this.setState({
                                        pessoaEmpresa: {
                                            ...this.state.pessoaEmpresa, inscricaoEstadual: pTexto.target.value
                                        }
                                    })}
                                    value={this.state.pessoaEmpresa.inscricaoEstadual || ''}
                                    style={{ width: '100%' }} />
                            </div>


                            <div>
                                <label>Inscrição municipal</label><br />
                                <InputText
                                    onChange={(pTexto) => this.setState({
                                        pessoaEmpresa: {
                                            ...this.state.pessoaEmpresa, inscricaoMunicipal: pTexto.target.value
                                        }
                                    })}
                                    value={this.state.pessoaEmpresa.inscricaoMunicipal || ''}
                                    style={{ width: '100%' }} />
                            </div>


                            <div>
                                <label>CNPJ</label><br />
                                <InputText
                                    onChange={(pTexto) => this.setState({
                                        pessoaEmpresa: {
                                            ...this.state.pessoaEmpresa, documento: this.util.formatarCNPJ(pTexto.target.value)
                                        }
                                    })}
                                    value={this.state.pessoaEmpresa.documento || ''}
                                    style={{ width: '100%', borderColor: this.state.validarInputs0.cnpj && 'var(--red-600)' }} />
                                {this.state.validarInputs0.cnpj && <small style={{ color: 'var(--red-600)' }}>CNPJ inválido</small>}
                            </div>

                            <div style={{ display: 'flex', justifyContent: 'right', width: '100%' }}>
                                <Button
                                    label="Próximo"
                                    type='button'
                                    className="p-button-raised" onClick={() => this.avancarSteps0()} />
                            </div>
                        </div>
                    }

                    {this.state.indexStep === 1 &&
                        <div style={{ display: 'flex', flexDirection: 'column', gap: 10, padding: '20px 0' }}>

                            <div>
                                <label>Nome responsável</label><br />
                                <InputText
                                    onChange={(pTexto) => this.setState({
                                        pessoaEmpresa: {
                                            ...this.state.pessoaEmpresa, responsavelNome: pTexto.target.value
                                        }
                                    })}
                                    value={this.state.pessoaEmpresa.responsavelNome || ''}
                                    style={{ width: '100%', borderColor: this.state.validarInputs1.responsavelNome && 'var(--red-600)' }} />
                                {this.state.validarInputs1.responsavelNome && <small style={{ color: 'var(--red-600)' }}>Nome responsavel inválido</small>}
                            </div>

                            <div>
                                <label>CPF responsável</label><br />
                                <InputText
                                    onChange={(pTexto) => this.setState({
                                        pessoaEmpresa: {
                                            ...this.state.pessoaEmpresa, responsavelCpf: this.util.formatarCPF(pTexto.target.value)
                                        }
                                    })}
                                    value={this.state.pessoaEmpresa.responsavelCpf || ''}

                                    style={{ width: '100%', borderColor: this.state.validarInputs1.cpfResponsavel && 'var(--red-600)' }} />
                                {this.state.validarInputs1.cpfResponsavel && <small style={{ color: 'var(--red-600)' }}>CPF responsável inválido</small>}
                            </div>


                            <div>
                                <label>E-mail</label><br />
                                <InputText
                                    onChange={(pTexto) => this.setState({
                                        pessoaEmpresa: {
                                            ...this.state.pessoaEmpresa, emailPlataformaLirida: pTexto.target.value
                                        }
                                    })}
                                    value={this.state.pessoaEmpresa.emailPlataformaLirida || ''}

                                    style={{ width: '100%', borderColor: this.state.validarInputs1.email && 'var(--red-600)' }} />
                                {this.state.validarInputs1.email && <small style={{ color: 'var(--red-600)' }}>E-mail inválido</small>}
                            </div>

                            <div>
                                <label>Telefone</label><br />
                                <InputText
                                    onChange={(pTexto) => this.setState({
                                        pessoaEmpresa: {
                                            ...this.state.pessoaEmpresa, telefonePlataformaLirida: this.util.formatarTelefone(pTexto.target.value)
                                        }
                                    })}
                                    value={this.state.pessoaEmpresa.telefonePlataformaLirida || ''}

                                    style={{ width: '100%', borderColor: this.state.validarInputs1.telefone && 'var(--red-600)' }} />
                                {this.state.validarInputs1.telefone && <small style={{ color: 'var(--red-600)' }}>Telefone inválido</small>}
                            </div>

                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                <Button
                                    label="Voltar"
                                    type='button'
                                    className="p-button-raised"
                                    onClick={() => this.setState({ indexStep: 0 })}
                                />
                                {this.state.salvarCheck ?
                                    <Button label="Salvo" icon={<i className="pi pi-check"></i>} className="p-button-success" />
                                    :
                                    <Button loading={this.state.salvarCarregando} label="Salvar" className="p-button-raised" type='submit' />
                                }
                            </div>
                        </div>}
                </form>

            </Dialog>

            < Header />

            <Messages ref={this.msgs1} />

            <main style={{ width: '95%', margin: '20px auto' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', border: '1px solid lightgray', padding: 10, borderRadius: 3 }}>

                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 20, margin: '20px 0' }}>
                        <div>
                            <h1 style={{ fontSize: 22, }}>Cliente</h1>
                            <h2 style={{ fontSize: 20, color: 'gray' }}>Seus clientes</h2>
                        </div>
                    </div>

                    {/* <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 10, }}>
                        <Button label="Novo" disabled={!this.state.pessoaUsuario.contaS2pCodigo ? true : false} className="p-button-raised" onClick={() => this.novaPessoa()} />
                    </div> */}
                </div>

                <div style={{ border: '1px solid lightgray', padding: 10, borderRadius: 3, gap: 20, marginTop: 20 }}>
                    <h2 style={{ fontSize: 20, color: 'gray', marginBottom: 20, display: 'flex', gap: 18 }}>Filtro de busca
                        <i className="pi pi-search"
                            style={{ 'fontSize': '1em' }}></i></h2>
                    <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', gap: 20 }}>

                        <div>
                            <label>Data cadastro inicial</label><br />
                            <Calendar
                                showButtonBar
                                onChange={pTexto => this.setState({
                                    filtro: {
                                        ...this.state.filtro, dataCadastro_inicial: pTexto.target.value
                                    }
                                })}
                                value={this.state.filtro.dataCadastro_inicial || ''}
                                style={{ width: '100%', }} dateFormat="dd/mm/yy" placeholder="dd/mm/yyyy" />
                        </div>

                        <div>
                            <label>Data cadastro final</label><br />
                            <Calendar
                                showButtonBar
                                onChange={pTexto => this.setState({
                                    filtro: {
                                        ...this.state.filtro, dataCadastro_final: pTexto.target.value
                                    }
                                })}
                                value={this.state.filtro.dataCadastro_final || ''}
                                style={{ width: '100%', }} dateFormat="dd/mm/yy" placeholder="dd/mm/yyyy" />
                        </div>

                        <div>
                            <label>Razão social</label><br />
                            <InputText
                                showButtonBar
                                onChange={pTexto => this.setState({
                                    filtro: {
                                        ...this.state.filtro, nome: pTexto.target.value
                                    }
                                })}
                                value={this.state.filtro.nome || ''}
                                style={{ width: '100%', }} />
                        </div>

                        <div>
                            <label>Nome fantasia</label><br />
                            <InputText
                                showButtonBar
                                onChange={pTexto => this.setState({
                                    filtro: {
                                        ...this.state.filtro, nomeFantasia: pTexto.target.value
                                    }
                                })}
                                value={this.state.filtro.nomeFantasia || ''}
                                style={{ width: '100%', }} />
                        </div>


                        <div>
                            <label>Nome responsável</label><br />
                            <InputText
                                showButtonBar
                                onChange={pTexto => this.setState({
                                    filtro: {
                                        ...this.state.filtro, nomeResponsavel: pTexto.target.value
                                    }
                                })}
                                value={this.state.filtro.nomeResponsavel || ''}
                                style={{ width: '100%', }} />
                        </div>

                        <div>
                            <label>CNPJ</label><br />
                            <InputText
                                showButtonBar
                                onChange={pTexto => this.setState({
                                    filtro: {
                                        ...this.state.filtro, documento: this.util.formatarCNPJ(pTexto.target.value)
                                    }
                                })}
                                value={this.state.filtro.documento || ''}
                                style={{ width: '100%', }} />
                        </div>
                    </div>

                    <div style={{ display: 'flex', justifyContent: 'right', marginTop: 20 }}>
                        <Button loading={this.state.buscarCarregando} label="Buscar" className="p-button-raised" onClick={() => this.listarPessoa()} />
                    </div>
                </div>

                <DataTable style={{ marginTop: 20 }}
                    selectionMode="single"
                    showGridlines
                    removableSort
                    paginator
                    responsiveLayout="scroll"
                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords}"
                    rows={10}
                    rowsPerPageOptions={[10, 20, 50]}
                    value={this.state.listaPessoa}
                    onSelectionChange={e => this.selecionarPessoa(e.value)}>

                    <Column
                        field="_codigo"
                        header="id"
                        sortable />
                    <Column
                        field="nome"
                        header="Razão social"
                        sortable />
                    <Column
                        field="apelido"
                        header="Nome fantasia"
                        sortable />
                    <Column
                        field="responsavelNome"
                        header="Nome responsável"
                        sortable />
                    <Column
                        field="_pessoaRelacionadaNome"
                        header="Contador"
                        sortable />
                    <Column
                        field="tags5"
                        header="Possui contrato?"
                        sortable
                        body={pItem => pItem.tags5 === 'N' ? 'Não' : 'Sim'} />
                    <Column
                        field="dataCadastro"
                        header="Data cadastro"
                        sortable
                        body={item => <p>{this.util.formatarData(item.dataCadastro)}</p>
                        } />
                </DataTable>

            </main>

        </div >
    }
}