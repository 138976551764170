import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Component, createRef } from "react";
import { TabView, TabPanel } from 'primereact/tabview';
import Pessoa from "lirida-back-service/Servicos/Pessoa/Pessoa";
import PessoaService from "lirida-back-service/Servicos/Pessoa/PessoaService";
import Header from "../../components/Header";
import Constante from "../../Util/Constante";
import Util from "../../Util/Util";
import { Toast } from "primereact/toast";

export default class PessoaContadorDetalhe extends Component {
  toast = createRef();
  util = new Util();
  constante = new Constante();
  pessoaService = new PessoaService(this.constante.token, this.constante.url_base);

  state = {
    pessoaUsuario: new Pessoa(),
    pessoaContador: new Pessoa(),
    salvarCarregando: false,
  }

  componentDidMount() {
    this.iniciar();
  }

  async iniciar() {
    let pessoaUsuario = await this.util.buscarLocalStorage('PESSOA_USUARIO');
    let parametro = await this.util.buscarParametros();
    let retorno = await this.pessoaService.buscarPorSeqPessoa(parametro?.id);
    let pessoaContador = retorno.objeto;
    this.setState({
      pessoaUsuario: pessoaUsuario,
      pessoaContador: pessoaContador,
    })
  }

  async salvar() {
    this.setState({
      salvarCarregando: true,
    })
    let pessoaContador = this.state.pessoaContador;
    console.log(pessoaContador)

    let retorno = await this.pessoaService.salvar(pessoaContador);
    pessoaContador = retorno.objeto;
    console.log(pessoaContador)

    this.toast.current.show([
      { severity: 'success', summary: ' Alteração armazenadas com sucesso!' },
    ]);
    this.setState({
      pessoaContador: pessoaContador,
      salvarCarregando: false,
    })
  }
  render() {
    return (
      <div>
        <Header />
        <main style={{ width: '95%', margin: '20px auto' }}>
          <Toast ref={this.toast} />
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', border: '1px solid lightgray', padding: 10, borderRadius: 3 }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 20, margin: '20px 0' }}>
              <Button label={<i className="pi pi-arrow-left"></i>} className="p-button-raised" onClick={() => window.location.href = '/contador'} />
              <div>
                <h1 style={{ fontSize: 22 }}>Detalhe contador</h1>
                <h2 style={{ fontSize: 20, color: 'gray' }}>Detalhe do contador</h2>
              </div>
            </div>

            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 10, }}>
              <Button loading={this.state.salvarCarregando} label="Salvar" className="p-button-raised" onClick={() => this.salvar()} />
            </div>
          </div>

          <div style={{ border: '1px solid lightgray', padding: 10, borderRadius: 3, marginTop: 10 }}><br />
            <div style={{ display: "flex", alignItems: "center", gap: 5, }}>
              <h3>Informações do contador</h3>
            </div>

            <div style={{ width: '100%', display: "grid", gridTemplateColumns: " 1fr 1fr 1fr", gap: 20, marginTop: 20 }}>
              <div>
                <label>Nome</label><br />
                <InputText
                  value={this.state.pessoaContador.nome || ''}
                  style={{ width: '100%' }}
                  disabled
                />
              </div>

              <div>
                <label>CNPJ</label><br />
                <InputText
                  value={this.state.pessoaContador.documento || ''}
                  style={{ width: '100%' }}
                  disabled
                />
              </div>

              <div>
                <label>E-mail</label><br />
                <InputText
                  value={this.state.pessoaContador.emailPlataformaLirida || ''}
                  style={{ width: '100%' }}
                  disabled
                />
              </div>

              <div>
                <label>CRC</label><br />
                <InputText
                  value={this.state.pessoaContador.tags1 || ''}
                  style={{ width: '100%' }}
                  disabled
                />
              </div>
            </div>

            <TabView style={{ margin: '20px 0px 0px 0px' }}>
              <TabPanel header="Taxas" style={{ display: 'grid', gridTemplateColumns: '1fr', gap: 10 }}>
                <div>
                  <label>Taxa Boleto</label><br />
                  <InputText
                    onChange={(pTexto) => this.setState({
                      pessoaContador: {
                        ...this.state.pessoaContador, taxaBoleto: pTexto.target.value
                      }
                    })}
                    value={this.state.pessoaContador.taxaBoleto || ''}
                    style={{ width: '100%' }} />
                </div>
                <div>
                  <label>Taxa Cartão de Crédito</label><br />
                  <InputText
                    onChange={pTexto => this.setState({
                      pessoaContador: {
                        ...this.state.pessoaContador, taxaCredito: pTexto.target.value
                      }
                    })}
                    value={this.state.pessoaContador.taxaCredito || ''}
                    style={{ width: '100%' }} />
                </div>
                <div>
                  <label>Taxa Pix</label><br />
                  <InputText
                    onChange={pTexto => this.setState({
                      pessoaContador: {
                        ...this.state.pessoaContador, taxaPix: pTexto.target.value
                      }
                    })}
                    value={this.state.pessoaContador.taxaPix || ''}
                    style={{ width: '100%' }} />
                </div>
              </TabPanel>
            </TabView>
          </div>
        </main>
      </div>
    )
  }
}