export default class Constante {
    constructor() {

        //URL E TOKEN
        this.token = 'f90b68d4-9355-4aa6-8d4d-a5568853b779';

        // this.url_base = 'http://192.168.1.20:8080/v1/api/';
        this.url_base = 'https://api.lirida.com.br/v1/api/';
        this.url_base_crosspag = "https://crosspag.com.br/cobranca?id=";

        // USUÁRIO
        this.seqUsuario = '47d1fa8b-dfb5-46bd-b867-8a8c7470d111';
        this.seqPessoaAdministrador = 'a61a96d8-f66c-444c-a554-26d1d860d4c6';

        //ITEM
        this.seqItemGenerico = '61be6246-7b49-4baf-b0fe-ed3a66c99d7d';
        //ITEM CATEGORIA 
        this.seqCategoriaItemGenerico = '84a648c6-bfbb-4848-b817-7d9708aad606';

        //DOCUMENTO
        this.seqTipoDocumentoContrato = '7f098266-2e8a-46d2-8b39-2268ffc7b135';
        //PROCESSO DOCUMENTO
        this.seqPrimeiroProcessoDocumento = 'd86d8c97-dd0b-4f54-a63f-1eddccbe8123';
        this.seqProcessoDocumentoDeletado = '5579a958-1f50-41c4-a316-e440dfd5291f';
        //TIPO ARQUIVO
        this.seqTipoArquivoGenerico = '87c1fd7f-7a5a-4b4c-92ba-cddf5bceed28';

        // TIPO ATENDIMENTO
        this.seqTipoAtendimentoGenerico = '3ea5ef57-cf39-4e37-a200-819e1c23956a';

        //PESSOA
        this.seqPessoaPerfilClienteMei = 'a3494a44-077f-409d-8777-be943676639f';
        this.seqPessoaPerfilContador = '29f9a896-c1bb-495e-87dc-79793d3c6756';
        this.seqPessoaPerfilAdm = '11a65e97-99d9-4672-a07e-c5da5be2d10b';

        //TABELA DE PREÇO
        this.seqTabelaPrecoPadrao = 'b602f6ba-07e9-44c9-bef7-ce614cf634d4';

        //FORMA PAGAMENTO
        this.seqFormaPagamentoPix = 'd9b3ea60-e50a-4461-9623-914b115bd881';
        this.seqFormaPagamentoBoleto = '9a201991-98ef-4233-92bf-1529289860ec';
        this.seqFormaPagamentoCartaoDebito = 'b3152f6d-eec2-47e0-bcd8-3a0cfc7ed7ea';
        this.seqFormaPagamentoCartaoCredito = '665e8f01-b69d-4eaf-b02d-388b60f432db';

        //CONDICAO DE PAGEMENTO
        this.seqCondicaoPagamentoAvista = '45288f9d-d9f0-47cb-9793-5c9842fa2cb6';

        //CONTA BANCARIA
        this.seqContaBancariaPrincipal = '556245f6-be34-4362-815f-7707f14a2be6';

        // RELACIONAMENTO
        this.seqRelacionamentoContadorResponsavel = '4028350a-52a4-455d-8994-d888a9d5841a';

        //EMAIL
        this.seqEmailRecuperarSenha = '26065a97-beb8-4ad1-b402-4a30d1a55d37';
        this.seqEmailCadastro = '3fc1eadf-adce-46db-b4f0-7f95b0568f0a';
        this.seqEmailCadastroMei = '26a5c3f2-51fc-4c4b-8f3e-79589172129e';



        //DKEWOYRS
        this.chaveSite = '6Lc0Jh8iAAAAAJ-wII5mo5oGU2zSY94bkXNQErAN';
        this.chaveSecreta = '6Lc0Jh8iAAAAAP81BHMftJFloXdBgvKWKG1iHAbw';

        /* ID */
        this.idListaContaBancaria = 'CB';
        this.idListaContaFinanceira = 'CF';
        this.idListaFormaPagamento = 'FP';

        //ENDEREÇO
        this.seqTipoEnderecoEntrega = '7ffaf5b9-ad2e-4cf8-b30e-25a61fdcaf09';
        this.seqTipoEnderecoCobranca = 'b4062983-6166-4c91-8319-a25287b505b2';

        //CONTA FINANCEIRA
        this.seqContaFinanceiraAnalitica = 'fdeefb3c-4815-407d-b36c-5d6659b5438c';

        //PERIODO
        this.seqPeriodo = "1a589bc2-898e-48d1-a6ee-0999d1d4586a";

        /* TAXAS PESSOA */
        this.taxaBoleto = 2;
        this.taxaCredito = 5;
        this.taxaPix = 1.5;
        this.tipoTaxaBoleto = "2";
        this.tipoTaxaCredito = "1";
        this.tipoTaxaPix = "2";
    }
}