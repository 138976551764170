import React, { Component } from "react";
import logoBranca from '../../assets/images/logoBranca.png'
import { Navigate } from "react-router";
import { Sidebar } from 'primereact/sidebar';

export default class TermoDeUso extends Component {
    TelaEnum = {
        principal: 'principal',
        login: "login",
        sobre: 'sobre',
        funcionalidades: 'funcionalidades',
        depoimentos: 'depoimentos',
        perguntasFrequentes: 'perguntasFrequentes',
        politicaPrivacidade: "politicaPrivacidade",
        termoDeUso: "termoDeUso",
    }

    state = {
        sidebarVisivel: false,
        tela: this.TelaEnum.termoDeUso,
    }

    render() {

        return <>
            {this.state.tela === this.TelaEnum.principal && <Navigate to="/" />}
            {this.state.tela === this.TelaEnum.login && <Navigate to="/login" />}
            {this.state.tela === this.TelaEnum.sobre && <Navigate to="/#sobre" />}
            {this.state.tela === this.TelaEnum.funcionalidades && <Navigate to="/#funcionalidades" />}
            {this.state.tela === this.TelaEnum.depoimentos && <Navigate to="/#depoimentos" />}
            {this.state.tela === this.TelaEnum.perguntasFrequentes && <Navigate to="/#perguntas-frequentes" />}
            {this.state.tela === this.TelaEnum.politicaPrivacidade && <Navigate to="/politicaDePrivacidade" />}


            <Sidebar visible={this.state.sidebarVisivel} onHide={() => this.setState({ sidebarVisivel: false })}>
                <div style={{ display: 'flex', flexDirection: 'column', gap: 30, }}>
                    <a
                        onClick={() => this.setState({ sidebarVisivel: false, tela: this.TelaEnum.sobre })}
                        style={{ color: 'black', textDecoration: 'none' }}
                        href="#sobre">
                        Sobre
                    </a>
                    <a
                        onClick={() => this.setState({ sidebarVisivel: false, tela: this.TelaEnum.funcionalidades })}
                        style={{ color: 'black', textDecoration: 'none' }}
                        href="#funcionalidades">
                        Funcionalidades
                    </a>
                    <a
                        onClick={() => this.setState({ sidebarVisivel: false, tela: this.TelaEnum.depoimentos })}
                        style={{ color: 'black', textDecoration: 'none' }}
                        href="#depoimentos">
                        Depoimentos
                    </a>
                    <a
                        onClick={() => this.setState({ sidebarVisivel: false, tela: this.TelaEnum.perguntasFrequentes })}
                        style={{ color: 'black', textDecoration: 'none' }}
                        href="#perguntas-frequentes">
                        Perguntas frequentes
                    </a>
                    <a
                        onClick={() => this.setState({ sidebarVisivel: false, tela: this.TelaEnum.login })}
                        style={{ color: 'black', textDecoration: 'none' }}
                    >Login</a>
                </div>
            </Sidebar>


            <header className="h-header">
                <div className="h-header-container">

                    <img
                        src={logoBranca}
                        alt="logo"
                        style={{ cursor: 'pointer' }}
                        onClick={() => this.setState({ tela: this.TelaEnum.principal })} />

                    <nav>
                        <a
                            href="#sobre"
                            onClick={() => this.setState({ tela: this.TelaEnum.sobre })}>Sobre</a>
                        <a
                            href="#funcionalidades"
                            onClick={() => this.setState({ tela: this.TelaEnum.funcionalidades })}>
                            Funcionalidades
                        </a>

                        <a
                            href="#depoimentos"
                            onClick={() => this.setState({ tela: this.TelaEnum.depoimentos })}>
                            Depoimentos
                        </a>

                        <a
                            href="#perguntas-frequentes"
                            onClick={() => this.setState({ tela: this.TelaEnum.perguntasFrequentes })}>
                            Perguntas frequentes
                        </a>

                        <a onClick={() => this.setState({ tela: this.TelaEnum.login })}>
                            Login
                        </a>

                        <button onClick={() => this.setState({ sidebarVisivel: true })}>
                            <i className="pi pi-bars"></i>
                        </button>
                    </nav>
                </div>
            </header>

            <main className="h-main-container" style={{ padding: '90px 30px 30px 30px' }}>
                <section className="h-politica-privacidade-container">
                    <h1 className="h-titulo-underline">Termo de uso ContadorFlex</h1>
                    <p className="h-texto-normal">
                        Iniciando o uso do website da ContadorFlex e dos serviços disponibilizados por ele, você concorda e estará sujeito a todos os termos, políticas e condições gerais descritas abaixo.
                    </p>

                    <h2 className="h-titulo-paragrafo">1. Objeto</h2>

                    <p className="h-texto-normal">
                        1.1 A ContadorFlex é uma ferramenta online de captação de recursos financeiro de forma simples e fácil.
                    </p>
                    <p className="h-texto-normal">
                        1.2 A ContadorFlex possibilita a criação da página pública de projetos, possui opção para recebimento de doações e vendas online através de uma aplicação web na internet, acessível a partir do endereço.
                        <span><a href='https://www.contadorflex.com.br' ref="noreferrer" target='_blank'>https://www.contadorflex.com.br</a></span>
                    </p>

                    <h2 className="h-titulo-paragrafo">2. Propriedade</h2>
                    <p className="h-texto-normal">
                        2.1 A ContadorFlex foi desenvolvido pela CROSSSYSTEM TECNOLOGIA DA INFORMAÇÃO LTDA. CNPJ 20.706.163./0001-00, e a propriedade do sistema é, e será a qualquer tempo, da CROSSSYSTEM.
                    </p>

                    <p className="h-texto-normal">
                        2.2 O Usuário concorda que o uso da ContadorFlex não significa, nem significará, aquisição de qualquer direito sobre o mesmo, não podendo explorá-lo comercialmente ou comercializá-lo.
                    </p>

                    <p className="h-texto-normal">
                        2.3 O Usuário declara estar ciente de que a ContadorFlex contém informações confidenciais protegidas pela Lei de Proteção sobre Propriedade Intelectual e por direitos autorais.
                    </p>

                    <h2 className="h-titulo-paragrafo">3. Serviço</h2>

                    <p className="h-texto-normal">
                        3.1 Para utilização da ContadorFlex solicitamos os dados de identificação do Usuário para fins legais.
                    </p>

                    <p className="h-texto-normal">
                        3.2 A ContadorFlex é comercializado aos clientes a partir de um modelo de cobrança de tarifas por captação financeira confirmada nos projetos gerenciados. Os valores das tarifas e os prazos são divulgados no site <span><a href='https://www.contadorflex.com.br' rel="noreferrer" target='_blank'>https://www.contadorflex.com.br</a></span> e podem ser alterados a qualquer momento.
                    </p>

                    <p className="h-texto-normal">
                        3.3 É reservado à CROSSSYSTEM o direito de modificar a ContadorFlex, acrescentar ou excluir funcionalidades, acrescentar serviços, alterar layout, etc, por qualquer motivo e sem aviso prévio, sem que isso implique qualquer responsabilidade em relação ao Usuário ou a terceiros.
                    </p>

                    <p className="h-texto-normal">
                        3.4 Os dados dos Usuários da ContadorFlex são armazenados em servidores, que seguem políticas e normas de segurança da informação de primeiro nível, realizando cópias de segurança dos dados (backups) periodicamente e preparados para recuperá-los em caso de falhas.
                    </p>

                    <p className="h-texto-normal">
                        3.5 A CROSSSYSTEM também realiza diariamente cópias de segurança dos dados (backups) de todos os Usuários da ContadorFlex e também está preparada para recuperá-los em caso de falhas. Essa redundância de armazenamento representa nosso segundo nível de segurança dos dados da ContadorFlex.
                    </p>

                    <p className="h-texto-normal">
                        3.6 As informações inseridas na ContadorFlex são de total responsabilidade do Usuário.
                    </p>

                    <p className="h-texto-normal">
                        3.7 O Usuário se compromete a utilizar a ContadorFlex em conformidade com lei e regulamento local, estadual e nacional.
                    </p>

                    <p className="h-texto-normal">
                        3.8 O Usuário se compromete a não utilizar a ContadorFlex para meios ilícitos ou que prejudique interesse de terceiros, respeitando a ética, a moral e os bons costumes.
                    </p>

                    <p className="h-texto-normal">
                        3.9 Para acessar sua conta da ContadorFlex, o Usuário compromete-se a utilizar uma senha forte (difícil de ser descoberta) e não divulgá-la para outras pessoas, sendo responsável por sua confidencialidade.
                    </p>

                    <p className="h-texto-normal">
                        3.10 O Usuário declara expressamente ser responsável por sua conta na ContadorFlex e aceita voluntariamente que o uso do software ocorra sob sua única e exclusiva responsabilidade.
                    </p>

                    <p className="h-texto-normal">
                        3.11 Por ser um portal que permite o cadastramento de informações sobre projetos, a CROSSSYSTEM analisa e avalia a integridade de cada projeto publicado, podendo ser bloqueado caso seja identificado (i) informações falsas ou errôneas a respeito de projeto divulgadas no Website; (ii) problemas relativos à administração destes projetos, incluindo, mas não se limitando a atrasos, falta de qualidade, disparidades entre o projeto e o anúncio, projetos cadastrados que não correspondam às expectativas dos participantes.
                    </p>

                    <p className="h-texto-normal">
                        3.12 Para cancelamento da conta, o proprietário da mesma deverá realizar a solicitação através do email relacionamento@ContadorFlex.com.br.
                    </p>

                    <p className="h-texto-normal">
                        3.13 Para cancelamento de um projeto, a Organização deverá acessar o painel administrativo, com seu usuário e senha, e realizar a inativação do Projeto.
                    </p>

                    <h2 className="h-titulo-paragrafo">4. Devolução</h2>
                    <p className="h-texto-normal">
                        É possível realizar a devolução do valor doado no prazo máximo de 60 dias, enviado a solicitação para o email relacionamento@ContadorFlex.com.br.
                    </p>

                    <p className="h-texto-normal">
                        4.1 Boleto bancário<br />
                        Para receitas realizadas com boleto bancário, o doador deve fornecer os seguintes dados de quem realizou o pagamento:<br /><br />
                        - Nome completo;<br />
                        - Número do CPF;<br />
                        - Nome do banco;<br />
                        - Número da conta bancária;<br />
                        - Número da agência;<br />
                        - Valor a ser devolvido;<br />
                        Depois, é só esperar até 10 dias úteis para receber a restituição na sua conta corrente.<br />
                    </p>

                    <p className="h-texto-normal">
                        4.2 Cartão de crédito<br />
                        para receitas realizadas com cartão de crédito, solicitaremos o estorno do débito à administradora do seu cartão. O prazo para o estorno das parcelas poderá ocorrer em até 2 faturas após o pedido do estorno. Lembre-se que esse procedimento é de responsabilidade da administradora do cartão. Deverá informar os seguintes dados:<br /> <br />
                        - Nome completo;<br />
                        - Número do CPF;<br />
                        - Valor a ser estornado;<br />
                    </p>
                    <h2 className="h-titulo-paragrafo">5. Garantias e Responsabilidade</h2>
                    <p className="h-texto-normal">
                        5.1 O uso da ContadorFlex é fornecido sem garantia implícita ou expressa quanto a erros, perda de informações ou qualquer outro evento causado pela má utilização por parte do Usuário.
                    </p>

                    <p className="h-texto-normal">
                        5.2 A CROSSSYSTEM não garante que a ContadorFlex atenda as necessidades particulares do Usuário.
                    </p>
                    <p className="h-texto-normal">
                        5.3 O Usuário declara estar ciente que a CROSSSYSTEM não tem, nem terá, qualquer responsabilidade, perante ao Usuário ou a terceiros, pelos prejuízos, danos patrimoniais ou morais de qualquer natureza resultante da má utilização da ContadorFlex.
                    </p>
                    <p className="h-texto-normal">
                        5.4 Quando for solicitada transferência bancária de valores pelo usuário, a CROSSSYSTEM se responsabiliza e se compromete a realizar a mesma na conta informada pelo usuário em até 3 dias úteis, respeitando os valores solicitados e o saldo da conta do usuário, que se refere aos valores recebidos e compensados das receitas em projetos, já descontadas as tarifas cobradas pelo serviço, descritas no item 3.2 deste termo. O prazo de compensação das doações ocorre da seguinte forma:
                    </p>

                    <p className="h-texto-normal">
                        5.4.1 Boleto: 1 dia após a notificação de pagamento enviado pelo banco. O Saldo ficará disponível na plataforma para resgate em até 1 dia útil após a compensação;
                    </p>


                    <p className="h-texto-normal">
                        5.4.2 Cartão de Crédito: compensação conforme parcelamento (Ex: 30, 60, 90, 120 dias, etc). Saldo ficará disponível na plataforma para resgate em até 1 dia útil após a compensação;
                    </p>

                    <h2 className="h-titulo-paragrafo">6. Foro</h2>
                    <p className="h-texto-normal">
                        6.1 As Partes desenvolverão todos os esforços no sentido de resolver, amigavelmente, todas as controvérsias ou divergências que, porventura, forem decorrentes deste Termo.
                    </p>
                    <p className="h-texto-normal">
                        6.2 Não obstante o disposto no item anterior, o Usuário, ao aceitar este Termo, concorda em eleger o foro da comarca do Rio de Janeiro, no Estado do Rio de Janeiro, como competente para dirimir qualquer dúvida ou controvérsia porventura oriunda deste Termo e/ou da utilização do Sistema e, expressamente, renuncia a qualquer outro.
                    </p>

                    <h2 className="h-titulo-paragrafo">7 . Disposições Finais</h2>
                    <p className="h-texto-normal">
                        7.1 O Usuário concorda em receber, via e-mail, informativos do ContadorFlex e da CROSSSYSTEM.
                    </p>

                    <p className="h-texto-normal">
                        7.2 Os termos e condições poderão ser atualizados e modificados a qualquer tempo, mediante notificação prévia. Estando o Usuário de acordo, será feito um termo aditivo de comum acordo entre as partes.
                    </p>
                    <p className="h-texto-normal">
                        7.3 O Usuário aceita que a CROSSSYSTEM poderá, ao seu critério, alterar a prestação de serviços da ContadorFlex, interromper temporária ou definitivamente, mediante aviso prévio ao Usuário.
                    </p>
                    <p className="h-texto-normal">
                        7.4 A violação de qualquer condição estabelecida neste termo ensejará a imediata exclusão do Usuário do cadastro da ContadorFlex, ficando a partir de então suspenso qualquer acesso do Usuário às áreas restritas do Website, sem prejuízo do direito da CROSSSYSTEM e dos terceiros prejudicados de exigirem a reparação dos danos eventualmente causados independentemente de prévia notificação formal.
                    </p>

                    <p className="h-texto-normal">
                        7.5 O Usuário será o único, direto e exclusivo responsável por todos os danos, infrações, descumprimento legais e atos de qualquer natureza, que venha a causar a terceiros ou outros quando da utilização do sistema objeto deste instrumento. Na hipótese da CROSSSYSTEM vir a ser demandada em juízo por dano ocasionado pelo Usuário, ser-lhe-á lícito alegar sua ilegitimidade passiva, nomeá-lo à autoria, denunciá-lo à lide etc., enfim, praticar todos os atos necessários para exercer seu direito de regresso, servindo o presente instrumento como título executivo extrajudicial de todos e quaisquer valores eventualmente despendidos pela CROSSSYSTEM para fazer frente a indenizações, despesas judiciais etc., sendo que estes deverão ser atualizados na forma da lei.
                    </p>

                    <p className="h-texto-normal">
                        7.6 O Usuário declara que leu e concordou com todos os termos acima citados.
                    </p>
                </section>
            </main>

            <footer className="h-footer-container">
                <section >

                    <div>
                        <a href="#sobre"
                            onClick={() => this.setState({ tela: this.TelaEnum.sobre })}>Quem somos</a>
                        <a href="#funcionalidades"
                            onClick={() => this.setState({ tela: this.TelaEnum.funcionalidades })}>Funcionalidade</a>
                        <a href="#depoimentos"
                            onClick={() => this.setState({ tela: this.TelaEnum.depoimentos })}>Depoimentos</a>
                        <a href="#perguntas-frequentes"
                            onClick={() => this.setState({ tela: this.TelaEnum.perguntasFrequentes })}>
                            Perguntas frequentes
                        </a>

                        <a href="#perguntas-frequentes" onClick={() => this.setState({ tela: this.TelaEnum.login })}>
                            Login
                        </a>
                    </div>

                    <div>
                        <a onClick={() => {
                            window.scrollTo({ top: 0, behavior: 'smooth' });
                            this.setState({ tela: this.TelaEnum.politicaPrivacidade });
                        }}>
                            Política de privacidade
                        </a>

                        <a onClick={() => {
                            window.scrollTo({ top: 0, behavior: 'smooth' });
                        }}>
                            Termo de uso
                        </a>
                    </div>

                    <div>
                        <a
                            href="https://www.facebook.com/profile.php?id=100085863546876"
                            target='_blank'>
                            Facebook
                        </a>

                        <a
                            href="https://www.instagram.com/contadorflex/"
                            target='_blank'>
                            Instagram
                        </a>
                    </div>
                </section>
                <img src={logoBranca} alt="logo rodapé" />
            </footer>
        </>
    }
}