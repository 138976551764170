import { Button } from "primereact/button";
import { Component, createRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Calendar } from "primereact/calendar";
import { InputText } from "primereact/inputtext";
import { Dialog } from "primereact/dialog";
import { Steps } from 'primereact/steps';
import { InputNumber } from "primereact/inputnumber";

import Header from "../components/Header";

import DocumentoDetalhePg from "./DocumentoDetalhePg";
import Util from '../Util/Util';
import Constante from "../Util/Constante";
import Documento from "lirida-back-service/Servicos/Documento/Documento";
import DocumentoService from 'lirida-back-service/Servicos/Documento/DocumentoService';
import Pessoa from 'lirida-back-service/Servicos/Pessoa/Pessoa';
import PessoaService from 'lirida-back-service/Servicos/Pessoa/PessoaService';
import DocumentoRelacionamento from 'lirida-back-service/Servicos/DocumentoRelacionamento/DocumentoRelacionamento';
import { Messages } from "primereact/messages";



export default class DocumentoPg extends Component {
    msgs1 = createRef();
    msgs2 = createRef();
    constante = new Constante();
    util = new Util();
    documentoService = new DocumentoService(this.constante.token, this.constante.url_base);
    pessoaService = new PessoaService(this.constante.token, this.constante.url_base);

    state = {
        pessoaUsuario: new Pessoa(),
        pessoaClienteMei: new Pessoa(),
        documento: new Documento(),
        documentoRelacionamento: new DocumentoRelacionamento(),


        listaDocumento: [],
        listaBanco: [],
        conteudoBanco: '',

        tela: 1,
        buscarCarregando: false,

        vModalNovo: false,
        indexStep: 1,

        salvarCarregando: false,
        salvarCheck: false,

        //FILTRO
        filtro: {
            dataCadastro_inicial: '',
            dataCadastro_final: '',
            dataValidade_inicial: '',
            dataValidade_final: '',
            nomeCliente: '',
            cpfCliente: '',
            razaoSocial: '',
            cnpjCliente: '',
        },

        errorInput1: {
            visivel: false,
            msg: ""
        },

        errorInput2: {
            visivel: false,
            msg: ""
        },


        errorInput3: {
            visivel: false,
            msg: ""
        },

        errorInput4: {
            visivel: false,
            msg: ""
        },

        errorInput5: {
            visivel: false,
            msg: ""
        },

        errorInput6: {
            visivel: false,
            msg: ""
        },
        errorInput7: {
            visivel: false,
            msg: ""
        },
        errorInput8: {
            visivel: false,
            msg: ""
        },
        errorInput9: {
            visivel: false,
            msg: ""
        },
    }

    componentDidMount() {
        this.iniciar();
    }

    async iniciar() {
        this.util.traduzirCalendar();
        this.pessoaUsuario = await this.util.buscarLocalStorage('PESSOA_USUARIO');
        if (!this.pessoaUsuario?._seqPessoa) {
            window.history.back()
        }
        this.setState({ pessoaUsuario: this.pessoaUsuario });
        this.listar(this.pessoaUsuario);
    }

    selecionar(pDocumento) {
        this.setState({ tela: 2, documento: pDocumento });
    }

    async listar(pPessoUsuario) {
        if (pPessoUsuario) {
            this.pessoaUsuario = pPessoUsuario;
        } else {
            this.pessoaUsuario = this.state.pessoaUsuario;
        }


        this.setState({ buscarCarregando: true });
        this.listaPesquisa = [
            this.util.criarObjetoPesquisa(
                'documento.seq_usuario',
                this.constante.seqUsuario,
                '',
                'TEXTO'
            ),

            this.util.criarObjetoPesquisa(
                'documento_relacionamento.seq_pessoa_relacionada',
                this.pessoaUsuario._seqPessoa,
                'AND',
                'TEXTO'
            ),

            this.util.criarObjetoPesquisa(
                'documento.seq_tipo_documento_processo',
                this.constante.seqPrimeiroProcessoDocumento,
                'AND',
                'TEXTO'
            ),
        ]
        if (this.state.filtro.dataCadastro_inicial) {
            this.listaPesquisa.push(
                this.util.criarObjetoPesquisa(
                    'documento.data_cadastro',
                    this.state.filtro.dataCadastro_inicial,
                    'AND',
                    'DATA_INICIAL'
                )
            )
        }

        if (this.state.filtro.dataCadastro_final) {
            this.listaPesquisa.push(
                this.util.criarObjetoPesquisa(
                    'documento.data_cadastro',
                    this.state.filtro.dataCadastro_final,
                    'AND',
                    'DATA_FINAL'
                )
            )
        }

        if (this.state.filtro.dataValidade_inicial) {
            this.listaPesquisa.push(
                this.util.criarObjetoPesquisa(
                    'documento.data_validade',
                    this.state.filtro.dataValidade_inicial,
                    'AND',
                    'DATA_INICIAL'
                )
            )
        }

        if (this.state.filtro.dataValidade_final) {
            this.listaPesquisa.push(
                this.util.criarObjetoPesquisa(
                    'documento.data_validade',
                    this.state.filtro.dataValidade_final,
                    'AND',
                    'DATA_FINAL'
                )
            )
        }

        if (this.state.filtro.nomeCliente) {
            this.listaPesquisa.push(
                this.util.criarObjetoPesquisa(
                    'pessoa.nome',
                    this.state.filtro.nomeCliente,
                    'AND',
                    'TEXTO'
                )
            )
        }

        if (this.state.filtro.cpfCliente) {
            this.listaPesquisa.push(
                this.util.criarObjetoPesquisa(
                    'pessoa.documento',
                    this.state.filtro.cpfCliente,
                    'AND',
                    'TEXTO'
                )
            )
        }

        if (this.state.filtro.razaoSocial) {
            this.listaPesquisa.push(
                this.util.criarObjetoPesquisa(
                    'pessoa.apelido',
                    this.state.filtro.razaoSocial,
                    'AND',
                    'TEXTO'
                )
            )
        }

        if (this.state.filtro.cnpjCliente) {
            this.listaPesquisa.push(
                this.util.criarObjetoPesquisa(
                    'pessoa.tags0',
                    this.state.filtro.cnpjCliente,
                    'AND',
                    'TEXTO'
                )
            )
        }


        console.log(this.listaPesquisa);
        this.retornoLista = await this.documentoService.listarComFiltro(this.listaPesquisa);
        this.listaDocumento = this.retornoLista.objeto;

        console.log('Lista documento');
        console.log(this.listaDocumento);
        this.setState({
            listaDocumento: this.listaDocumento,
            buscarCarregando: false,
            filtro: {
                ...this.state.filtro,
                dataCadastro_inicial: '',
                dataCadastro_final: '',
                dataValidade_inicial: '',
                dataValidade_final: '',
                cpfCliente: '',
                cnpjCliente: '',
                nomeCliente: '',
                razaoSocial: '',
            }
        });

    }



    novoContrato() {
        this.setState({
            documento: new Documento(),
            pessoaClienteMei: new Pessoa(),
            vModalNovo: true,
            errorInput1: {
                ...this.state.errorInput1, visivel: false, msg: ''
            },
            errorInput2: {
                ...this.state.errorInput2, visivel: false, msg: ''
            },
            errorInput3: {
                ...this.state.errorInput3, visivel: false, msg: ''
            },
            errorInput4: {
                ...this.state.errorInput4, visivel: false, msg: ''
            },
            errorInput5: {
                ...this.state.errorInput5, visivel: false, msg: ''
            },
            errorInput6: {
                ...this.state.errorInput6, visivel: false, msg: ''
            },
            errorInput7: {
                ...this.state.errorInput7, visivel: false, msg: ''
            },
            errorInput8: {
                ...this.state.errorInput8, visivel: false, msg: ''
            },
            errorInput9: {
                ...this.state.errorInput9, visivel: false, msg: ''
            },
        })
    }

    async salvarContrato() {
        this.errorInput7 = this.state.errorInput7;
        this.errorInput8 = this.state.errorInput8;
        this.inputsOk = true

        if (!this.state.documento.dataProximaRecorrencia) {
            this.errorInput7.visivel = true;
            this.errorInput7.msg = 'Campo data próxima recorrencia é obrigatório';
            this.inputsOk = false;
        } else {
            this.errorInput7.visivel = false;
            this.errorInput7.msg = '';
        }

        if (!this.state.documento.valorTotal) {
            this.errorInput8.visivel = true;
            this.errorInput8.msg = 'Campo valor é obrigatório';
            this.inputsOk = false;
        } else {
            this.errorInput8.visivel = false;
            this.errorInput8.msg = '';
        }

        if (!this.inputsOk) {
            this.setState({
                errorInput7: this.errorInput7,
                errorInput8: this.errorInput8,
            })
            return
        }
        this.setState({ salvarCarregando: true });
        this.pessoaClienteMei = this.state.pessoaClienteMei;
        this.pessoaUsuario = this.state.pessoaUsuario;
        this.documento = this.state.documento;
        if (!this.pessoaClienteMei.nome || !this.state.pessoaClienteMei.documento || !this.documento.valorTotal) {
            this.setState({ salvarCarregando: false })
            return
        }

        this.documentoNovo = true;

        if (this.documento._seqDocumento) {
            this.documentoNovo = false;
        }

        //SALVANDO PESSOA CLIENTE
        this.pessoaClienteMei.seqUsuario = this.constante.seqUsuario;
        this.pessoaClienteMei.tipoDocumento = 'CNPJ';
        this.pessoaClienteMei.seqPessoaPerfil = this.constante.seqPessoaPerfilClienteMei;
        this.pessoaClienteMei.tags4 = this.pessoaUsuario.telefonePlataformaLirida;
        this.retornoPessoa = await this.pessoaService.salvar(this.pessoaClienteMei);
        this.pessoaClienteMei = this.retornoPessoa.objeto;
        console.log('CLIENTE SALVO');
        console.log(this.pessoaClienteMei);


        //SALVANDO DOCUMENTO
        this.documento = this.state.documento;
        this.documento.seqContaBancaria = this.constante.seqContaBancariaPrincipal;
        this.documento.seqUsuario = this.constante.seqUsuario;
        this.documento.seqTipoDocumento = this.constante.seqTipoDocumentoContrato;
        this.documento.seqPessoa = this.pessoaClienteMei._seqPessoa;
        this.documento.seqCondicaoPagamento = this.constante.seqCondicaoPagamentoAvista;
        this.documento.seqFormaPagamento = this.constante.seqFormaPagamentoPix;
        this.documento.seqTipoDocumentoProcesso = this.constante.seqPrimeiroProcessoDocumento;
        this.documento.seqPessoaRecebedor = this.pessoaUsuario._seqPessoa;
        this.documento.seqContaFinanceira = this.constante.seqContaFinanceiraAnalitica;
        this.documento.seqPeriodo = this.constante.seqPeriodo;
        this.documento.valorLiquido = this.documento.valorTotal;
        console.log(this.documento)

        this.retornoDocumento = await this.documentoService.salvar(this.documento);
        this.documento = this.retornoDocumento.objeto;
        console.log('DOCUMENTO SALVO')
        console.log(this.documento)


        //RELACIONAMENTO DOCUMENTO
        if (this.documentoNovo) {
            this.documentoRelacionamento = this.state.documentoRelacionamento;
            this.documentoRelacionamento.seqUsuario = this.constante.seqUsuario;
            this.documentoRelacionamento.seqDocumento = this.documento._seqDocumento;
            this.documentoRelacionamento.seqTipoRelacionamento = this.constante.seqRelacionamentoContadorResponsavel;
            this.documentoRelacionamento.seqPessoaRelacionada = this.pessoaUsuario._seqPessoa;
            this.retornoDocumentoRelacionamento = await this.documentoService.salvarRelacionamento(this.documentoRelacionamento);
            this.documentoRelacionamento = this.retornoDocumentoRelacionamento.objeto;
            console.log("RELACIONAMENTO DOCUMENTO");
            console.log(this.documentoRelacionamento);
            this.movimentoFinanceiro = await this.documentoService.gerarMovimentoFinanceiro(this.documento);
            this.movimentoFinanceiro = this.movimentoFinanceiro.objeto
            console.log("MOVIMENTO FINANCEIRO");
            console.log(this.movimentoFinanceiro);

            this.util.enviarEmailSenhaMei(this.constante.seqEmailCadastroMei, this.pessoaClienteMei.emailPlataformaLirida, this.pessoaClienteMei.documento, this.pessoaClienteMei.nome, this.pessoaClienteMei.senha);
        }

        this.pessoaUsuario.tags2 = 'S';
        this.retornoPessoaUsuario = await this.pessoaService.salvar(this.pessoaUsuario);
        this.pessoaUsuario = this.retornoPessoaUsuario.objeto
        this.util.salvarLocalStorage('PESSOA_USUARIO', this.pessoaUsuario)
        this.setState({
            pessoaUsuario: this.pessoaUsuario,
            salvarCarregando: false,
            salvarCheck: true,
            documento: new Documento(),
            pessoaClienteMei: new Pessoa(),
        })
        setTimeout(() => this.setState({ salvarCheck: false, vModalNovo: false, indexStep: 0, }), 1000);
        this.msgs1.current.show([{ severity: 'success', summary: 'Contrato salvo com sucesso.', life: 5000 }]);
        this.listar();
    }


    avancarSteps() {
        this.errorInput1 = this.state.errorInput1;
        this.errorInput2 = this.state.errorInput2;
        this.errorInput3 = this.state.errorInput3;
        this.errorInput4 = this.state.errorInput4;
        this.errorInput5 = this.state.errorInput5;
        this.errorInput6 = this.state.errorInput6;
        this.errorInput9 = this.state.errorInput9;
        this.inputsOk = true;

        if (!this.state.pessoaClienteMei.nome) {
            this.errorInput1.visivel = true;
            this.errorInput1.msg = 'Campo nome é obrigatório'
            this.inputsOk = false
        } else {
            this.errorInput1.visivel = false;
            this.errorInput1.msg = '';
            this.inputsOk = true;
        }

        if (!this.state.pessoaClienteMei.documento) {
            this.errorInput2.visivel = true;
            this.errorInput2.msg = 'Campo cpf é obrigatório'
            this.inputsOk = false
        } else {
            this.errorInput2.visivel = false;
            this.errorInput2.msg = '';
            this.inputsOk = true;
        }

        if (!this.state.pessoaClienteMei.emailPlataformaLirida) {
            this.errorInput3.visivel = true;
            this.errorInput3.msg = 'Campo e-mail é obrigatório'
            this.inputsOk = false
        } else {
            this.errorInput3.visivel = false;
            this.errorInput3.msg = '';
            this.inputsOk = true;
        }

        if (!this.state.pessoaClienteMei.telefonePlataformaLirida) {
            this.errorInput9.visivel = true;
            this.errorInput9.msg = 'Campo telefone é obrigatório'
            this.inputsOk = false
        } else {
            this.errorInput9.visivel = false;
            this.errorInput9.msg = '';
            this.inputsOk = true;
        }

        if (!this.state.pessoaClienteMei.apelido) {
            this.errorInput4.visivel = true;
            this.errorInput4.msg = 'Campo e-mail é obrigatório'
            this.inputsOk = false
        } else {
            this.errorInput4.visivel = false;
            this.errorInput4.msg = '';
            this.inputsOk = true;
        }

        if (!this.state.pessoaClienteMei.tags0) {
            this.errorInput5.visivel = true;
            this.errorInput5.msg = 'Campo cnpj é obrigatório'
            this.inputsOk = false
        } else {
            this.errorInput5.visivel = false;
            this.errorInput5.msg = '';
            this.inputsOk = true;
        }

        if (!this.state.pessoaClienteMei.tags3) {
            this.errorInput6.visivel = true;
            this.errorInput6.msg = 'Campo inscrição municipal é obrigatório'
            this.inputsOk = false
        } else {
            this.errorInput6.visivel = false;
            this.errorInput6.msg = '';
            this.inputsOk = true;
        }

        if (this.inputsOk) {
            this.setState({ indexStep: 1 })
        } else {
            this.setState({
                errorInput1: this.errorInput1,
                errorInput2: this.errorInput2,
                errorInput3: this.errorInput3,
                errorInput4: this.errorInput4,
                errorInput5: this.errorInput5,
                errorInput6: this.errorInput6,
                errorInput9: this.errorInput9,
            })
        }
    }
    render() {
        return this.state.pessoaUsuario._seqPessoa && <div>
            <Dialog
                header="Novo contrato"
                visible={this.state.vModalNovo}
                style={{ width: 700 }}
                onHide={() => this.setState({ vModalNovo: false, documento: new Documento() })}
            >
                <Steps
                    model={[{ label: "Informações do cliente" }, { label: "Informações do contrato" }]}
                    activeIndex={this.state.indexStep} />
                <form
                    onSubmit={e => { e.preventDefault(); this.salvarContrato() }}
                    style={{ padding: 20, display: "flex", flexDirection: "column", gap: 10 }}>
                    {this.state.indexStep === 0 &&
                        <div style={{ display: 'flex', flexDirection: 'column', gap: 10, padding: '20px 0' }}>
                            <div>
                                <label>Nome</label><br />
                                <InputText
                                    onChange={(pTexto) => this.setState({
                                        pessoaClienteMei: {
                                            ...this.state.pessoaClienteMei, apelido: pTexto.target.value
                                        }
                                    })}
                                    value={this.state.pessoaClienteMei.apelido || ''}
                                    style={{ width: '100%', borderColor: this.state.errorInput1.visivel && 'var(--red-600)' }} />
                                <small style={{ color: 'var(--red-600)' }}>{this.state.errorInput1.msg}</small>

                            </div>

                            <div>
                                <label>CPF</label><br />
                                <InputText
                                    onChange={(pTexto) => this.setState({
                                        pessoaClienteMei: {
                                            ...this.state.pessoaClienteMei, tags0: this.util.formatarCPF(pTexto.target.value)
                                        }
                                    })}
                                    value={this.state.pessoaClienteMei.tags0 || ''}
                                    style={{ width: '100%', borderColor: this.state.errorInput2.visivel && 'var(--red-600)' }} />
                                <small style={{ color: 'var(--red-600)' }}>{this.state.errorInput2.msg}</small>
                            </div>

                            <div>
                                <label>E-mail</label><br />
                                <InputText
                                    onChange={(pTexto) => this.setState({
                                        pessoaClienteMei: {
                                            ...this.state.pessoaClienteMei, emailPlataformaLirida: pTexto.target.value
                                        }
                                    })}
                                    value={this.state.pessoaClienteMei.emailPlataformaLirida || ''}
                                    style={{ width: '100%', borderColor: this.state.errorInput3.visivel && 'var(--red-600)' }} />
                                <small style={{ color: 'var(--red-600)' }}>{this.state.errorInput3.msg}</small>
                            </div>

                            <div>
                                <label>Telefone</label><br />
                                <InputText
                                    onChange={(pTexto) => this.setState({
                                        pessoaClienteMei: {
                                            ...this.state.pessoaClienteMei, telefonePlataformaLirida: this.util.formatarTelefone(pTexto.target.value)
                                        }
                                    })}
                                    value={this.state.pessoaClienteMei.telefonePlataformaLirida || ''}
                                    style={{ width: '100%', borderColor: this.state.errorInput9.visivel && 'var(--red-600)' }} />
                                <small style={{ color: 'var(--red-600)' }}>{this.state.errorInput9.msg}</small>
                            </div>

                            <div>
                                <label>Razão social</label><br />
                                <InputText
                                    onChange={(pTexto) => this.setState({
                                        pessoaClienteMei: {
                                            ...this.state.pessoaClienteMei, nome: pTexto.target.value
                                        }
                                    })}
                                    value={this.state.pessoaClienteMei.nome}
                                    style={{ width: '100%', borderColor: this.state.errorInput4.visivel && 'var(--red-600)' }} />
                                <small style={{ color: 'var(--red-600)' }}>{this.state.errorInput4.msg}</small>
                            </div>

                            <div>
                                <label>CNPJ</label><br />
                                <InputText
                                    onChange={(pTexto) => this.setState({
                                        pessoaClienteMei: {
                                            ...this.state.pessoaClienteMei, documento: this.util.formatarCNPJ(pTexto.target.value)
                                        }
                                    })}
                                    value={this.state.pessoaClienteMei.documento || ''}
                                    style={{ width: '100%', borderColor: this.state.errorInput5.visivel && 'var(--red-600)' }} />
                                <small style={{ color: 'var(--red-600)' }}>{this.state.errorInput5.msg}</small>
                            </div>



                            <div>
                                <label>Inscrição municipal</label><br />
                                <InputText
                                    onChange={(pTexto) => this.setState({
                                        pessoaClienteMei: {
                                            ...this.state.pessoaClienteMei, tags3: pTexto.target.value
                                        }
                                    })}
                                    value={this.state.pessoaClienteMei.tags3 || ''}
                                    style={{ width: '100%', borderColor: this.state.errorInput6.visivel && 'var(--red-600)' }} />
                                <small style={{ color: 'var(--red-600)' }}>{this.state.errorInput6.msg}</small>
                            </div>

                            <div style={{ display: 'flex', justifyContent: 'right', width: '100%' }}>
                                <Button
                                    label="Próximo"
                                    type='button'
                                    className="p-button-raised" onClick={() => this.avancarSteps()} />
                            </div>
                        </div>
                    }

                    {this.state.indexStep === 1 &&
                        <div style={{ display: 'flex', flexDirection: 'column', gap: 10, padding: '20px 0' }}>
                            <div>
                                <label>Data próxima cobrança</label><br />
                                <Calendar
                                    onChange={pTexto => this.setState({
                                        documento: {
                                            ...this.state.documento, dataProximaRecorrencia: this.util.formatarDataBanco(pTexto.target.value)
                                        }
                                    })}
                                    showButtonBar
                                    value={this.util.formatarDataCalendar(this.state.documento.dataProximaRecorrencia)}
                                    style={{ width: '100%', borderColor: this.state.errorInput7.visivel && 'var(--red-600)' }}
                                    dateFormat="dd/mm/yy" placeholder="dd/mm/yyyy" />
                                <small style={{ color: 'var(--red-600)' }}>{this.state.errorInput7.msg}</small>
                            </div>

                            <div>
                                <label>Valor</label><br />
                                <InputNumber
                                    onValueChange={pTexto => this.setState({
                                        documento: {
                                            ...this.state.documento, valorTotal: pTexto.target.value
                                        }
                                    })}
                                    mode="currency"
                                    currency="BRL"
                                    locale="pt-BR"
                                    value={this.state.documento.valorTotal}
                                    style={{ width: '100%', borderColor: this.state.errorInput8.visivel && 'var(--red-600)' }} />
                                <small style={{ color: 'var(--red-600)' }}>{this.state.errorInput8.msg}</small>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                                <Button
                                    label="Voltar"
                                    type='button'
                                    className="p-button-raised"
                                    onClick={() => this.setState({ indexStep: 0 })}
                                />
                                {this.state.salvarCheck ?
                                    <Button label="Salvo" icon={<i className="pi pi-check"></i>} className="p-button-success" />
                                    :
                                    <Button loading={this.state.salvarCarregando} label="Salvar" className="p-button-raised" type='submit' />
                                }
                            </div>
                        </div>}
                </form>

            </Dialog>

            < Header />

            <Messages ref={this.msgs1} />

            {this.state.tela === 1 ? <main style={{ width: '95%', margin: '20px auto' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', border: '1px solid lightgray', padding: 10, borderRadius: 3 }}>

                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 20, margin: '20px 0' }}>
                        <div>
                            <h1 style={{ fontSize: 22, }}>Contratos</h1>
                            <h2 style={{ fontSize: 20, color: 'gray' }}>Seus contratos</h2>
                        </div>
                    </div>

                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 10, }}>
                        <Button label="Novo" disabled={!this.state.pessoaUsuario.contaS2pCodigo ? true : false} className="p-button-raised" onClick={() => this.novoContrato()} />
                    </div>
                </div>

                <div style={{ border: '1px solid lightgray', padding: 10, borderRadius: 3, gap: 20, marginTop: 20 }}>
                    <h2 style={{ fontSize: 20, color: 'gray', marginBottom: 20, display: 'flex', gap: 18 }}>Filtro de busca
                        <i className="pi pi-search"
                            style={{ 'fontSize': '1em' }}></i></h2>
                    <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr', gap: 20 }}>

                        <div>
                            <label>Data cadastro inicial</label><br />
                            <Calendar
                                showButtonBar
                                onChange={pTexto => this.setState({
                                    filtro: {
                                        ...this.state.filtro, dataCadastro_inicial: this.util.formatarDataBanco(pTexto.target.value)
                                    }
                                })}
                                value={this.state.filtro.dataCadastro_inicial || ''}
                                style={{ width: '100%', }} dateFormat="dd/mm/yy" placeholder="dd/mm/yyyy" />
                        </div>

                        <div>
                            <label>Data cadastro final</label><br />
                            <Calendar
                                showButtonBar
                                onChange={pTexto => this.setState({
                                    filtro: {
                                        ...this.state.filtro, dataCadastro_final: this.util.formatarDataBanco(pTexto.target.value)
                                    }
                                })}
                                value={this.state.filtro.dataCadastro_final || ''}
                                style={{ width: '100%', }} dateFormat="dd/mm/yy" placeholder="dd/mm/yyyy" />
                        </div>
                        {/* 
                        <div>
                            <label>Data validade inicial</label><br />
                            <Calendar
                                showButtonBar
                                onChange={pTexto => this.setState({
                                    filtro: {
                                        ...this.state.filtro, dataValidade_inicial: this.util.formatarDataBanco(pTexto.target.value)
                                    }
                                })}
                                value={this.state.filtro.dataValidade_inicial || ''}
                                style={{ width: '100%', }} dateFormat="dd/mm/yy" placeholder="dd/mm/yyyy" />
                        </div>

                        <div>
                            <label>Data validade final</label><br />
                            <Calendar
                                showButtonBar
                                onChange={pTexto => this.setState({
                                    filtro: {
                                        ...this.state.filtro, dataValidade_final: this.util.formatarDataBanco(pTexto.target.value)
                                    }
                                })}
                                value={this.state.filtro.dataValidade_final || ''}
                                style={{ width: '100%', }} dateFormat="dd/mm/yy" placeholder="dd/mm/yyyy" />
                        </div> */}

                        <div>
                            <label>Nome do cliente</label><br />
                            <InputText
                                onChange={pTexto => this.setState({
                                    filtro: {
                                        ...this.state.filtro, nomeCliente: pTexto.target.value
                                    }
                                })}
                                value={this.state.filtro.nomeCliente || ''}
                                style={{ width: '100%', }} />
                        </div>

                        <div>
                            <label>CPF do cliente</label><br />
                            <InputText
                                onChange={pTexto => this.setState({
                                    filtro: {
                                        ...this.state.filtro, cpfCliente: this.util.formatarCPF(pTexto.target.value)
                                    }
                                })}
                                value={this.state.filtro.cpfCliente || ''}
                                style={{ width: '100%', }} placeholder="___.___.___-__" />
                        </div>

                        <div>
                            <label>Razão social</label><br />
                            <InputText
                                onChange={pTexto => this.setState({
                                    filtro: {
                                        ...this.state.filtro, razaoSocial: pTexto.target.value
                                    }
                                })}
                                value={this.state.filtro.razaoSocial || ''}
                                style={{ width: '100%', }} />
                        </div>

                        <div>
                            <label>CNPJ do cliente</label><br />
                            <InputText
                                onChange={pTexto => this.setState({
                                    filtro: {
                                        ...this.state.filtro, cnpjCliente: this.util.formatarCNPJ(pTexto.target.value)
                                    }
                                })}
                                value={this.state.filtro.cnpjCliente || ''}
                                style={{ width: '100%', }} placeholder="__.___.___/____-__" />
                        </div>
                    </div>

                    <div style={{ display: 'flex', justifyContent: 'right', marginTop: 20 }}>
                        <Button loading={this.state.buscarCarregando} label="Buscar" className="p-button-raised" onClick={() => this.listar()} />
                    </div>
                </div>

                <DataTable style={{ marginTop: 20 }}
                    selectionMode="single"
                    showGridlines
                    removableSort
                    paginator
                    responsiveLayout="scroll"
                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords}"
                    rows={10}
                    rowsPerPageOptions={[10, 20, 50]}
                    value={this.state.listaDocumento}
                    onSelectionChange={e => this.selecionar(e.value)}>

                    <Column
                        field="_codigo"
                        header="id"
                        sortable />
                    <Column
                        field="_tipoDocumentoNome"
                        header="Nome"
                        sortable />
                    <Column
                        field="_pessoaNome"
                        header="Cliente"
                        sortable />
                    <Column
                        field="_pessoaRelacionadaNome"
                        header="Contador"
                        sortable />
                    <Column
                        field="dataCadastro"
                        header="Data cadastro"
                        sortable
                        body={item => <p>{this.util.formatarData(item.dataCadastro)}</p>
                        } />
                    {/* <Column
                        field="dataValidade"
                        header="Data validade"
                        sortable
                        body={item => <p>{this.util.formatarData(item.dataValidade)}</p>
                        } /> */}
                    <Column
                        field="dataProximaRecorrencia"
                        header="Data recorrência"
                        sortable
                        body={item => <p>{this.util.formatarData(item.dataProximaRecorrencia)}</p>
                        } />
                </DataTable>

            </main>
                : <DocumentoDetalhePg
                    listarDocumento={() => this.listar()}
                    setState={(pState) => this.setState(pState)}
                    documento={this.state.documento}
                />
            }
        </div>
    }
}