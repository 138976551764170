import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { InputNumber } from "primereact/inputnumber";
import { TabView, TabPanel } from 'primereact/tabview';
import { Component } from "react";
import { Dialog } from 'primereact/dialog';
import Documento from 'lirida-back-service/Servicos/Documento/Documento'
import DocumentoService from 'lirida-back-service/Servicos/Documento/DocumentoService'
import DocumentoRelacionamento from 'lirida-back-service/Servicos/DocumentoRelacionamento/DocumentoRelacionamento';
import PessoaService from "lirida-back-service/Servicos/Pessoa/PessoaService";
import Pessoa from "lirida-back-service/Servicos/Pessoa/Pessoa";
import Util from "../Util/Util";
import Constante from "../Util/Constante";
import { Calendar } from "primereact/calendar";
import DocumentoArquivo from 'lirida-back-service/Servicos/DocumentoArquivo/DocumentoArquivo';
import DocumentoAtendimento from 'lirida-back-service/Servicos/DocumentoAtendimento/DocumentoAtendimento';

export default class DocumentoDetalhePg extends Component {
    constante = new Constante();
    util = new Util();
    pessoaService = new PessoaService(this.constante.token, this.constante.url_base);
    documentoService = new DocumentoService(this.constante.token, this.constante.url_base);

    state = {
        pessoaUsuario: new Pessoa(),
        pessoaClienteMei: new Pessoa(),
        documento: new Documento(),
        documentoRelacionamento: new DocumentoRelacionamento(),
        documentoAtendimento: new DocumentoAtendimento(),
        documentoArquivo: new DocumentoArquivo(),
        listaArquivo: [],
        listaDocumentoAtendimento: [],
        url_baseArquivo: '',
        dataVencimento: '',
        base64Arquivo: '',
        nomeArquivo: '',
        tipoArquivo: '',
        salvarCarregando: false,
        deletarCarregando: false,
        vModalDeletar: false,
        vModalDeletarArquivo: false,
        vModalDeletarAtendimento: false,
        salvarCheck: false,
        documentoNovo: false,

        inputsContratoError: {
            nomeCliente: false,
            cpfCliente: false,
            emailCliente: false,
            telefoneCliente: false,
            razaoSocial: false,
            cnpjCliente: false,
            inscricaoMunicipal: false,
            dataProximaCobranca: false,
            valor: false,
        }
    }
    componentDidMount() {
        this.iniciar();
    }

    async iniciar() {
        this.pessoaUsuario = await this.util.buscarLocalStorage("PESSOA_USUARIO");
        console.log('PESSOA USUARIO');
        console.log(this.pessoaUsuario);
        this.url_baseArquivo = await this.util.buscarUrlBaseArquivo();
        if (!this.pessoaUsuario?._seqPessoa) {
            window.history.back()
        }
        this.documento = this.props.documento;
        if (this.documento._seqDocumento) {
            this.documentoNovo = false;
            this.pessoaClienteMei = await this.listarPessoaCliente(this.documento);
        } else {
            this.documentoNovo = true;
            this.pessoaClienteMei = this.state.pessoaClienteMei;
            this.documento = this.state.documento;
        }
        console.log("DOCUMENTO SELECIONADO");
        console.log(this.documento);
        this.listarArquivo(this.documento);
        this.listarDocumentoAtendimento(this.documento);
        this.setState({
            documentoNovo: this.documentoNovo,
            url_baseArquivo: this.url_baseArquivo,
            documento: this.documento,
            pessoaUsuario: this.pessoaUsuario,
            pessoaClienteMei: this.pessoaClienteMei
        })
        console.log("PESSOA USUARIO (TELA DETALHE)");
        console.log(this.pessoaUsuario);

    }
    async salvar() {
        console.log('salvar')
        this.setState({ salvarCarregando: true });
        this.pessoaClienteMei = this.state.pessoaClienteMei;
        this.pessoaUsuario = this.state.pessoaUsuario;
        this.documento = this.state.documento;
        if (!this.pessoaClienteMei.nome || !this.state.pessoaClienteMei.documento || !this.documento.valorTotal) {
            this.setState({ salvarCarregando: false })
            return
        }

        this.documentoNovo = true;

        if (this.documento._seqDocumento) {
            this.documentoNovo = false;
        }

        //SALVANDO PESSOA CLIENTE
        this.pessoaClienteMei.seqUsuario = this.constante.seqUsuario;
        this.pessoaClienteMei.seqPessoaPerfil = this.constante.seqPessoaPerfilClienteMei;
        this.retornoPessoa = await this.pessoaService.salvar(this.pessoaClienteMei);
        this.pessoaClienteMei = this.retornoPessoa.objeto;
        console.log('CLIENTE SALVO');
        console.log(this.pessoaClienteMei);


        //SALVANDO DOCUMENTO
        this.documento = this.state.documento;
        this.documento.seqContaBancaria = this.constante.seqContaBancariaPrincipal;
        this.documento.seqUsuario = this.constante.seqUsuario;
        this.documento.seqTipoDocumento = this.constante.seqTipoDocumentoContrato;
        this.documento.seqPessoa = this.pessoaClienteMei._seqPessoa;
        this.documento.seqCondicaoPagamento = this.constante.seqCondicaoPagamentoAvista;
        this.documento.seqFormaPagamento = this.constante.seqFormaPagamentoPix;
        this.documento.seqTipoDocumentoProcesso = this.constante.seqPrimeiroProcessoDocumento;
        this.documento.seqPessoaRecebedor = this.pessoaUsuario._seqPessoa;
        this.documento.seqContaFinanceira = this.constante.seqContaFinanceiraAnalitica;
        this.documento.seqPeriodo = this.constante.seqPeriodo;
        this.documento.valorLiquido = this.documento.valorTotal;
        console.log(this.documento)
        this.retornoDocumento = await this.documentoService.salvar(this.documento);
        this.documento = this.retornoDocumento.objeto;
        console.log('DOCUMENTO SALVO')
        console.log(this.documento)


        //RELACIONAMENTO DOCUMENTO
        if (this.documentoNovo) {
            this.documentoRelacionamento = this.state.documentoRelacionamento;
            this.documentoRelacionamento.seqUsuario = this.constante.seqUsuario;
            this.documentoRelacionamento.seqDocumento = this.documento._seqDocumento;
            this.documentoRelacionamento.seqTipoRelacionamento = this.constante.seqRelacionamentoContadorResponsavel;
            this.documentoRelacionamento.seqPessoaRelacionada = this.pessoaUsuario._seqPessoa;
            this.retornoDocumentoRelacionamento = await this.documentoService.salvarRelacionamento(this.documentoRelacionamento);
            this.documentoRelacionamento = this.retornoDocumentoRelacionamento.objeto;
            console.log("RELACIONAMENTO DOCUMENTO");
            console.log(this.documentoRelacionamento);
            this.movimentoFinanceiro = await this.documentoService.gerarMovimentoFinanceiro(this.documento);
            this.movimentoFinanceiro = this.movimentoFinanceiro.objeto
            console.log("MOVIMENTO FINANCEIRO");
            console.log(this.movimentoFinanceiro);
        }



        this.setState({
            salvarCarregando: false,
            salvarCheck: true,
            documento: this.documento
        })
        setTimeout(() => this.setState({ salvarCheck: false, }), 1000);
    }

    async listarPessoaCliente(pDocumento) {
        this.listaPesquisa = [
            this.util.criarObjetoPesquisa(
                'pessoa.seq_usuario',
                this.constante.seqUsuario,
                '',
                'TEXTO'
            ),
            this.util.criarObjetoPesquisa(
                'pessoa.seq_pessoa',
                pDocumento.seqPessoa,
                'AND',
                'TEXTO'
            ),
        ]
        this.retorno = await this.pessoaService.listarComFiltro(this.listaPesquisa);
        this.pessoaClienteMei = this.retorno.objeto[0];
        console.log('PESSOA')
        console.log(this.pessoaClienteMei)
        return this.pessoaClienteMei;
    }



    async selecionarArquivo(pArquivo) {
        console.log(pArquivo.name);
        // this.tipoArquivo = String(pArquivo.name).split('.').slice(-1)[0];
        // console.log(this.tipoArquivo);
        const retorno = await this.util.pegarBase64(pArquivo);
        console.log(retorno)
        this.setState({ base64Arquivo: retorno, nomeArquivo: pArquivo.name });
    }


    async salvarArquivo() {
        this.setState({ salvarCarregando: true })
        this.documento = this.state.documento;
        this.base64Arquivo = this.state.base64Arquivo;
        this.nomeArquivo = this.state.nomeArquivo;
        this.tipoArquivo = this.state.tipoArquivo;
        this.nomeArquivo = this.nomeArquivo + '.' + this.tipoArquivo
        this.documentoArquivo = new DocumentoArquivo();
        this.documentoArquivo.seqDocumento = this.documento._seqDocumento;
        this.documentoArquivo.seqUsuario = this.constante.seqUsuario;
        this.documentoArquivo.situacao = 'ATIVO';
        this.documentoArquivo.arquivo = this.base64Arquivo;
        this.documentoArquivo.arquivoOriginal = this.nomeArquivo;
        this.documentoArquivo.seqTipoArquivo = this.constante.seqTipoArquivoGenerico;
        this.retornoArquivo = await this.documentoService.salvarArquivo(this.documentoArquivo);
        console.log("Arquivo")
        console.log(this.retornoArquivo);

        this.listarArquivo(this.documento);

        this.setState({
            salvarCarregando: false,
            base64Arquivo: '',
            nomeArquivo: '',
            tipoArquivo: '',
        })

    }

    async listarArquivo(pDocumento) {
        if (pDocumento) {
            this.documento = pDocumento
        } else {
            this.documento = this.state.documento
        }
        this.listaArquivo = await this.documentoService.listarArquivo(this.documento);
        this.listaArquivo = this.listaArquivo.objeto;
        console.log(this.listaArquivo);
        this.setState({ listaArquivo: this.listaArquivo });
    }


    async deletarArquivo() {
        this.documentoArquivo = this.state.documentoArquivo;
        await this.documentoService.deletarArquivo(this.documentoArquivo);
        this.listarArquivo();
    }

    async salvarAtendimento() {
        this.setState({ salvarCarregando: true })
        this.documento = this.state.documento;
        this.documentoAtendimento = this.state.documentoAtendimento;
        this.documentoAtendimento.seqUsuario = this.constante.seqUsuario;
        this.documentoAtendimento.seqTipoAtendimento = this.constante.seqTipoAtendimentoGenerico;
        this.documentoAtendimento.seqDocumento = this.documento._seqDocumento;
        this.documentoAtendimento.situacao = 'ATIVO';
        this.retorno = await this.documentoService.salvarAtendimento(this.documentoAtendimento);
        console.log(this.retorno);
        this.setState({
            salvarCarregando: false,
            documentoAtendimento: new DocumentoAtendimento()
        })
        this.listarDocumentoAtendimento(this.documento);
    }

    async listarDocumentoAtendimento(pDocumento) {
        if (pDocumento) {
            this.documento = pDocumento;
        } else {
            this.documento = this.state.documento;
        }

        this.retorno = await this.documentoService.listarAtendimento(this.documento);
        this.listaDocumentoAtendimento = this.retorno.objeto
        console.log(this.listaDocumentoAtendimento);
        this.setState({
            listaDocumentoAtendimento: this.listaDocumentoAtendimento
        })
    }

    selecionarDocumentoAtendimento(e) {
        console.log(e)
        this.setState({ documentoAtendimento: e });
    }

    async deletarDocumentoAtendimento() {
        this.documentoAtendimento = this.state.documentoAtendimento;
        await this.documentoService.deletarAtendimento(this.documentoAtendimento);
        console.log("ATENDIMENTO DELETADO")
        this.setState({ documentoAtendimento: new DocumentoAtendimento() });
        this.listarDocumentoAtendimento();
    }

    async deletarDocumento() {
        console.log('DOCUMENTO DELETADO')
        this.setState({ deletarCarregando: true });
        this.documento = this.state.documento;
        this.documento.seqTipoDocumentoProcesso = this.constante.seqProcessoDocumentoDeletado;
        this.retorno = await this.documentoService.salvar(this.documento);
        setTimeout(() => this.setState({ deletarCarregando: false, }), 2000);
        this.voltar();
    }

    voltar() {
        this.setState({ pessoaClienteMei: new Pessoa() });
        this.props.setState({ tela: 1, documento: new Documento() });
        this.props.listarDocumento();
    }

    async validarInputsContrato() {
        this.inputsContratoError = this.state.inputsContratoError;
        this.listaChaveInputsError = Object.keys(this.inputsContratoError);

        for (let i = 0; i < this.listaChaveInputsError.length; i++) {
            this.inputsContratoError[this.listaChaveInputsError[i]] = false;
        }

        if (!this.state.pessoaClienteMei.apelido) {
            this.inputsContratoError.nomeCliente = true;
        }

        if (!this.state.pessoaClienteMei.documento) {
            this.inputsContratoError.cnpjCliente = true;
        }

        this.retorno = await this.util.validarDocumento(this.state.pessoaClienteMei.documento);

        if (!this.retorno) {
            this.inputsContratoError.cnpjCliente = true;
        }
        if (!this.state.pessoaClienteMei.emailPlataformaLirida) {
            this.inputsContratoError.emailCliente = true;
        }
        if (!this.state.pessoaClienteMei.telefonePlataformaLirida) {
            this.inputsContratoError.telefoneCliente = true;
        }
        if (!this.state.pessoaClienteMei.nome) {
            this.inputsContratoError.razaoSocial = true;
        }
        if (!this.state.pessoaClienteMei.tags0) {
            this.inputsContratoError.cpfCliente = true;
        }
        if (!this.state.pessoaClienteMei.tags3) {
            this.inputsContratoError.inscricaoMunicipal = true;
        }
        if (!this.state.documento.dataProximaRecorrencia) {
            this.inputsContratoError.dataProximaCobranca = true;
        }
        if (!this.state.documento.valorTotal) {
            this.inputsContratoError.valor = true;
        }
        this.inputsOk = true;

        for (let i = 0; i < this.listaChaveInputsError.length; i++) {
            if (this.inputsContratoError[this.listaChaveInputsError[i]]) {
                this.inputsOk = false
            }
        }
        this.setState({ inputsContratoError: this.inputsContratoError })
        console.log(this.inputsOk)
        return this.inputsOk
    }

    render() {
        return <div>
            {/* NAVEGAÇÃO */}

            <Dialog header="Alerta" visible={this.state.vModalDeletar} style={{ width: 400 }} onHide={() => this.setState({ vModalDeletar: false, })}>
                <div style={{ display: "flex", flexDirection: 'column', gap: 20 }}>
                    <p>Deseja realmente deletar?</p>
                    <div style={{ display: 'flex', gap: 10, justifyContent: "right" }}>
                        <Button label="Sim" onClick={() => {
                            this.deletarDocumento();
                            this.setState({ vModalDeletar: false, });
                        }} />
                        <Button label="Não" onClick={() => this.setState({ vModalDeletar: false, })} />
                    </div>
                </div>
            </Dialog>

            <Dialog header="Alerta" visible={this.state.vModalDeletarArquivo} style={{ width: 400 }} onHide={() => this.setState({ vModalDeletarArquivo: false, })}>
                <div style={{ display: "flex", flexDirection: 'column', gap: 20 }}>
                    <p>Deseja realmente deletar?</p>
                    <div style={{ display: 'flex', gap: 10, justifyContent: "right" }}>
                        <Button label="Sim" onClick={() => {
                            this.deletarArquivo();
                            this.setState({ vModalDeletarArquivo: false, });
                        }} />
                        <Button label="Não" onClick={() => this.setState({ vModalDeletarArquivo: false, })} />
                    </div>
                </div>
            </Dialog>

            <Dialog header="Alerta" visible={this.state.vModalDeletarAtendimento} style={{ width: 400 }} onHide={() => this.setState({ vModalDeletarAtendimento: false, })}>
                <div style={{ display: "flex", flexDirection: 'column', gap: 20 }}>
                    <p>Deseja realmente deletar?</p>
                    <div style={{ display: 'flex', gap: 10, justifyContent: "right" }}>
                        <Button label="Sim" onClick={() => {
                            this.deletarDocumentoAtendimento();
                            this.setState({ vModalDeletarAtendimento: false, });
                        }} />
                        <Button label="Não" onClick={() => this.setState({ vModalDeletarAtendimento: false, })} />
                    </div>
                </div>
            </Dialog>


            {/* TELA */}
            {/* <Header /> */}

            <main style={{ width: '95%', margin: '20px auto' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', border: '1px solid lightgray', padding: 10, borderRadius: 3 }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 20, margin: '20px 0' }}>
                        <Button label={<i className="pi pi-arrow-left"></i>} className="p-button-raised"
                            onClick={() => { this.voltar(); }} />
                        <div>
                            <h1 style={{ fontSize: 22 }}>Detalhe contrato</h1>
                            <h2 style={{ fontSize: 20, color: 'gray' }}>Novo contrato</h2>
                        </div>
                    </div>

                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 10, }}>
                        {this.state.salvarCheck ?
                            <Button label="Salvo" icon={<i className="pi pi-check"></i>} className="p-button-success" />
                            :
                            <Button loading={this.state.salvarCarregando} label="Salvar" className="p-button-raised" onClick={async () => {
                                this.retorno = await this.validarInputsContrato();
                                console.log(this.retorno);
                                if (this.retorno) this.salvar();
                            }} />
                        }
                        {this.state.documento._seqDocumento && <Button loading={this.state.deletarCarregando}
                            label="Deletar"
                            className="p-button-danger" onClick={() => this.setState({ vModalDeletar: true, })} />}
                    </div>
                </div>

                <div style={{ border: '1px solid lightgray', borderRadius: 3, marginTop: 20, padding: 10 }}>
                    <h2 style={{ fontSize: 22.5 }}>Informações do cliente (Mei)</h2>
                    <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr", gap: 20, marginTop: 20 }}>
                        <div>
                            <label>Nome</label><br />
                            <InputText
                                onChange={(pTexto) => this.setState({
                                    pessoaClienteMei: {
                                        ...this.state.pessoaClienteMei, apelido: pTexto.target.value
                                    }
                                })}
                                value={this.state.pessoaClienteMei.apelido || ''}
                                style={{ width: '100%', borderColor: this.state.inputsContratoError.nomeCliente && 'var(--red-600)' }} />
                            {this.state.inputsContratoError.nomeCliente && <small style={{ color: 'var(--red-600)' }}>O campo nome é obrigatório</small>}
                        </div>

                        <div>
                            <label>CPF</label><br />
                            <InputText
                                onChange={(pTexto) => this.setState({
                                    pessoaClienteMei: {
                                        ...this.state.pessoaClienteMei, tags0: this.util.formatarCPF(pTexto.target.value)
                                    }
                                })}
                                value={this.state.pessoaClienteMei.tags0 || ''}
                                style={{ width: '100%', borderColor: this.state.inputsContratoError.cpfCliente && 'var(--red-600)' }} />
                            {this.state.inputsContratoError.cpfCliente && <small style={{ color: 'var(--red-600)' }}>O campo cpf é obrigatório</small>}
                        </div>


                        <div>
                            <label>Razão social</label><br />
                            <InputText
                                onChange={(pTexto) => this.setState({
                                    pessoaClienteMei: {
                                        ...this.state.pessoaClienteMei, nome: pTexto.target.value
                                    }
                                })}
                                value={this.state.pessoaClienteMei.nome}
                                style={{ width: '100%', borderColor: this.state.inputsContratoError.razaoSocial && 'var(--red-600)' }} />
                            {this.state.inputsContratoError.razaoSocial && <small style={{ color: 'var(--red-600)' }}>O campo razão social é obrigatório</small>}
                        </div>

                        <div>
                            <label>CNPJ</label><br />
                            <InputText
                                onChange={(pTexto) => this.setState({
                                    pessoaClienteMei: {
                                        ...this.state.pessoaClienteMei, documento: this.util.formatarCNPJ(pTexto.target.value)
                                    }
                                })}
                                value={this.state.pessoaClienteMei.documento || ''}
                                style={{ width: '100%', borderColor: this.state.inputsContratoError.cnpjCliente && 'var(--red-600)' }} />
                            {this.state.inputsContratoError.cnpjCliente && <small style={{ color: 'var(--red-600)' }}>O campo cnpj é obrigatório</small>}
                        </div>



                        <div>
                            <label>Inscrição municipal</label><br />
                            <InputText
                                onChange={(pTexto) => this.setState({
                                    pessoaClienteMei: {
                                        ...this.state.pessoaClienteMei, tags3: pTexto.target.value
                                    }
                                })}
                                value={this.state.pessoaClienteMei.tags3 || ''}
                                style={{ width: '100%', borderColor: this.state.inputsContratoError.inscricaoMunicipal && 'var(--red-600)' }} />
                            {this.state.inputsContratoError.inscricaoMunicipal && <small style={{ color: 'var(--red-600)' }}>O campo inscrição municipal é obrigatório</small>}
                        </div>


                        <div>
                            <label>E-mail</label><br />
                            <InputText
                                onChange={(pTexto) => this.setState({
                                    pessoaClienteMei: {
                                        ...this.state.pessoaClienteMei, emailPlataformaLirida: pTexto.target.value
                                    }
                                })}
                                value={this.state.pessoaClienteMei.emailPlataformaLirida || ''}
                                style={{ width: '100%', borderColor: this.state.inputsContratoError.emailCliente && 'var(--red-600)' }} />
                            {this.state.inputsContratoError.emailCliente && <small style={{ color: 'var(--red-600)' }}>O campo e-mail é obrigatório</small>}
                        </div>

                        <div>
                            <label>Telefone</label><br />
                            <InputText
                                onChange={(pTexto) => this.setState({
                                    pessoaClienteMei: {
                                        ...this.state.pessoaClienteMei, telefonePlataformaLirida: this.util.formatarTelefone(pTexto.target.value)
                                    }
                                })}
                                value={this.state.pessoaClienteMei.telefonePlataformaLirida || ''}
                                style={{ width: '100%', borderColor: this.state.inputsContratoError.telefoneCliente && 'var(--red-600)' }} />
                            {this.state.inputsContratoError.telefoneCliente && <small style={{ color: 'var(--red-600)' }}>O campo telefone é obrigatório</small>}
                        </div>

                    </div>
                </div>





                <div style={{ border: '1px solid lightgray', borderRadius: 3, marginTop: 20, padding: 10 }}>
                    <h2 style={{ fontSize: 22.5 }}>Informações do contrato</h2>
                    <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr 1fr", gap: 20, marginTop: 20 }}>
                        <div>
                            <label>Data cadastro</label><br />
                            <Calendar
                                showButtonBar
                                value={this.util.formatarDataCalendar(this.state.documento.dataCadastro)}
                                disabled style={{ width: '100%', }} dateFormat="dd/mm/yy" placeholder="dd/mm/yyyy" />
                        </div>

                        <div>
                            <label>Data validade</label><br />
                            <Calendar
                                showButtonBar
                                onChange={pTexto => this.setState({
                                    documento: {
                                        ...this.state.documento, dataValidade: this.util.formatarDataBanco(pTexto.target.value)
                                    }
                                })}
                                value={this.util.formatarDataCalendar(this.state.documento.dataValidade)}
                                style={{ width: '100%', }} dateFormat="dd/mm/yy" placeholder="dd/mm/yyyy" />
                        </div>

                        <div>
                            <label>Data próxima cobrança</label><br />
                            <Calendar
                                onChange={pTexto => this.setState({
                                    documento: {
                                        ...this.state.documento, dataProximaRecorrencia: this.util.formatarDataBanco(pTexto.target.value)
                                    }
                                })}
                                showButtonBar
                                value={this.util.formatarDataCalendar(this.state.documento.dataProximaRecorrencia)}
                                style={{ width: '100%', }} dateFormat="dd/mm/yy" placeholder="dd/mm/yyyy" />
                        </div>

                        <div>
                            <label>Data inicio</label><br />
                            <Calendar
                                showButtonBar
                                onChange={pTexto => this.setState({
                                    documento: {
                                        ...this.state.documento, dataInicio: this.util.formatarDataBanco(pTexto.target.value)
                                    }
                                })}
                                value={this.util.formatarDataCalendar(this.state.documento.dataInicio)}
                                style={{ width: '100%', }} dateFormat="dd/mm/yy" placeholder="dd/mm/yyyy" />
                        </div>

                        <div>
                            <label>Data final</label><br />
                            <Calendar
                                showButtonBar
                                onChange={pTexto => this.setState({
                                    documento: {
                                        ...this.state.documento, dataFinal: this.util.formatarDataBanco(pTexto.target.value)
                                    }
                                })}
                                value={this.util.formatarDataCalendar(this.state.documento.dataFinal)}
                                style={{ width: '100%', }} dateFormat="dd/mm/yy" placeholder="dd/mm/yyyy" />
                        </div>


                        <div>
                            <label>Valor</label><br />
                            <InputNumber
                                onValueChange={pTexto => this.setState({
                                    documento: {
                                        ...this.state.documento, valorTotal: pTexto.target.value
                                    }
                                })}
                                mode="currency"
                                currency="BRL"
                                locale="pt-BR"
                                value={this.state.documento.valorTotal}
                                style={{ width: '100%' }} />
                        </div>

                        <div>
                            <label>Observação</label><br />
                            <InputText
                                onChange={pTexto => this.setState({
                                    documento: {
                                        ...this.state.documento, observacao: pTexto.target.value
                                    }
                                })}
                                value={this.state.documento.observacao}
                                style={{ width: '100%' }} />
                        </div>
                    </div>
                </div>

                <div style={{ border: '1px solid lightgray', padding: 10, borderRadius: 3, marginTop: 20 }}>
                    {/* <h2 style={{ fontSize: 22.5 }}>Arquivo / Atendimentos</h2> */}


                    <TabView style={{ marginTop: 10 }} >
                        <TabPanel header="Arquivos" disabled={!this.state.documento._seqDocumento}>
                            {!this.state.documento._seqDocumento ? <div>
                                <p>Nenhum contrato selecionado / salvo</p>
                            </div> : <>
                                <div style={{
                                    marginBottom: 10,
                                    display: "flex",
                                    gap: 20,
                                    flexWrap: 'wrap',
                                    alignItems: 'center'
                                }}>
                                    <div>
                                        <label>Arquivo</label><br />
                                        <InputText
                                            type="file"
                                            onChange={e => this.selecionarArquivo(e.target.files[0])} />
                                    </div>

                                    <div style={{ widht: 'auto', display: 'flex', gap: 20, alignItems: 'end' }}>
                                        {/* <div>
                                            <label>Nome arquivo</label><br />
                                            <InputText
                                                type="Text"
                                                onChange={pTexto => this.setState({ nomeArquivo: pTexto.target.value })} />

                                        </div> */}

                                        <div>
                                            <Button
                                                label="Salvar arquivo"
                                                className="p-button-raised"
                                                loading={this.state.salvarCarregando}
                                                onClick={() => this.salvarArquivo()}
                                            />
                                        </div>

                                    </div>


                                </div>
                                <DataTable
                                    selectionMode="single"
                                    showGridlines
                                    removableSort
                                    paginator
                                    responsiveLayout="scroll"
                                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                    currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords}"
                                    rows={10}
                                    rowsPerPageOptions={[10, 20, 50]}
                                    value={this.state.listaArquivo}>
                                    <Column
                                        field="arquivo"
                                        header="Arquivo"
                                        body={item => <a href={this.state.url_baseArquivo + item.arquivo} rel='noreferrer' target='_blank'
                                        >{item.arquivoOriginal}</a>} />
                                    <Column field="_seqDocumentoArquivo" header="Deletar" body={item => <div>
                                        <Button
                                            onClick={() => this.setState({ vModalDeletarArquivo: true, documentoArquivo: item })}
                                            icon="pi pi-trash"
                                            className="p-button-rounded p-button-danger"
                                            aria-label="Filter" />
                                    </div>
                                    } />
                                </DataTable>
                            </>}
                        </TabPanel>


                        <TabPanel header="Atendimento" disabled={!this.state.documento._seqDocumento}>
                            {!this.state.documento._seqDocumento ? null : <>
                                <div style={{
                                    widht: 'auto',
                                    display: 'flex',
                                    gap: 20,
                                    justifyContent: 'center',
                                    margin: '10px 0 40px 0'
                                }}>
                                    <InputTextarea
                                        rows={5}
                                        cols={50}
                                        autoResize
                                        placeholder='Descrição do atendimento'
                                        value={this.state.documentoAtendimento.descricao || ''}
                                        onChange={(pTexto) => this.setState({
                                            documentoAtendimento: {
                                                ...this.state.documentoAtendimento, descricao: pTexto.target.value
                                            }
                                        })}
                                    />
                                    <div>
                                        <Button
                                            label="Salvar"
                                            className="p-button-raised"
                                            loading={this.state.salvarCarregando}
                                            onClick={() => this.salvarAtendimento()}
                                        />
                                    </div>

                                    {this.state.documentoAtendimento._seqDocumentoAtendimento && <div><Button loading={this.state.deletarCarregando}
                                        label="Deletar"
                                        className="p-button-danger"
                                        onClick={() => this.setState({ vModalDeletarAtendimento: true, })} /></div>}
                                </div>

                                <DataTable
                                    selectionMode="single"
                                    showGridlines
                                    removableSort
                                    paginator
                                    responsiveLayout="scroll"
                                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                    currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords}"
                                    rows={10}
                                    rowsPerPageOptions={[10, 20, 50]}
                                    value={this.state.listaDocumentoAtendimento}
                                    onSelectionChange={e => this.selecionarDocumentoAtendimento(e.value)}
                                >
                                    <Column
                                        field="descricao"
                                        header="Descrição" />
                                    <Column
                                        field="dataCadastro"
                                        header="Data cadastro"
                                        sortable
                                        body={item => <p>{this.util.formatarData(item.dataCadastro)}</p>
                                        } />
                                </DataTable>
                            </>}
                        </TabPanel>

                        <TabPanel
                            header="Nota fiscal"
                        >


                            <DataTable
                                selectionMode="single"
                                showGridlines
                                removableSort
                                paginator
                                responsiveLayout="scroll"
                                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords}"
                                rows={10}
                                rowsPerPageOptions={[10, 20, 50]}
                                value={this.state.listaDocumentoAtendimento}
                                onSelectionChange={e => this.selecionarDocumentoAtendimento(e.value)}
                            >
                                <Column
                                    field="descricao"
                                    header="Descrição" />
                                <Column
                                    field="dataCadastro"
                                    header="Data cadastro"
                                    sortable
                                    body={item => <p>{this.util.formatarData(item.dataCadastro)}</p>
                                    } />
                            </DataTable>
                        </TabPanel>

                    </TabView>
                </div>
            </main>
        </div>
    }
}