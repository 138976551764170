import { Component, createRef } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Link, Navigate } from "react-router-dom";
import { Messages } from "primereact/messages";
import PessoaService from 'lirida-back-service/Servicos/Pessoa/PessoaService';
import Pessoa from 'lirida-back-service/Servicos/Pessoa/Pessoa';
import Constante from "../Util/Constante";
import Util from "../Util/Util";
import LogoEscura from '../assets/images/logoEscura.png';
import LogoBranca from '../assets/images/logoBranca.png';
import { Checkbox } from 'primereact/checkbox';
import { Captcha } from 'primereact/captcha';

export default class CadastroPg extends Component {
    msgs1 = createRef();
    constante = new Constante();
    util = new Util();
    pessoaService = new PessoaService(this.constante.token, this.constante.url_base);
    TelaEnum = {
        principal: 'principal',
        login: "login",
        sobre: 'sobre',
        funcionalidades: 'funcionalidades',
        depoimentos: 'depoimentos',
        perguntasFrequentes: 'perguntasFrequentes',
        politicaPrivacidade: "politicaPrivacidade",
        termoDeUso: "termoDeUso",
        cadastro: "cadastro",
    }
    state = {
        pessoaUsuario: new Pessoa(),

        termoDeUsoChecked: false,
        reaCaptcha: false,

        inputsCadastroError: {
            nome: false,
            email: false,
            documento: false,
            numeroCrc: false,
            termoDeUso: false,
            captcha: false,
        },
        errorInput2: {
            visivel: false,
            msg: ""
        },

        liberarAcesso: {
            login: false,
            termoDeUso: false,
        },

        carregando: false,
        tela: this.TelaEnum.cadastro,
    }

    async salvar() {
        this.setState({ carregando: true });
        const errorInput2 = this.state.errorInput2;

        let pessoaUsuario = this.state.pessoaUsuario;
        let retornoAdm = await this.pessoaService.buscarPorSeqPessoa(this.constante.seqPessoaAdministrador);
        let pessoaAdm = retornoAdm.objeto;

        pessoaUsuario.seqUsuario = this.constante.seqUsuario;
        pessoaUsuario.tipoDocumento = 'CNPJ';
        pessoaUsuario.seqPessoaPerfil = this.constante.seqPessoaPerfilContador;
        pessoaUsuario.taxaBoleto = pessoaAdm.taxaBoleto
        pessoaUsuario.taxaCredito = pessoaAdm.taxaCredito;
        pessoaUsuario.taxaPix = pessoaAdm.taxaPix;
        pessoaUsuario.tipoTaxaBoleto = pessoaAdm.tipoTaxaBoleto;
        pessoaUsuario.tipoTaxaCredito = pessoaAdm.tipoTaxaCredito;
        pessoaUsuario.tipoTaxaPix = pessoaAdm.tipoTaxaPix;

        let listaPesquisa = [
            this.util.criarObjetoPesquisa(
                'pessoa.seq_usuario',
                this.constante.seqUsuario,
                '',
                'TEXTO'
            ),
            this.util.criarObjetoPesquisa(
                'pessoa.email_plataforma_lirida',
                pessoaUsuario.emailPlataformaLirida,
                'AND',
                'TEXTO'
            )
        ]

        let retorno = await this.pessoaService.listarComFiltro(listaPesquisa);
        let lista = retorno.objeto
        for (let i = 0; i < lista.length; i++) {
            if (lista[i]?.emailPlataformaLirida === pessoaUsuario.emailPlataformaLirida) {
                errorInput2.visivel = true;
                errorInput2.msg = "E-mail em uso";
                this.setState({ errorInput2: errorInput2, carregando: false, });
                return
            }
        }

        retorno = await this.pessoaService.salvar(pessoaUsuario);
        pessoaUsuario = retorno.objeto;
        this.util.enviarEmailSenha(this.constante.seqEmailCadastro, pessoaUsuario?.emailPlataformaLirida, pessoaUsuario?.nome, pessoaUsuario?.senha);

        this.setState({
            pessoaUsuario: new Pessoa(),
            carregando: false,
        });
        this.msgs1.current.show([{ severity: 'success', summary: 'Cadastrado com sucesso. Seu acesso será enviado para o seu e-mail.', }]);
        window.scrollTo({ top: 0, behavior: 'smooth' })
        setTimeout(() => { this.navegar('login') }, 3500);
    }

    navegar(pTela) {
        const liberarAcesso = this.state.liberarAcesso;
        liberarAcesso[pTela] = true;
        this.setState({ liberarAcesso: liberarAcesso });
    }

    validarInputs() {
        const inputsCadastroError = this.state.inputsCadastroError;
        const listaChaveinputsCadastroError = Object.keys(inputsCadastroError);

        for (let i = 0; i < listaChaveinputsCadastroError.length; i++) {
            inputsCadastroError[listaChaveinputsCadastroError[i]] = false;
        }

        if (!this.state.pessoaUsuario.nome) {
            inputsCadastroError.nome = true;
        }

        if (!this.state.pessoaUsuario.emailPlataformaLirida) {
            inputsCadastroError.email = true;
        }
        if (!this.state.pessoaUsuario.documento) {
            inputsCadastroError.documento = true;
        }

        if (this.state.pessoaUsuario.documento && this.state.pessoaUsuario.documento?.length !== 18) {
            inputsCadastroError.documento = true;
        }

        if (!this.state.pessoaUsuario.tags1) {
            inputsCadastroError.numeroCrc = true;
        }

        // if (!this.state.reaCaptcha) {
        //     inputsCadastroError.captcha = true;
        // }

        if (!this.state.termoDeUsoChecked) {
            inputsCadastroError.termoDeUso = true;
        }

        let inputOK = true;

        for (let i = 0; i < listaChaveinputsCadastroError.length; i++) {
            if (inputsCadastroError[listaChaveinputsCadastroError[i]]) {
                inputOK = false;
            }
        }

        this.setState({ inputsCadastroError: inputsCadastroError });

        return inputOK;
    }

    render() {
        return <div>
            {/* NAVEGAÇÃO */}
            {this.state.liberarAcesso.login && <Navigate to="/login" />}

            {/* NAVEGAÇÃO */}
            {this.state.tela === this.TelaEnum.principal && <Navigate to="/" />}
            {this.state.tela === this.TelaEnum.login && <Navigate to="/login" />}
            {this.state.tela === this.TelaEnum.sobre && <Navigate to="/#sobre" />}
            {this.state.tela === this.TelaEnum.funcionalidades && <Navigate to="/#funcionalidades" />}
            {this.state.tela === this.TelaEnum.depoimentos && <Navigate to="/#depoimentos" />}
            {this.state.tela === this.TelaEnum.perguntasFrequentes && <Navigate to="/#perguntas-frequentes" />}
            {this.state.tela === this.TelaEnum.termoDeUso && <Navigate to="/termoDeUso" />}
            {this.state.tela === this.TelaEnum.politicaPrivacidade && <Navigate to="/politicaDePrivacidade" />}
            {/* TELA */}
            {/* <header style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', backgroundColor: 'var(--primary-color)', padding: 10 }}>
                <div style={{ width: 110, height: 60 }}>
                    <img
                        style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                        src={LogoBranca}
                        alt="Logo ContadorFlex na cor branca." />
                </div>
            </header> */}


            <header className="h-header" style={{ position: 'static' }} >
                <div className="h-header-container">

                    <img src={LogoBranca} alt="logo" style={{ cursor: 'pointer' }} onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })} />

                    <nav>
                        {/* <a href="#sobre" onClick={() => this.setState({ tela: this.TelaEnum.sobre })}>Sobre</a>
                        <a href="#funcionalidades" onClick={() => this.setState({ tela: this.TelaEnum.funcionalidades })}>Funcionalidades</a>
                        <a href="#depoimentos" onClick={() => this.setState({ tela: this.TelaEnum.depoimentos })}>Depoimentos</a>
                        <a href="#perguntas-frequentes" onClick={() => this.setState({ tela: this.TelaEnum.perguntasFrequentes })}>Perguntas frequentes</a>

                        <a onClick={() => this.setState({ tela: this.TelaEnum.login })}>Login</a>
                        <button onClick={() => this.setState({ sidebarVisivel: true })}>
                            <i className="pi pi-bars"></i>
                        </button> */}
                    </nav>
                </div>
            </header>

            <main style={{
                width: '100%',
                height: '100%',
                margin: 'auto',
                display: 'flex',
                justifyContent: 'center',
            }}>
                <section style={{
                    maxWidth: '1280px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    padding: '10px',
                }}>
                    <div style={{ width: '40%', height: '60%' }}>
                        <img
                            style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                            src={LogoEscura}
                            alt="Logo ContadorFlex na cor escura." />

                    </div>

                    <form
                        onSubmit={e => {
                            e.preventDefault();
                            const retorno = this.validarInputs();
                            if (retorno) this.salvar();
                        }}
                        style={{ width: '50%', display: 'flex', flexDirection: 'column', gap: 20 }}>
                        <Messages ref={this.msgs1} />

                        <h1>Cadastre-se</h1>

                        <div>
                            <label>Nome da empresa contábil</label><br />
                            <InputText
                                onChange={(pTexto) => this.setState({
                                    pessoaUsuario: {
                                        ...this.state.pessoaUsuario, nome: pTexto.target.value
                                    }
                                })}
                                value={this.state.pessoaUsuario.nome || ''}
                                type='text'
                                style={{ width: '100%', borderColor: this.state.inputsCadastroError.nome && 'var(--red-600)' }} />
                            {this.state.inputsCadastroError.nome && <small style={{ color: 'var(--red-600)' }}>Nome inválido</small>}
                        </div>


                        <div>
                            <label>E-mail</label><br />
                            <InputText
                                onChange={(pTexto) => this.setState({
                                    pessoaUsuario: {
                                        ...this.state.pessoaUsuario, emailPlataformaLirida: pTexto.target.value
                                    }
                                })}
                                value={this.state.pessoaUsuario.emailPlataformaLirida || ''}
                                type='email'
                                style={{ width: '100%', borderColor: this.state.inputsCadastroError.email || this.state.errorInput2.visivel ? 'var(--red-600)' : null }} />
                            {this.state.inputsCadastroError.email || this.state.errorInput2.visivel ? <small style={{ color: 'var(--red-600)' }}>{this.state.errorInput2.visivel ? this.state.errorInput2.msg : 'E-mail inválido'}</small> : null}
                        </div>

                        <div>
                            <label>CNPJ</label><br />
                            <InputText
                                onChange={(pTexto) => this.setState({
                                    pessoaUsuario: {
                                        ...this.state.pessoaUsuario, documento: this.util.formatarCNPJ(pTexto.target.value)
                                    }
                                })
                                }
                                type='text'
                                value={this.state.pessoaUsuario.documento || ""}
                                style={{ width: '100%', borderColor: this.state.inputsCadastroError.documento && 'var(--red-600)' }} />
                            {this.state.inputsCadastroError.documento && <small style={{ color: 'var(--red-600)' }}>Documento inválido</small>}
                        </div>


                        <div>
                            <label>Número do CRC</label><br />
                            <InputText
                                onChange={(pTexto) => this.setState({
                                    pessoaUsuario: {
                                        ...this.state.pessoaUsuario, tags1: pTexto.target.value
                                    }
                                })}
                                value={this.state.pessoaUsuario.tags1 || ''}
                                style={{ width: '100%', borderColor: this.state.inputsCadastroError.numeroCrc && 'var(--red-600)' }} />
                            {this.state.inputsCadastroError.numeroCrc && <small style={{ color: 'var(--red-600)' }}>Número CRC inválido</small>}
                        </div>

                        <div style={{ display: 'flex', alignItems: 'start', gap: 20 }}>

                            <div style={{ display: "flex", flexDirection: 'column', alignItems: 'center' }}>
                                <Captcha
                                    language='pt-BR'
                                    siteKey={this.constante.chaveSite}
                                    onResponse={() => this.setState({ reaCaptcha: true })} />
                                {this.state.inputsCadastroError.captcha && <small style={{ color: 'var(--red-600)' }}>reCaptcha inválido</small>}
                            </div>

                            <div style={{ display: 'flex', gap: 10, alignItems: 'center', justifyContent: 'right' }}>
                                <Checkbox
                                    onChange={e => this.setState({ termoDeUsoChecked: e.checked })}
                                    checked={this.state.termoDeUsoChecked}
                                    style={{ outline: this.state.inputsCadastroError.termoDeUso && '1px solid red', borderRadius: 5 }} />
                                <div>
                                    <label>Li e aceito os <Link
                                        style={{ fontWeight: 'bold', textDecoration: 'underline', color: 'black' }}
                                        to='/termoDeUso'
                                        target='_blank'>
                                        termos de uso
                                    </Link></label><br />
                                    {this.state.inputsCadastroError.termoDeUso && <small style={{ color: 'var(--red-600)', marginLeft: 'auto' }}>Termo de uso não lido</small>}
                                </div>
                            </div>

                        </div>


                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Button label="Voltar para login" type="button" className="p-button-link" style={{ padding: 0 }}
                                onClick={() => {
                                    window.scrollTo({ top: 0, behavior: 'smooth' })
                                    this.setState({ tela: this.TelaEnum.login })
                                }} />
                            <Button label="Enviar" type="submit" loading={this.state.carregando} />
                        </div>
                    </form>
                </section>
            </main>

            <footer className="h-footer-container">
                <section >
                    <div>
                        <a
                            href="#sobre"
                            onClick={() => this.setState({ tela: this.TelaEnum.sobre })}>
                            Quem somos
                        </a>

                        <a
                            href="#funcionalidades"
                            onClick={() => this.setState({ tela: this.TelaEnum.funcionalidades })}>
                            Funcionalidade
                        </a>

                        <a
                            href="#depoimentos"
                            onClick={() => this.setState({ tela: this.TelaEnum.depoimentos })}>
                            Depoimentos
                        </a>

                        <a
                            href="#perguntas-frequentes"
                            onClick={() => this.setState({ tela: this.TelaEnum.perguntasFrequentes })}>
                            Perguntas frequentes
                        </a>

                        <a
                            onClick={() => {
                                window.scrollTo({ top: 0, behavior: 'smooth' });
                            }}>
                            login
                        </a>
                    </div>

                    <div>
                        <a
                            onClick={() => {
                                window.scrollTo({ top: 0, behavior: 'smooth' });
                                this.setState({ tela: this.TelaEnum.politicaPrivacidade });
                            }}>
                            Política de privacidade
                        </a>

                        <a
                            href=''
                            onClick={() => {
                                this.setState({ tela: this.TelaEnum.termoDeUso });
                            }}>
                            Termo de uso
                        </a>
                    </div>

                    <div>
                        <a
                            href="https://www.facebook.com/profile.php?id=100085863546876" target='_blank'>
                            Facebook
                        </a>

                        <a
                            href="https://www.instagram.com/contadorflex/" target='_blank'>
                            Instagram
                        </a>
                    </div>
                </section>
                <img src={LogoBranca} alt="logo rodapé" />
            </footer>
        </div>
    }
}