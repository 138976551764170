import { Component, createRef } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Messages } from "primereact/messages";
import PessoaService from 'lirida-back-service/Servicos/Pessoa/PessoaService';
import Pessoa from 'lirida-back-service/Servicos/Pessoa/Pessoa';
import Constante from '../Util/Constante';
import Util from "../Util/Util";
import LogoEscura from '../assets/images/logoEscura.png';
import LogoBranca from '../assets/images/logoBranca.png';
import Pesquisa from "lirida-back-service/Servicos/Pesquisa/Pesquisa";

export default class LoginPg extends Component {
    constante = new Constante();
    util = new Util();
    pessoaService = new PessoaService(this.constante.token, this.constante.url_base);

    msgs1 = createRef();

    state = {
        email: '',
        senha: '',
        inputError: {
            email: false,
            senha: false,
        },
        carregando: false,
    }

    async componentDidMount() {
        let listaPesquisa = [];
        let pesquisa = new Pesquisa();
        pesquisa.campo = 'pessoa.seq_usuario';
        pesquisa.conteudo = this.constante.seqUsuario;
        pesquisa.operacao = '';
        pesquisa.tipo = 'TEXTO';
        listaPesquisa.push(pesquisa);

        pesquisa = new Pesquisa();
        pesquisa.campo = 'pessoa.seq_pessoa_perfil';
        pesquisa.conteudo = this.constante.seqPessoaPerfilAdm;
        pesquisa.operacao = 'AND';
        pesquisa.tipo = 'TEXTO';
        listaPesquisa.push(pesquisa);

        let retorno = await this.pessoaService.listarComFiltro(listaPesquisa);
        console.log(retorno)
    }

    async validarAcesso() {
        this.setState({ carregando: true });
        this.msgs1.current.clear();

        let listaPesquisa = [];
        let pessoa = new Pessoa();
        let inputError = this.state.inputError;
        let inputsOK = true;


        Object.keys(inputError).forEach(pKey => {
            inputError[pKey] = false;
        });

        if (!this.state.email) {
            inputError.email = true;
        }

        if (!this.state.senha) {
            inputError.senha = true;
        }

        if (!this.util.validarEmail(this.state.email)) {
            inputError.email = true;
        }

        Object.keys(inputError).forEach(pKey => {
            if (inputError[pKey]) {
                inputsOK = false;
            }
        });

        this.setState({ inputError: inputError });

        if (!inputsOK) {
            return;
        }

        let pesquisa = new Pesquisa();
        pesquisa.campo = 'pessoa.seq_usuario';
        pesquisa.conteudo = this.constante.seqUsuario;
        pesquisa.operacao = '';
        pesquisa.tipo = 'TEXTO';
        listaPesquisa.push(pesquisa);

        pesquisa = new Pesquisa();
        pesquisa.campo = 'pessoa.email_plataforma_lirida';
        pesquisa.conteudo = this.state.email;
        pesquisa.operacao = 'AND';
        pesquisa.tipo = 'TEXTO';
        listaPesquisa.push(pesquisa);


        pesquisa = new Pesquisa();
        pesquisa.campo = " AND pessoa.seq_pessoa_perfil in ('" + this.constante.seqPessoaPerfilAdm + "', '" + this.constante.seqPessoaPerfilContador + "')";
        pesquisa.conteudo = this.constante.seqPessoaPerfilContador;
        pesquisa.operacao = 'AND';
        pesquisa.tipo = 'MANUAL';
        listaPesquisa.push(pesquisa);

        let retorno = await this.pessoaService.listarComFiltro(listaPesquisa);
        pessoa = retorno.objeto[0];

        if (!pessoa) {
            this.setState({ inputError: inputError, carregando: false, });
            this.msgs1.current?.show([
                { severity: 'error', summary: 'Credênciais de acesso inválidas!', sticky: true },
            ]);
            return
        }

        if (this.state.senha !== pessoa?.senha) {
            inputError.senha = true
            this.setState({ inputError: inputError, carregando: false, })
            return;
        }
        this.setState({ inputError: inputError, carregando: false });

        if (pessoa.seqPessoaPerfil === this.constante.seqPessoaPerfilContador) {
            this.util.salvarLocalStorage("PESSOA_USUARIO", pessoa);
            window.location.href = '/cliente';
        } else if (pessoa.seqPessoaPerfil === this.constante.seqPessoaPerfilAdm) {
            this.util.salvarLocalStorage("PESSOA_USUARIO", pessoa);
            window.location.href = '/contador';
        }
    }

    render() {
        return <div style={{
            width: '100%',
            height: '100%',
        }}>

            <header className="h-header" style={{ position: 'static' }} >
                <div className="h-header-container">

                    <img src={LogoBranca} alt="logo" style={{ cursor: 'pointer' }} onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })} />
                </div>
            </header>

            <main style={{
                width: '100%',
                height: '100%',
                margin: 'auto',
                display: 'flex',
                justifyContent: 'center',
            }}>


                <section style={{
                    maxWidth: '1280px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    padding: '10px',
                }}>
                    <div style={{ width: '40%', height: '60%' }}>
                        <img
                            style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                            src={LogoEscura}
                            alt="Logo ContadorFlex na cor escura." />

                    </div>

                    <form onSubmit={e => { e.preventDefault(); this.validarAcesso(e.target) }} style={{ width: '50%', display: 'flex', flexDirection: 'column', gap: 20 }}>
                        <Messages ref={this.msgs1} />

                        <h1>Login</h1>

                        <div>
                            <label>E-mail</label><br />
                            <InputText
                                onChange={pEvento => this.setState({ email: pEvento.target.value })}
                                type='email'
                                style={{ width: '100%', borderColor: this.state.inputError.email && 'var(--red-600)' }} />
                            {this.state.inputError.email && <small style={{ color: 'var(--red-600)' }}>E-mail inválido</small>}
                        </div>
                        <div>
                            <label>Senha</label><br />
                            <InputText
                                onChange={pEvento => this.setState({ senha: pEvento.target.value })}
                                type='password'
                                style={{ width: '100%', borderColor: this.state.inputError.senha && 'var(--red-600)' }} />
                            {this.state.inputError.senha && <small style={{ color: 'var(--red-600)' }}>Senha inválida</small>}
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Button label="Recuperar senha" type="button" className="p-button-link" style={{ padding: 0 }}
                                onClick={() => window.location.href = 'recuperar_senha'} />
                            <Button label="Cadastre-se" type="button" className="p-button-link" style={{ padding: 0 }}
                                onClick={() => window.location.href = '/cadastro'} />
                            < Button label="Entrar" type="submit" loading={this.state.carregando} onClick={() => this.validarAcesso()} />
                        </div>
                    </form>

                </section>
            </main>

            <footer className="h-footer-container">
                <section >
                    <div>
                        <a
                            href="#sobre"
                            onClick={() => window.location.href = '/'}>
                            Quem somos
                        </a>

                        <a
                            href="#funcionalidades"
                            onClick={() => window.location.href = '/'}>
                            Funcionalidade
                        </a>

                        <a
                            href="#depoimentos"
                            onClick={() => window.location.href = '/'}>
                            Depoimentos
                        </a>

                        <a
                            href="/">
                            Perguntas frequentes
                        </a>

                        <a
                            onClick={() => {
                                window.scrollTo({ top: 0, behavior: 'smooth' });
                            }}>
                            login
                        </a>
                    </div>

                    <div>
                        <a
                            onClick={() => { window.location.href = '/politicaDePrivacidade' }}>
                            Política de privacidade
                        </a>

                        <a
                            onClick={() => { window.location.href = '/termoDeUso' }}>
                            Termo de uso
                        </a>
                    </div>

                    <div>
                        <a
                            href="https://www.facebook.com/profile.php?id=100085863546876" target='_blank'>
                            Facebook
                        </a>

                        <a
                            href="https://www.instagram.com/contadorflex/" target='_blank'>
                            Instagram
                        </a>
                    </div>
                </section>
                <img src={LogoBranca} alt="logo rodapé" />
            </footer>
        </div >
    }
}