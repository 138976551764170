import { Component, createRef } from "react";
import { Button } from 'primereact/button';
import { Avatar } from 'primereact/avatar';
import Util from "../Util/Util";
import LogoBranca from '../assets/images/logoBranca.png'
import Pessoa from "lirida-back-service/Servicos/Pessoa/Pessoa";
import { Toast } from "primereact/toast";
import { Steps } from "primereact/steps";
import { Dialog } from "primereact/dialog";
import { Messages } from "primereact/messages";
import FormDadosBancarios from "./FormDadosBancarios";
import FormDadosPessoais from "./FormDadosPessoais";
import FormEndereco from "./FormEndereco";
import Constante from "../Util/Constante";

export default class Header extends Component {
    util = new Util();
    constante = new Constante();
    mensagens = createRef();
    mensagens2 = createRef();
    toast = createRef();

    listaStep = [{ label: "Dados pessoais" }, { label: "Endereço" }, { label: "Dados bancários" }];
    state = {
        pessoaUsuario: new Pessoa(),
        urlBase: '',
        dialogVisivel: false,
        indexStep: 0,
    }

    componentDidMount() {
        this.iniciar();
    }

    async iniciar() {
        const urlBase = await this.util.buscarUrlBaseArquivo();
        const pessoaUsuario = await this.util.buscarLocalStorage("PESSOA_USUARIO");
        if (pessoaUsuario.seqPessoaPerfil === this.constante.seqPessoaPerfilContador && (!pessoaUsuario.contaS2pCodigo || !pessoaUsuario.seqEnderecoPrincipal)) {
            this.mensagens.current.show([
                { severity: 'error', summary: `É importante você manter seus dados atualizados para você receber o seu dinheiro. Clique aqui e complete seus Dados pessoais e Dados bancários!`, sticky: true, closable: false },
            ])
        }

        // if (!pessoaUsuario.tags0 && pessoaUsuario?.contaS2pCodigo) {
        //     this.mensagens2.current.show([
        //         { severity: 'warn', summary: 'Clique aqui e cadastre o seu primeiro contrato', sticky: true, closable: false },
        //     ])
        // }

        this.setState({
            urlBase: urlBase,
            pessoaUsuario: pessoaUsuario,
        })
    }

    sair() {
        this.util.removerLocalStorage("PESSOA_USUARIO");
        window.location.href = '/login'
    }

    avancarFormulario() {
        const proximoIndexStep = this.state.indexStep + 1

        if (proximoIndexStep === this.listaStep.length) {
            this.toast.current.show([
                { severity: 'success', summary: 'Você completou todos os seus dados!' },
            ]);

            this.mensagens.current.clear();

            this.setState({ dialogVisivel: false });
            this.iniciar()

        } else {
            this.setState({ indexStep: proximoIndexStep });
        }
    }


    render() {
        return <>
            {/* COMPONENTE */}
            <Dialog
                style={{ width: 600 }}
                visible={this.state.dialogVisivel}
                onHide={() => this.setState({ dialogVisivel: false })}
            >
                <div style={{ display: 'flex', flexDirection: 'column', gap: 20 }}>
                    <Steps
                        model={this.listaStep}
                        activeIndex={this.state.indexStep} />
                    {this.state.indexStep === 0 &&
                        <div style={{ display: 'flex', flexDirection: 'column', gap: 20 }}>
                            <h2>Complete seus dados pessoais</h2>
                            <FormDadosPessoais avancarFormulario={() => this.avancarFormulario()} />
                        </div>
                    }
                    {this.state.indexStep === 1 &&
                        <div style={{ display: 'flex', flexDirection: 'column', gap: 20 }}>
                            <h2>Complete os dados do seu endereço</h2>
                            <FormEndereco avancarFormulario={() => this.avancarFormulario()} />
                        </div>
                    }
                    {this.state.indexStep === 2 &&
                        <div style={{ display: 'flex', flexDirection: 'column', gap: 20 }}>
                            <h2>Complete seus dados bancários</h2>
                            <FormDadosBancarios avancarFormulario={() => this.avancarFormulario()} />
                        </div>
                    }
                </div>
            </Dialog>

            <header style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', backgroundColor: 'var(--primary-color)', padding: '10px 20px' }}>
                <img src={LogoBranca} alt="pallua" style={{ width: 80, objectFit: 'contain' }} />

                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <nav style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: 5 }}>
                        {this.state.pessoaUsuario.seqPessoaPerfil === this.constante.seqPessoaPerfilContador && <Button className="p-button-link" label="Cliente" onClick={() => window.location.href = '/cliente'} style={{ color: 'white' }} />}
                        {this.state.pessoaUsuario.seqPessoaPerfil === this.constante.seqPessoaPerfilAdm && <Button className="p-button-link" label="Contadores" onClick={() => window.location.href = "/contador"} style={{ color: 'white' }} />}
                        <Button className="p-button-link" label="Financeiro" onClick={() => window.location.href = '/financeiro'} style={{ color: 'white' }} />

                        <Button className="p-button-link" style={{ padding: 0 }} label={
                            <Avatar label={String(this.state.pessoaUsuario.nome).charAt(0)} image={(this.state.urlBase && this.state.pessoaUsuario.foto) ? this.state.urlBase + this.state.pessoaUsuario.foto : null} shape="circle" style={{ objectFit: 'contain' }}></Avatar>
                        } onClick={() => window.location.href = '/perfil'} />
                        <Button className="p-button-link" label="Sair" icon={<i className="pi pi-sign-out"></i>} iconPos="right" onClick={() => this.sair()} style={{ color: 'white', gap: 5 }} />
                    </nav>
                </div>
            </header>
            {!this.state.pessoaUsuario.tags2 && <Messages
                style={{ cursor: "pointer" }}
                ref={this.mensagens2}
                onClick={() => this.navegar('contrato')} />}
            <Messages style={{ cursor: "pointer" }}
                ref={this.mensagens}
                onClick={() => this.setState({ dialogVisivel: true })} />
            <Toast ref={this.toast} />
        </>
    }
}