import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';

import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

import "primereact/resources/themes/rhea/theme.css";
import 'primeicons/primeicons.css';
import 'primereact/resources/primereact.min.css';

import './index.css';

import LoginPg from './pages/LoginPg';
import DocumentoPg from './pages/DocumentoPg';
import RecuperarSenhaPg from './pages/RecuperarSenhaPg';
import DocumentoDetalhePg from './pages/DocumentoDetalhePg';
import CadastroPg from './pages/CadastroPg';
import PerfilPg from './pages/PerfilPg';
import FinanceiroPg from './pages/FinanceiroPg';
import Hotsite from './pages/Hotsite';
import TermoDeUso from './pages/Hotsite/TermoDeUso';
import PoliticaPrivacidade from './pages/Hotsite/PoliticaPrivacidade';
import PessoaPg from './pages/PessoaPg';
import PessoaDetalhePg from './pages/PessoaDetalhePg';
import PessoaContador from './pages/Adm/pessoaContador';
import PessoaContadorDetalhe from './pages/Adm/pessoaContadorDetalhe';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <Routes>
      <Route path="*" element={<Navigate to="/" />} />
      <Route path="/" element={<Hotsite />} />
      <Route path="/termoDeUso" element={<TermoDeUso />} />
      <Route path="/politicaDePrivacidade" element={<PoliticaPrivacidade />} />
      <Route path="/login" element={<LoginPg />} />
      <Route path="/recuperar_senha" element={<RecuperarSenhaPg />} />
      <Route path="/cadastro" element={<CadastroPg />} />
      <Route path="/perfil" element={<PerfilPg />} />

      <Route path="/contrato" element={<DocumentoPg />} />
      <Route path="/contrato_detalhe" element={<DocumentoDetalhePg />} />
      <Route path="/cliente" element={<PessoaPg />} />
      <Route path="/cliente_detalhe" element={<PessoaDetalhePg />} />

      <Route path="/financeiro" element={<FinanceiroPg />} />


      <Route path='/contador' element={<PessoaContador />} />
      <Route path='/detalhe_contador' element={<PessoaContadorDetalhe />} />

      {/* <Route path="/admin/*" element={
        <Routes>
          <Route path='/contador' element={<PessoaContador />} />
          <Route path='/detalhe_contador' element={<PessoaContadorDetalhe />} />
        </Routes>
      } /> */}
    </Routes>

  </BrowserRouter>);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
