import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { TabView, TabPanel } from 'primereact/tabview';
import { Component } from "react";
import { Dialog } from 'primereact/dialog';

export default class FinanceiroDetalhePg extends Component {
    state = {
        liberarAcesso: {
            tela_exemplo1: false,
        },
        salvarCarregando: false,
        deletarCarregando: false,
        vModalDeletar: false,
    }

    salvar() {
        this.setState({ salvarCarregando: true });
        setTimeout(() => this.setState({ salvarCarregando: false, salvarCheck: true, }), 2000);
        setTimeout(() => this.setState({ salvarCheck: false, }), 4000);
    }

    deletar() {
        this.setState({ deletarCarregando: true });
        setTimeout(() => this.setState({ deletarCarregando: false, }), 2000);
    }

    navegar(pTela) {
        const liberarAcesso = this.state.liberarAcesso;
        liberarAcesso[pTela] = true;
        this.setState({ liberarAcesso: liberarAcesso });
    }

    voltar() {
        this.props.setState({ tela: 1 });
        // this.props.listarDocumento();
    }

    render() {
        return <div>
            {/* NAVEGAÇÃO */}
            <Dialog header="Alerta" visible={this.state.vModalDeletar} style={{ width: 400 }} onHide={() => this.setState({ vModalDeletar: false, })}>
                <div style={{ display: "flex", flexDirection: 'column', gap: 20 }}>
                    <p>Deseja realmente deletar?</p>
                    <div style={{ display: 'flex', gap: 10, justifyContent: "right" }}>
                        <Button label="Sim" onClick={() => {
                            this.deletar();
                            this.setState({ vModalDeletar: false, });
                        }} />
                        <Button label="Não" onClick={() => this.setState({ vModalDeletar: false, })} />
                    </div>
                </div>
            </Dialog>

            {/* TELA */}

            <main style={{ width: '95%', margin: '20px auto' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', border: '1px solid lightgray', padding: 10, borderRadius: 3 }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 20, margin: '20px 0' }}>
                        <Button label={<i className="pi pi-arrow-left"></i>} className="p-button-raised" onClick={() => this.voltar()} />
                        <div>
                            <h1 style={{ fontSize: 22 }}>Detalhe da Tela Exemplo 1</h1>
                            <h2 style={{ fontSize: 20, color: 'gray' }}>Detalhe da Tela Exemplo 1</h2>
                        </div>
                    </div>

                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 10, }}>
                        {this.state.salvarCheck ?
                            <Button label="Salvo" icon={<i className="pi pi-check"></i>} className="p-button-success" />
                            :
                            <Button loading={this.state.salvarCarregando} label="Salvar" className="p-button-raised" onClick={() => this.salvar()} />
                        }
                        <Button loading={this.state.deletarCarregando} label="Deletar" className="p-button-danger" onClick={() => this.setState({ vModalDeletar: true, })} />
                    </div>
                </div>

                <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr 1fr", gap: 20, border: '1px solid lightgray', padding: 10, borderRadius: 3, marginTop: 20 }}>
                    <div>
                        <label>Label</label><br />
                        <InputText value="Crosssystem" disabled style={{ width: '100%' }} />
                    </div>
                    <div>
                        <label>Label</label><br />
                        <InputText style={{ width: '100%' }} />
                    </div>
                    <div>
                        <label>Label</label><br />
                        <InputText style={{ width: '100%' }} />
                    </div>
                    <div>
                        <label>Label</label><br />
                        <InputText style={{ width: '100%' }} />
                    </div>
                </div>

                <div style={{ border: '1px solid lightgray', padding: 10, borderRadius: 3, marginTop: 20 }}>
                    <h2 style={{ fontSize: 22.5 }}>Itens</h2>
                    <DataTable selectionMode="single" showGridlines value={[{ campo1: 'dados1', campo2: 'dados2' }, { campo1: 'dados1', campo2: 'dados2' }]} onSelectionChange={e => this.selecionar(e.value)}>
                        <Column field="campo1" header="titulo1"></Column>
                        <Column field="campo2" header="titulo2"></Column>
                    </DataTable>

                    <TabView style={{ marginTop: 10 }}>
                        <TabPanel header="Tab 1">
                            Conteudo qualquer 1
                        </TabPanel>
                        <TabPanel header="Tab 2">
                            Conteudo qualquer 2
                        </TabPanel>
                        <TabPanel header="Tab 3">
                            Conteudo qualquer 3
                        </TabPanel>
                    </TabView>
                </div>
            </main>
        </div>
    }
}